//Prenotazioni
import {
    FetchPrenotazioniParamsAndBody,
    FetchPrenotazioniResponse,
    PaginatedRequestParamsAndBody
} from 'store/home/type';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {Prenotazione} from 'models/prenotazione.model';

const fetchPrenotazioni = async (requestParams: FetchPrenotazioniParamsAndBody): Promise<FetchPrenotazioniResponse> => {
    console.log('Request [fetchPrenotazioni] params:', requestParams);
    const params:PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size ,
        //FIXED BY DEFAULT
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }
    const response: AxiosResponse<FetchPrenotazioniResponse> = await appAxios.post(`/api/cerbero/prenotazione/getList`,{
        ...requestParams.entity
    },{
        params
    });

    console.log('Response [fetchPrenotazioni] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchPrenotazioni')
}

const fetchPrenotazione = async (id: string): Promise<Prenotazione> => {
    console.log('Request [fetchPrenotazione] id:', id);
    const response: AxiosResponse<Prenotazione> = await appAxios.get(`/api/cerbero/prenotazione/get/${id}`);

    console.log('Response [fetchPrenotazione] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchPrenotazione')
}

const effettuaCheckIn = async (id: string | number): Promise<Prenotazione> => {
    console.log('Request [effettuaCheckIn] id:', id);
    const response: AxiosResponse<Prenotazione> = await appAxios.post(`/api/cerbero/prenotazione/effettuaCheckIn/${id}`);

    console.log('Response [effettuaCheckIn] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during effettuaCheckIn')
}

const bloccaPrenotazione = async (id: string | number): Promise<void> => {
    console.log('Request [bloccaPrenotazione] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/bloccaCheckIn/${id}`);

    console.log('Response [bloccaPrenotazione] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during bloccaPrenotazione')
}

const sbloccaPrenotazione = async (id: string | number): Promise<void> => {
    console.log('Request [sbloccaPrenotazione] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/sbloccaCheckIn/${id}`);

    console.log('Response [sbloccaPrenotazione] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sbloccaPrenotazione')
}

const resetPrenotazione = async (id: string | number): Promise<void> => {
    console.log('Request [resetPrenotazione] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/resetCheckIn/${id}`);

    console.log('Response [resetPrenotazione] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during resetPrenotazione')
}
const sendToPS = async (id: string | number): Promise<void> => {
    console.log('Request [resetPrenotazione] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/sendToPS/${id}`);

    console.log('Response [sendToPS] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendToPS')
}

const aggiungiPuliziaExtra = async (id: string | number): Promise<void> => {
    console.log('Request [aggiungiPuliziaExtra] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/aggiungiPuliziaAggiuntiva/${id}`);

    console.log('Response [aggiungiPuliziaExtra] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during aggiungiPuliziaExtra')
}
const rimuoviPulizia = async (id: string | number): Promise<void> => {
    console.log('Request [rimuoviPulizia] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/rimuoviPulizia/${id}`);

    console.log('Response [rimuoviPulizia] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during rimuoviPulizia')
}
const rimuoviPuliziaExtra = async (id: string | number): Promise<void> => {
    console.log('Request [rimuoviPuliziaExtra] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/rimuoviPuliziaExtra/${id}`);

    console.log('Response [rimuoviPuliziaExtra] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during rimuoviPuliziaExtra')
}

const inoltraDatiPrenotazione = async (id: string | number,telefono:string): Promise<void> => {
    console.log('Request [inoltraDatiPrenotazione] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/inviaCredenziali/${id}`,{
        telefono
    });

    console.log('Response [inoltraDatiPrenotazione] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during inoltraDatiPrenotazione')
}

const updateConsumi = async (id: number | string ): Promise<void> => {
    console.log('Request [updateConsumi] id:', id);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/prenotazione/updateConsumi/${id}` );
    console.log('Response [updateConsumi] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during updateConsumi')
}

export const PrenotazioniService = {
    fetchPrenotazioni,
    fetchPrenotazione,
    effettuaCheckIn,
    bloccaPrenotazione,
    sbloccaPrenotazione,
    resetPrenotazione,
    aggiungiPuliziaExtra,
    rimuoviPuliziaExtra,
    rimuoviPulizia,
    inoltraDatiPrenotazione,
    sendToPS,
    updateConsumi,
};
