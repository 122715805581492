import React, {FC} from 'react';
import {ContainerProps, StackProps, Text, VStack} from '@chakra-ui/react';
import {borderRadius} from 'style/styleConstants';

interface MyCardProps extends StackProps{
  title?: string;
  containerProps?: ContainerProps;
}

export const MyCard:FC<MyCardProps> = ({title,children,containerProps}, rest) => {
    return (
        <VStack align={'flex-start'} w={'full'} h={'full'}  flexGrow={1} {...rest}>
            {title && <Text fontWeight={'semibold'} fontSize={'lg'} color={'titleColor'}>{title}</Text>}
            <VStack shadow={'md'} flexGrow={1}  borderRadius={borderRadius} align={'flex-start'} border={'solid 1px'} borderColor={'borderColor'} mt={2} p={5} {...containerProps}>
                    {children}
            </VStack>
        </VStack>
    );
};
