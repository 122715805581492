import {createReducer} from '@reduxjs/toolkit';
import {StrutturaCreationState} from 'store/strutturaCreation/types';
import {StrutturaCreationActions} from 'store/strutturaCreation/strutturaCreation.action';

const initialState: StrutturaCreationState = {
    completedSections: [],
    linkUtili: [],
    procedure: [],
    proprietari: [],
    varchi: [],
    shelly: [],
    isError: false,
    isLoading: false,

};

export const strutturaCreationReducer = createReducer(initialState, (builder) => {
    builder.addCase(StrutturaCreationActions.addSectionAction, (state,action) =>{
        if (state.completedSections.includes(action.payload)){
            return state;
        }
        return {
            ...state,
            completedSections: [...state.completedSections, action.payload]
        }
    })

    // Procedure
    builder.addCase(StrutturaCreationActions.getProcedureAction.fulfilled, (state,action) =>{
        return {
            ...state,
            isError: false,
            isLoading: false,
            procedure: [...action.payload]
        }
    })

    // Link Utili
    builder.addCase(StrutturaCreationActions.getLinkUtiliAction.fulfilled, (state,action) =>{
        return {
            ...state,
            isError: false,
            isLoading: false,
            linkUtili: [...action.payload]
        }
    })

    // Varchi
    builder.addCase(StrutturaCreationActions.getVarchiAction.fulfilled, (state,action) =>{
        return {
            ...state,
            isError: false,
            isLoading: false,
            varchi: [...action.payload]
        }
    })

    // Shelly
    builder.addCase(StrutturaCreationActions.getShellyAction.fulfilled, (state,action) =>{
        return {
            ...state,
            isError: false,
            isLoading: false,
            shelly: [...action.payload]
        }
    })
    // Proprietari
    builder.addCase(StrutturaCreationActions.getProprietariStrutturaAction.fulfilled, (state,action) =>{
        const proprietari = action.payload.reverse()
        return {
            ...state,
            isError: false,
            isLoading: false,
            proprietari
        }
    })

    //Struttura
    builder.addCase(StrutturaCreationActions.fetchStrutturaAction.pending, (state,action) =>{
        return {
            ...state,
            isError: false,
            isLoading: true,
        }
    })

    builder.addCase(StrutturaCreationActions.fetchStrutturaAction.rejected, (state,action) =>{
        return {
            ...state,
            isError: true,
            isLoading: false,
        }
    })

    builder.addCase(StrutturaCreationActions.fetchStrutturaAction.fulfilled, (state,action) =>{
        const revProprietari = action.payload.proprietariStruttura

        return {
            ...state,
            isError: false,
            isLoading: false,
            struttura: action.payload,
            procedure: action.payload.procedure ? action.payload.procedure : [],
            linkUtili: action.payload.strutturaLinks ? action.payload.strutturaLinks : [],
            proprietari: revProprietari ? [...revProprietari] : [],
        }
    })

    builder.addCase(StrutturaCreationActions.saveStrutturaAction, (state,action) =>{
        const revProprietari = action.payload.proprietariStruttura
        console.log('saveStrutturaAction', revProprietari)
        return {
            ...state,
            struttura: action.payload,
            procedure: action.payload.procedure ? action.payload.procedure : [],
            proprietari: revProprietari ? [...revProprietari] : [],
            linkUtili: action.payload.strutturaLinks ? action.payload.strutturaLinks : [],
        }
    })

    builder.addCase(StrutturaCreationActions.clearStoreAction, (state,action) =>{
        return {
            ...state,
            struttura: undefined,
            completedSections: [],
            varchi: [],
            shelly: [],
            procedure: [],
            linkUtili: [],
            isError: false,
            isLoading: false,
        }
    })

    builder.addDefaultCase((state, action) => {
        return state;
    });
});
