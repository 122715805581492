import {createAction} from '@reduxjs/toolkit';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';

export const enum PROPRIETARI_TABLE_ACTION {
    SET_PAGE_INDEX = 'PROPRIETARI_SET_PAGE_INDEX',
    SET_PAGE_SIZE = 'PROPRIETARI_SET_PAGE_SIZE',
    PROPRIETARI_TABLE_RESET = 'PROPRIETARI_TABLE_RESET',
    SET_FILTERS = 'PROPRIETARI_SET_FILTERS',
    SET_ORDERING_FILTERS = 'PROPRIETARI_SET_ORDERING_FILTERS',
}

const setPageIndexAction = createAction(PROPRIETARI_TABLE_ACTION.SET_PAGE_INDEX, (payload:number) => {
    return {payload}
})

const setPageSizeAction = createAction(PROPRIETARI_TABLE_ACTION.SET_PAGE_SIZE, (payload:number) => {
    return {payload}
})

const proprietariTableResetAction = createAction(PROPRIETARI_TABLE_ACTION.PROPRIETARI_TABLE_RESET, () => {
    return {payload: undefined}
})

const setFiltersAction = createAction(PROPRIETARI_TABLE_ACTION.SET_FILTERS, (payload:PaginatedTableFilters) => {
    return {payload}
})

const setOrderingFiltersAction = createAction(PROPRIETARI_TABLE_ACTION.SET_ORDERING_FILTERS, (payload:OrderingFilters) => {
    return {payload}
})

export const ProprietariTableAction = {
    setPageIndexAction,
    setPageSizeAction,
    proprietariTableResetAction,
    setFiltersAction,
    setOrderingFiltersAction
};
