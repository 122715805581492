import React, {FC} from 'react';
import {Varco} from 'models/varco.model';
import MyButton from 'shared/components/default/myButton/myButton.component';
import {VStack, Text, Center, Spinner, HStack} from '@chakra-ui/react';
import {IoBusiness} from 'react-icons/io5';
import {BsFillDoorOpenFill, BsHouseDoorFill, ImRoad} from 'react-icons/all';

interface VarcoButtonProps {
    varco: Varco,
    onClick?: () => void,
    isLoading?: boolean,
    m?: number,
}

export const VarcoButton: FC<VarcoButtonProps> = ({varco, onClick, m, isLoading}) => {
    let title = `Apri ${varco.nome}`;
    let subtitle = '';
    let icon = undefined;

    switch (varco.tipologia) {
        case 1:
            subtitle = 'Premere qui per aprire il cancello su strada';
            icon = <ImRoad size={24}/>;
            break;
        case 2:
            subtitle = 'Premere qui per aprire la porta del condominio';
            icon = <IoBusiness size={24}/>;
            break;
        case 3:
            subtitle = 'Premere qui per aprire la porta dell\'appartamento';
            icon = <BsHouseDoorFill size={24}/>;
            break;
        case 4:
            subtitle = 'Premere qui per aprire la porta della stanza';
            icon = <BsFillDoorOpenFill size={24}/>
            break;
    }

    const renderBody = () => {
        if (isLoading) {
            return (
                <Center>
                    <Spinner/>
                </Center>
            );
        }
        return (
            <>
                <Text fontSize={'sm'}
                      noOfLines={2}
                >{title}</Text>
                <Text fontSize={'sm'}
                      noOfLines={2}
                      fontWeight={'thin'}
                >{subtitle}</Text>
            </>
        )
    }

    return (
        <MyButton myType={'PRIMARY'}
                  isDisabled={false}
                  key={varco.id}
                  h={'90px'}
                  minW={320}
                  onClick={onClick}
        >
            <HStack>
                {icon}
                <VStack w={'full'} h={'full'} justifyContent={'center'} alignContent={'center'}>
                    {renderBody()}
                </VStack>
            </HStack>
        </MyButton>
    )
};

