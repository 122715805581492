import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {Accesso, FetchAccessiParamsAndBody, FetchAccessiResponse} from "../../store/gestioneAccessi/types";
import {FetchPrenotazioniResponse, PaginatedRequestParamsAndBody} from "../../store/home/type";
import {backendDateTimeFormatter} from "../../utils/dates/date.utils";

const fetchAccessi = async (requestParams: FetchAccessiParamsAndBody): Promise<FetchAccessiResponse> => {
    console.log('Request [fetchGestioneStrutture]');
    const params: PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size,
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }

    const response: AxiosResponse<FetchAccessiResponse> = await appAxios.post(`/api/cerbero/struttura/accessi/getList`, {
        ...requestParams.entity
    }, {
        params
    });

    console.log('Response [fetchPrenotazioni] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchPrenotazioni')
}

const createAccesso = async (accesso: Accesso): Promise<Accesso> => {
    console.log('Request [saveAccesso]');
    //adapt finevalidita from 07-02-2024 12:23 to 2024-11-07T23:59:59
    accesso.fineValidita = backendDateTimeFormatter(accesso.fineValidita)
    const response: AxiosResponse<Accesso> = await appAxios.post(`/api/cerbero/struttura/createAccesso`, {
        ...accesso
    });

    console.log('Response [saveAccesso] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during saveAccesso')
}

const fetchAccesso = async (id: string): Promise<Accesso> => {
    console.log('Request [fetchAccesso]');
    const response: AxiosResponse<Accesso> = await appAxios.get(`/api/cerbero/struttura/accessi/${id}`);

    console.log('Response [fetchAccesso] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchAccesso')
}

const deleteAccesso = async (id: number): Promise<void> => {
    console.log('Request [deleteAccesso]');
    const response: AxiosResponse<void> = await appAxios.delete(`/api/cerbero/struttura/deleteAccesso/${id}`);
}
const editAccesso = async (id: string | number, accesso: Accesso): Promise<void> => {
    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/updateAccesso/${id}`, {
        codice: accesso.codice,
        descrizione: accesso.descrizione,
        fineValidita: backendDateTimeFormatter(accesso.fineValidita),
        struttureIds: accesso.struttureIds,
    });
    console.log('Response [editAccesso] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editAccesso')
}


export const GestioneAccessiService = {
    fetchAccessi,
    createAccesso,
    deleteAccesso,
    fetchAccesso,
    editAccesso,
};
