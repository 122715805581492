import {extendTheme} from '@chakra-ui/react';
import {BottomNavigationStyleConfig} from 'chakra-ui-bottom-navigation';

export const appThemeColors = {
    primary: {
        100: '#92ece1',
        300: '#4FD1C5',
        500:'#319795',
        700:'#285E61'
    },
    background: '#ffffff',
    secondary:{
        100:'#ffffff'
    },
    titleColor:'#4A5568',
    subtitleColor: '#718096',
    captionColor: '#718096',
    iconColor: '#718096',
    textColor: '#00000080',
    strongTextColor: '#2D3748',
    borderColor: '#E2E8F0',
    darkButtonColor:'#00000080',
    lightButtonColor: '#ffffff',
    darkOverlay:'#00000030',
    disabledButtonColor: '#B2D6D4',
    dangerColor: {
        300:'#FED7D7',
        500:'#E53E3E',
        700:'#9B2C2C'
    }
}

export const COLORS = {
    PRIMARY_500: '#319795',
    DANGER_500: '#E53E3E',
    WARNING: '#FFB400',
    BACKGROUND: '#ffffff',
    ICON: '#718096',
    BORDER: '#E2E8F0',
}

export const Spacing = {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 16,
    xl: 32,
}

export const appTheme = extendTheme({
    colors: appThemeColors,
    components: {
        BottomNavigation: BottomNavigationStyleConfig
    }

})

