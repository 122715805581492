import React, {useEffect, useState} from 'react';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {
    Box,
    Button,
    Divider, Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack, Select, Spacer,
    Stack, Table, Tbody, Td,
    Text, Textarea, Th, Thead, Tr,
    VStack
} from '@chakra-ui/react';
import {
    primaryButtonProps,
    primaryShortButtonProps
} from 'shared/components/default/myButton/myButton.types';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {useTranslation} from 'react-i18next';
import MyInput from 'shared/components/default/myInput/myInput.component';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';

import 'antd/dist/antd.css';
import {HomeAction} from 'store/home/home.action';
import {useNavigate, useParams} from 'react-router-dom';
import {MyDatePicker} from 'shared/components/default/myDatePicker/myDatePicker.component';
import {myCardStyle, stretchCardContainerStyle} from 'style/styleConstants';
import {
    Allegato,
    Manutenzione,
    Onere,
    Pagamento,
    StatoManutenzione,
    StatoOta, StatoPagamento
} from "../../../store/gestioneManutenzioni/types";
import {
    ManutenzioniFormInitialFrom,
    ManutenzioniValidationSchema
} from "./validation/manutenzioni.validation";
import {
    MyPaginatedColumn,
    MyPaginatedTable
} from "../../../shared/components/custom/paginatedTable/myPaginatedTable.component";
import SingleFileUploader from "../../../shared/components/custom/singleFileUploader/singleFileUploader";
import {IoCloudDownloadOutline, IoTrashBin} from "react-icons/all";
import AlertInsertPayment from "./alertInsertPayment/alertInsertPayment.component";
import {uiManagerActions} from "../../../store/uiManager/uiManager.action";
import {appAxios} from "../../../service/axios.config";
import AllegatiTable from "../../../shared/components/custom/AllegatiTable/AllegatiTable";
import PagamentiTable from "../../../shared/components/custom/pagamentiTable/pagamentiTable";
import enTexts from "../../loginMaintenance/i18n/en.json";
import itTexts from "../../loginMaintenance/i18n/it.json";
import {loadTranslations} from "../../../i18n/i18n.service";
import {RoutesPaths} from "../../../navigation/root.routes";
import {HomeRoutes} from "../../../navigation/home/home.routes";
import {getPathToManutenzioneDetail} from "../../../navigation/navigation.service";

export const ManutenzioniCreationEditPage = () => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const {t} = useTranslation()
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false)
    const [strutturaId, setStrutturaId] = useState<number>(0)
    const [manutenzioneId, setManutenzioneId] = useState<number>(0)
    const [pagamenti, setPagamenti] = useState<Pagamento[]>([])
    const [allegati, setAllegati] = useState<Allegato[]>([])
    //FORM
    const [formDefaultValues, setFormDefaultValues] = useState<Manutenzione>(ManutenzioniFormInitialFrom);
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: {errors, isDirty, isSubmitting}
    } = useForm<any>({
        resolver: yupResolver(ManutenzioniValidationSchema),
        defaultValues: formDefaultValues,
    });

    useEffect(() => {
        console.log('params', params)
        if (params.idAppartamento) {
            setStrutturaId(Number(params.idAppartamento))
        }
        if (params.idManutenzione) {
            setIsPageLoading(true)
            setIsEdit(true)
            dispatch(HomeAction.fetchManutenzioneAction(Number(params.idManutenzione))).then((response) => {
                if (response.payload) {
                    setFormDefaultValues(response.payload as Manutenzione)
                    reset(response.payload)
                    let manutenzione = response.payload as Manutenzione
                    if (manutenzione.pagamenti) {
                        setPagamenti(manutenzione.pagamenti)
                    }
                    if (manutenzione.allegati) {
                        setAllegati(manutenzione.allegati)
                    }
                }
            }).finally(() => {
                setIsPageLoading(false)
                setManutenzioneId(Number(params.idManutenzione))
                setIsUploadEnabled(true)
            }).catch((error) => {
                setIsPageLoading(false)
                navigate(-1)
            })
        }
    }, [])
    const handleFileUpload = async (file: File, isAllegatoPre: boolean) => {

        dispatch(HomeAction.uploadAllegato({
            id: manutenzioneId,
            file: file,
            isAllegatoPre: isAllegatoPre
        })).then((response) => {
            if (response.payload) {
                let manutenzione = response.payload as Manutenzione
                console.log('manutenzione', manutenzione)
                if (manutenzione.allegati) {
                    setAllegati(manutenzione.allegati)
                }

                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'Il caricamento dell\'allegato è avvenuto con successo',
                    status: 'success',
                }))
            }
        })
    }


    const tableColumns: MyPaginatedColumn[] = [

        {
            columnName: t('homePage:TABLES:MANUTENZIONE:DATA_PAGAMENTO'),
            objectKey: 'dataPagamento',
            filteringKey: 'dataPagamento',
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:IMPORTO'),
            objectKey: 'importo',
            filteringKey: 'importo',
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:STATO_PAGAMENTO:NAME'),
            objectKey: 'statoPagamento',
            filteringKey: 'statoPagamento',
            customObject: (value: string) => {
                return <Text>{t(`homePage:TABLES:MANUTENZIONE:STATO_PAGAMENTO:${value}`)}</Text>
            }
        },
    ]
    const createManutenzione = (manutenzione: Manutenzione) => {
        dispatch(HomeAction.createManutenzioneAction({
            manutenzione: manutenzione,
            idStruttura: strutturaId
        })).then((response) => {
                setIsLoading(false);
                if (response.payload) {
                    let manutenzione = response.payload as Manutenzione

                    dispatch(uiManagerActions.showToast({
                        title: 'Success',
                        description: 'La creazione della manutenzione è avvenuta con successo',
                        status: 'success',
                    }))
                    if(manutenzione.id) {
                        setFormDefaultValues(response.payload as Manutenzione)
                        setManutenzioneId(manutenzione.id)
                        setIsUploadEnabled(true)
                        setIsEdit(true)
                    }
                }
            }
        )
    }


    const editManutenzione = (manutenzione: Manutenzione) => {
        if (manutenzioneId) {
            dispatch(HomeAction.editManutenzione({
                id: manutenzioneId,
                manutenzione: manutenzione
            })).then((response) => {
                setIsLoading(false);
                if (response.payload) {
                    dispatch(uiManagerActions.showToast({
                        title: 'Success',
                        description: 'La della manutenzione è avvenuta con successo',
                        status: 'success',
                    }))
                }
            })
        } else {
            dispatch(uiManagerActions.showToast({
                title: 'Errore',
                description: 'Non è stato possibile recuperare l\'id della manutenzione per la modifica',
                status: 'error',
            }))
        }
    }

    const formSubmit = (manutenzione: Manutenzione) => {
        setIsLoading(true);
        if (isEdit) {
            return editManutenzione(manutenzione)

        }
        return createManutenzione(manutenzione)

    }
    const getPageTitle = () => {
        if (params.id) {
            return 'Modifica manutenzione'
        } else {
            return 'Creazione manutenzione'
        }
    }

    const downloadAttachment = (id: number, fileName: string) => {
        dispatch(HomeAction.downloadAllegato(
            {
                id: id,
                fileName: fileName
            })).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'Il download dell\'allegato è avvenuto con successo',
                    status: 'success',
                }))
            }
        })

    }
    const deleteAttachment = (id: number) => {
        dispatch(HomeAction.deleteAllegato(id
        )).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'La cancellazione dell\'allegato è avvenuta con successo',
                    status: 'success',
                }))
                setAllegati(allegati.filter((allegato) => allegato.id !== id));
            }
        })
    }

    function handleStatoPagamentoClick(id: number) {
        const index = pagamenti.findIndex((pagamento) => pagamento.id === id);
        // get pagamento by id and change stato
        const pagamento: Pagamento = pagamenti.find((pagamento) => pagamento.id === id) as Pagamento;
        if (pagamento) {
            pagamento.statoPagamento = pagamento.statoPagamento === "PAGATO" ? "NON_PAGATO" : "PAGATO";
        }

        setPagamenti([...pagamenti]);
        dispatch(HomeAction.editPagamento({
            id: id,
            pagamento: pagamento
        })).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'La modifica del pagamento è avvenuta con successo',
                    status: 'success',
                }))
            }
        })

    }

    const handleAddPaymentFormSubmit = (data: Pagamento) => {
        console.log(data);
        dispatch(HomeAction.createPagamentoAction({
            idManutenzione: manutenzioneId,
            pagamento: data
        })).then((response) => {
                setIsLoading(false);
                if (response.payload) {
                    dispatch(uiManagerActions.showToast({
                        title: 'Success',
                        description: 'La creazione del pagamento è avvenuta con successo',
                        status: 'success',
                    }))
                    let manutenzione = response.payload as Manutenzione
                    if (manutenzione.pagamenti) {
                        setPagamenti(manutenzione.pagamenti)
                    }
                }
            }
        )
    }
    const handleDeletePayment = (id: number) => {
        dispatch(HomeAction.deletePagamentoAction(id)).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'La cancellazione del pagamento è avvenuta con successo',
                    status: 'success',
                }))
                setPagamenti(pagamenti.filter((pagamento) => pagamento.id !== id));
            }
        })
    };

    const deleteManutenzione = () => {
        dispatch(HomeAction.deleteManutenzioneAction(manutenzioneId)).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'La cancellazione della manutenzione è avvenuta con successo',
                    status: 'success',
                }))
                navigate(-1);
            }
        })
    }
    return (
        <SimplePage title={getPageTitle()} hasBack={true} isLoading={isPageLoading}>
            <>
                <AlertInsertPayment isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}
                                    onSubmit={handleAddPaymentFormSubmit}/>
            </>

            <Box w={'full'} h={'full'} p={6} backgroundColor={'background'}>
                <form className={'formStyle'} onSubmit={handleSubmit(formSubmit)}>
                    <Text fontWeight={'semibold'} fontSize={'lg'} color={'titleColor'}>{'Dati'}</Text>
                    <HStack {...stretchCardContainerStyle} my={2}>
                        <VStack {...myCardStyle}>
                            <FormControl isInvalid={!!errors.data}>
                                <FormLabel color={'titleColor'}>Data*</FormLabel>
                                <MyDatePicker
                                    control={control}
                                    name="data"
                                    placeholder={'Data'}
                                    allowClear={false}
                                />
                                <FormErrorMessage>{errors.data?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.stato}>
                                <FormLabel color={'titleColor'}>Stato Manutenzione*</FormLabel>
                                <Controller
                                    name="stato"
                                    control={control}
                                    render={({field}) => (
                                        <Select {...field} onChange={(e) => {
                                            if (e.target.value === t('homePage:TABLES:MANUTENZIONE:STATO:NAME')) {
                                                field.onChange(undefined);
                                            } else {
                                                const statoValue: StatoManutenzione = e.target.value as StatoManutenzione;
                                                field.onChange(statoValue);
                                            }
                                        }}>
                                            <option
                                                value={undefined}>{t('homePage:TABLES:MANUTENZIONE:STATO:NAME')}</option>
                                            {Object.keys(StatoManutenzione).map((key) => (
                                                <option key={key} value={key}>
                                                    {t(`homePage:TABLES:MANUTENZIONE:STATO:${key}`)}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>{errors.stato?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.costo}>
                                <FormLabel color={'titleColor'}>Costo</FormLabel>
                                <MyInput formReg={register('costo')} type={'number'} step={0.01} myType={'OUTLINE'}
                                         placeholder={'Costo'}/>
                                <FormErrorMessage>{errors.costo?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.numeroDocumento}>
                                <FormLabel color={'titleColor'}>Numero Documento</FormLabel>
                                <MyInput formReg={register('numeroDocumento')} myType={'OUTLINE'}
                                         placeholder={'Numero documento'}/>
                                <FormErrorMessage>{errors.numeroDocumento?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.riferimentoOspite}>
                                <FormLabel color={'titleColor'}>Riferimento Ospite</FormLabel>
                                <MyInput formReg={register('riferimentoOspite')} myType={'OUTLINE'}
                                         placeholder={'Riferimento ospite'}/>
                                <FormErrorMessage>{errors.riferimentoOspite?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.importoPagatoManutentore}>
                                <FormLabel color={'titleColor'}>Importo pagato al manutentore</FormLabel>
                                <MyInput formReg={register('importoPagatoManutentore')} type={'number'} step={0.01}
                                         myType={'OUTLINE'}
                                         placeholder={'Importo pagato al manutentore'}/>
                                <FormErrorMessage>{errors.importoPagatoManutentore?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.importoRichiestoResponsabile}>
                                <FormLabel color={'titleColor'}>Importo richiesto al responsabile</FormLabel>
                                <MyInput formReg={register('importoRichiestoResponsabile')} type={'number'} step={0.01}
                                         myType={'OUTLINE'}
                                         placeholder={'Importo richiesto al responsabile'}/>
                                <FormErrorMessage>{errors.importoRichiestoResponsabile?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.importoPagatoRimborsoPortale}>
                                <FormLabel color={'titleColor'}>Importo pagato (rimborso) dal portale</FormLabel>
                                <MyInput formReg={register('importoPagatoRimborsoPortale')} type={'number'} step={0.01}
                                         myType={'OUTLINE'}
                                         placeholder={'Importo pagato (rimborso) dal portale'}/>
                                <FormErrorMessage>{errors.importoPagatoRimborsoPortale?.message}</FormErrorMessage>
                            </FormControl>


                            <FormControl isInvalid={errors.statoOta}>
                                <FormLabel color={'titleColor'}>Stato OTA*</FormLabel>
                                <Controller
                                    name="statoOta"
                                    control={control}
                                    render={({field}) => (
                                        <Select {...field} onChange={(e) => {
                                            if (e.target.value === t('homePage:TABLES:MANUTENZIONE:STATO_OTA:NAME')) {
                                                field.onChange(undefined);
                                            } else {
                                                const statoValue: StatoOta = e.target.value as StatoOta;
                                                field.onChange(statoValue);
                                            }
                                        }}>
                                            <option
                                                value={undefined}>{t('homePage:TABLES:MANUTENZIONE:STATO_OTA:NAME')}</option>

                                            {Object.keys(StatoOta).map((key) => (
                                                <option key={key} value={key}>
                                                    {t(`homePage:TABLES:MANUTENZIONE:STATO_OTA:${key}`)}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>{errors.statoOta?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.dataRimborsoPortale}>
                                <FormLabel color={'titleColor'}>Data Rimborso Portale</FormLabel>
                                <MyDatePicker
                                    control={control}
                                    name="dataRimborsoPortale"
                                    placeholder={'Data Rimborso Portale'}
                                    allowClear={true}
                                />
                                <FormErrorMessage>{errors.dataRimborsoPortale?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.onere}>
                                <FormLabel color={'titleColor'}>Onere*</FormLabel>
                                <Controller
                                    name="onere"
                                    control={control}
                                    render={({field}) => (
                                        <Select {...field} onChange={(e) => {
                                            if (e.target.value === t('homePage:TABLES:MANUTENZIONE:ONERE:NAME')) {
                                                field.onChange(undefined);
                                            } else {
                                                const statoValue: Onere = e.target.value as Onere;
                                                field.onChange(statoValue);
                                            }
                                        }}>
                                            <option
                                                value={undefined}>{t('homePage:TABLES:MANUTENZIONE:ONERE:NAME')}</option>

                                            {Object.keys(Onere).map((key) => (
                                                <option key={key} value={key}>
                                                    {t(`homePage:TABLES:MANUTENZIONE:ONERE:${key}`)}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>{errors.onere?.message}</FormErrorMessage>
                            </FormControl>
                        </VStack>
                        <VStack {...myCardStyle}>

                            <FormControl isInvalid={errors.note}>
                                <FormLabel color={'titleColor'}>Note</FormLabel>
                                <Textarea {...register('note')} placeholder={'Note'}/>
                                <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
                            </FormControl>
                            {!isUploadEnabled && (
                                <div style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    fontSize: '15px',
                                }}>
                                    <p>Prima di aggiungere gli allegati concludi la creazione della manutenzione</p>
                                </div>
                            )}
                            <div style={{filter: isUploadEnabled ? 'none' : 'blur(8px)'}}>


                                <Flex padding={5}>
                                    <FormLabel paddingTop={2} color={'titleColor'}>Pagamento Rateizzato</FormLabel>
                                    <Spacer/>
                                    <Button {...primaryShortButtonProps}
                                            onClick={() => setIsAlertOpen(true)}>{'Aggiungi'}</Button>

                                </Flex>

                                <PagamentiTable pagamenti={pagamenti} tableColumns={tableColumns}
                                                handleStatoPagamentoClick={handleStatoPagamentoClick}
                                                handleDeletePayment={handleDeletePayment}/>
                                <Text color={'titleColor'}>
                                    Carica allegati PRE
                                </Text>
                                <SingleFileUploader onFileUpload={handleFileUpload} id={'allegatiPre'}
                                                    isAllegatoPre={true}/>

                                <AllegatiTable allegati={allegati.filter(allegato => allegato.isAllegatoPre)}
                                               downloadAttachment={downloadAttachment}
                                               deleteAttachment={deleteAttachment}/>

                                <Text color={'titleColor'}>
                                    Carica allegati POST
                                </Text>
                                <SingleFileUploader onFileUpload={handleFileUpload} id={'allegatiPost'}
                                                    isAllegatoPre={false}/>
                                <AllegatiTable allegati={allegati.filter(allegato => !allegato.isAllegatoPre)}
                                               downloadAttachment={downloadAttachment}
                                               deleteAttachment={deleteAttachment}/>
                            </div>

                        </VStack>

                    </HStack>
                    <Divider/>
                    <Text fontWeight={'semibold'} mt={5} fontSize={'lg'}
                          color={'titleColor'}>{isEdit ? 'Concludi la modifica' : 'Concludi la creazione'}</Text>
                    <Divider/>
                    <Box w={'full'} display={'flex'} mt={5} justifyContent={'center'} alignContent={'center'}>
                        <Button {...primaryButtonProps} isLoading={isLoading || isSubmitting}
                                type={'submit'}
                                maxW={'70%'}>{isEdit ? 'Modifica manutenzione' : 'Crea record manutenzione'}</Button>

                    </Box>
                    {isEdit &&
                    <Box w={'full'} display={'flex'} mt={5} justifyContent={'center'} alignContent={'center'}>

                        <Button maxW={'70%'} width={300} onClick={deleteManutenzione}
                                backgroundColor={"dangerColor.500"}>Elimina</Button>
                    </Box>
                    }
                </form>
            </Box>
        </SimplePage>
    );
};

export default ManutenzioniCreationEditPage;
