import {Struttura, StrutturaBaseModel} from 'models/struttura/struttura.model';
import {StrutturaBe} from 'models/struttura/struttura.be';

export const struttureBaseModelMapper = (s: StrutturaBe | Struttura): StrutturaBaseModel => {
    return ({
        nomeInterno: s.nomeInterno,
        linkImmagine: s.linkImmagine,
        mezziDiTrasporto: s.mezziDiTrasporto,
        quartiere: s.quartiere,
        piano: s.piano,
        tariffaNuovaBooking: s.tariffaNuovaBooking,
        ascensore: s.ascensore,
        contattoAssistenza: s.contattoAssistenza,
        oraCheckInDef: s.oraCheckInDef,
        oraCheckOutDef: s.oraCheckOutDef,
        extraCostoPulizia: s.extraCostoPulizia,
        costoPulizia: s.costoPulizia,
        numeroPersonePerPuliziaExtra: s.numeroPersonePerPuliziaExtra,
        codiceRos1000: s.codiceRos1000,
        usernameRos1000: s.usernameRos1000,
        passwordRos1000: s.passwordRos1000,
        usernamePS: s.usernamePS,
        passwordPS: s.passwordPS,
        wskeyPS: s.wskeyPS,
        idAppartamentoPS: s.idAppartamentoPS,
        codiceMexal: s.codiceMexal,
    })
}

