import React, {FC} from 'react';
import {COLORS} from 'style/chakraTheme';
import {Tooltip} from '@chakra-ui/react'
import {
    IoAlertCircle,
    IoCheckmarkCircleSharp,
    IoCloseCircleSharp,
    IoCloudCircle,
    IoRemoveCircleSharp
} from 'react-icons/io5';

export interface CheckIconStatusProps {
    status: CheckIconStatusType;
}

export type CheckIconStatusType = 'success' | 'error' | 'warning' | 'archived' | 'deleted' ;

export const CheckIconStatus: FC<CheckIconStatusProps> = ({status}) => {

    if (status === 'success') {
        return (
            <Tooltip label="Prenotazione in corso">
                <span>
                    <IoCheckmarkCircleSharp size={32} color={COLORS.PRIMARY_500}/>
                </span>
            </Tooltip>
        )
    }
    if (status === 'warning') {
        return (
            <Tooltip label="Prenotazione in attesa">
                <span>
                <IoAlertCircle size={32} color={COLORS.WARNING}/>
                </span>
            </Tooltip>
        )
    }

    if (status === 'archived') {
        return (
            <Tooltip label="Prenotazione archiviata, check-out passato">
                <span>
                    <IoCloudCircle size={32} color={COLORS.ICON}/>
                </span>
            </Tooltip>
        )
    }
    if (status === 'deleted') {
        return (
            <Tooltip label="Prenotazione cancellata">
                <span>

                    <IoRemoveCircleSharp  size={32} color={COLORS.DANGER_500}/>
                </span>
            </Tooltip>
        )
    }
    return (
        <Tooltip label="Prenotazione disabilitata/errata">
            <span>
                <IoCloseCircleSharp size={32} color={COLORS.DANGER_500}/>
            </span>
        </Tooltip>
    )

};
