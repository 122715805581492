import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {MyPaginatedColumn, MyPaginatedTable} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {useNavigate} from 'react-router-dom';
import {HomeAction} from 'store/home/home.action';
import {Button, Stack, Text} from '@chakra-ui/react';
import {OrderingFilters, PaginatedManutenzioneFilters, PaginatedTableFilters} from 'store/home/type';
import {priceFormatter} from "../../../utils/numbers.util";
import {
    backendDateFormatter,
    backendTimeFormatter,
    backendToFrontedDateFormatter
} from "../../../utils/dates/date.utils";
import {uiManagerActions} from "../../../store/uiManager/uiManager.action";
import {GestioneManutenzioniTableAction} from "../../../store/home/paginatedActions/gestioneManutenzioniTable.action";
import {Manutenzione} from "../../../store/gestioneManutenzioni/types";

export const GestioneManutenzioniPage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {manutenzioniPaginated} = useAppSelector(state => state.homeReducer)

    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<PaginatedManutenzioneFilters>({
        data: new Date().toString(),
    });

    useEffect(() => {
        fetchData(manutenzioniPaginated.pageIndex, manutenzioniPaginated.pageSize, manutenzioniPaginated.filters, manutenzioniPaginated.sortDirection, manutenzioniPaginated.sortField)
    }, []);


    const tableColumns: MyPaginatedColumn[] = [
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:STRUTTURA'),
            objectKey: 'nomeStruttura',
            filteringKey: 'struttura_nomeStruttura',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:RIFERIMENTO_OSPITE'),
            objectKey: 'riferimentoOspite',
            filteringKey: 'riferimentoOspite',
            isFilterable: true,
        },

        {
            columnName: t('homePage:TABLES:MANUTENZIONE:DATA'),
            objectKey: 'data',
            filteringKey: 'data',
            isFilterable: true,
            customObject: (data: string) => (
                <Text>{backendToFrontedDateFormatter(data)}</Text>
            )
        },

        {
            columnName: t('homePage:TABLES:MANUTENZIONE:COSTO'),
            objectKey: 'costo',
            filteringKey: 'costo',
            isFilterable: true,
            customObject: (costo: number) => (
                <Text>{priceFormatter(costo)}</Text>
            )
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:STATO:NAME'),
            objectKey: 'stato',
            filteringKey: 'stato',
            isFilterable: true,
            customObject: (stato: string) => (
                <Text>{t(`homePage:TABLES:MANUTENZIONE:STATO:${stato}`)}</Text>
            )
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:STATO_OTA:NAME'),
            objectKey: 'statoOta',
            filteringKey: 'statoOta',
            isFilterable: true,
            customObject: (statoOta: string) => (
                <Text>{t(`homePage:TABLES:MANUTENZIONE:STATO_OTA:${statoOta}`)}</Text>
            )
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:DATA_RIMBORSO_PORTALE'),
            objectKey: 'dataRimborsoPortale',
            filteringKey: 'dataRimborsoPortale',
            isFilterable: true,
            customObject: (dataRimborsoPortale: string) => (
                <Text>{backendToFrontedDateFormatter(dataRimborsoPortale)}</Text>
            )
        },
        {
            columnName: t('homePage:TABLES:MANUTENZIONE:ONERE:NAME'),
            objectKey: 'onere',
            filteringKey: 'onere',
            isFilterable: true,
            customObject: (onere: string) => (
                <Text>{t(`homePage:TABLES:MANUTENZIONE:ONERE:${onere}`)}</Text>
            )
        },
    ]

    const columnEntityMapping = (params: PaginatedTableFilters) => {

        switch (params.filteringColumn) {
            case 'Stato':
                return {
                    stato: params.searchText,
                }
            case 'costo':
                return {
                    costo: params.searchText,
                }
            case 'statoOta':
                return {
                    statoOta: params.searchText,
                }
            case 'onere':
                return {
                    onere: params.searchText,
                }

            default:
                return {};
        }
    }

    const fetchData = async (pageIndex: number, pageSize: number, entity?: any, sortDirection?: string, sortField?: string) => {
        setLoading(true)
        await dispatch(HomeAction.fetchManutenzioniAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection,
            sortField: sortField
        }))
        setLoading(false)
    }

    return (
        <SimplePage title={t('homePage:TITLES:GESTIONE_MANUTENZIONI')}>
            <MyPaginatedTable
                pages={manutenzioniPaginated?.data}
                fetchData={fetchData}
                totalPages={manutenzioniPaginated?.totalPages}
                columns={tableColumns}
                loading={loading}
                pageIndex={manutenzioniPaginated.pageIndex}
                pageSize={manutenzioniPaginated.pageSize}
                setPageIndex={(pageIndex: number) => dispatch(GestioneManutenzioniTableAction.setPageIndexAction(pageIndex))}
                setPageSize={(pageSize: number) => dispatch(GestioneManutenzioniTableAction.setPageSizeAction(pageSize))}
                setOrderingFilters={(value: OrderingFilters) => dispatch(GestioneManutenzioniTableAction.setOrderingFiltersAction(value))}
                orderingFilters={{
                    sortField: manutenzioniPaginated.sortField,
                    sortDirection: manutenzioniPaginated.sortDirection
                }}
                onRowClick={(data: Manutenzione) => navigate(`${data.id}`)}
                columnEntityMapping={columnEntityMapping}
                manutenzioneHeader={{
                    costoPlaceholder: 'Costo',
                    filters,
                    setFilters,
                }}
            />
        </SimplePage>

    );
};
