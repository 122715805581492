import React, {FC, useEffect, useState} from 'react';
import {
    Box,
    Flex,
    IconButton,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Spinner,
    Table,
    TableColumnHeaderProps,
    Tbody,
    Td,
    Text,
    Tfoot,
    Thead,
    Tooltip,
    Tr
} from '@chakra-ui/react';
import {AiFillFastBackward, AiFillFastForward} from 'react-icons/ai';
import {IoChevronBack, IoChevronForward} from 'react-icons/io5';
import {SearchTableHeader} from 'shared/components/custom/paginatedTable/components/searchTableHeader.component';
import {OrderingFilters, PaginatedManutenzioneFilters, PaginatedTableFilters} from 'store/home/type';
import {MyColumnHeader} from 'shared/components/custom/paginatedTable/components/myColumnHeader.component';
import {ManutenzioniManutenzioniSearchTableHeader} from "./components/manutenzioniSearchTableHeader.component";

export interface MyPaginatedColumn {
    columnName: string;
    objectKey: string;
    filteringKey?: string;
    isFilterable?: boolean;
    customObject?: (data: any) => JSX.Element;
    customHeaderProps?: TableColumnHeaderProps;
}

export interface MyPaginatedTableProps<T> {
    columns: MyPaginatedColumn[],
    pages: T[] | undefined,
    loading: boolean,
    onRowClick?: (row: T) => void,
    fetchData: (pageIndex: number, pageSize: number, entity?: PaginatedTableFilters | PaginatedManutenzioneFilters, sortDirection?: string, sortField?: string) => void,
    pageSize: number,
    setPageSize: (pageSize: number) => void,
    pageIndex: number,
    setPageIndex: (pageIndex: number) => void,
    setOrderingFilters?: (orderingFiltersData: OrderingFilters) => void,
    orderingFilters?: OrderingFilters,
    totalPages: number,
    defaults?:{ // De defaults are for the resetFilter
        sortField?:string
        sortDirection?:string
    }
    header?: {
        placeholder: string,
        removeDatePiker?: boolean,
        children?: React.ReactNode,
        filters: PaginatedTableFilters,
        setFilters: (filters: PaginatedTableFilters) => void
    }
    manutenzioneHeader?: {
        costoPlaceholder: string,
        children?: React.ReactNode,
        filters: PaginatedManutenzioneFilters,
        setFilters: (filters: PaginatedManutenzioneFilters) => void
    }
    columnEntityMapping: (params: PaginatedTableFilters) => any
}

export const MyPaginatedTable: FC<MyPaginatedTableProps<any>> = ({
                                                                     columns,
                                                                     columnEntityMapping,
                                                                     pages,
                                                                     loading,
                                                                     onRowClick,
                                                                     pageSize,
                                                                     setPageSize,
                                                                     setPageIndex,
                                                                     fetchData,
                                                                     orderingFilters,
                                                                     setOrderingFilters,
                                                                     defaults,
                                                                     header,
                                                                     manutenzioneHeader,
                                                                     ...rest
                                                                 }) => {
    const [init, setInit] = useState(false);

    const INITAL_PAGE_INDEX = 1;
    const totalPages = rest.totalPages;

    useEffect(() => {
        if (!header?.filters.searchText && !header?.filters.checkInData && !header?.filters.checkOutData && !pages?.length ) {
            backEndMappedFetchData(0, pageSize).then(() => {
                setInit(true);
            });
        } else {
            setInit(true);
        }
    }, []);


    useEffect(() => {
        if (!loading && init) {
            console.log('MyPaginatedTable: onChange pageSize',pageSize," entity:");
            backEndMappedFetchData(0, pageSize, header?.filters, orderingFilters?.sortDirection, orderingFilters?.sortField);
        }
    }, [pageSize]);

    const backEndMappedFetchData = async (pageNumber: number, pageSize: number, data?: PaginatedTableFilters, sortDirection?: string, sortField?: string,resetSort?:boolean) => {
        let entity = {}
        if (data) {
            console.log('MyPaginatedTable: backEndMappedFetchData',data)
            header?.setFilters(data);
            entity = columnEntityMapping(data)
        }
        setPageIndex(pageNumber);
        const newSortDirection = resetSort? undefined : sortDirection? sortDirection: orderingFilters?.sortDirection
        const newSortField = resetSort? undefined :sortField? sortField: orderingFilters?.sortField
        await fetchData(pageNumber, pageSize, entity,newSortDirection ,newSortField );
    }

    const backEndManutenzioneMappedFetchData = async (pageNumber: number, pageSize: number, data?: PaginatedManutenzioneFilters, sortDirection?: string, sortField?: string,resetSort?:boolean) => {
        setPageIndex(pageNumber);
        const newSortDirection = resetSort? undefined : sortDirection? sortDirection: orderingFilters?.sortDirection
        const newSortField = resetSort? undefined :sortField? sortField: orderingFilters?.sortField
        await fetchData(pageNumber, pageSize, data,newSortDirection ,newSortField );
    }


    const gotoPage = (pageNumber: number) => {
        backEndMappedFetchData(pageNumber, pageSize, header?.filters, orderingFilters?.sortDirection, orderingFilters?.sortField);
    }

    const getPageIndex = () => {
        return rest.pageIndex + 1;
    }

    const canPreviousPage = () => {
        return getPageIndex() > INITAL_PAGE_INDEX;
    }

    const canNextPage = () => {
        return getPageIndex() < totalPages;
    }

    const getRealLastPage = () => {
        return totalPages - 1;
    }

    const previousPage = () => {
        backEndMappedFetchData(getPageIndex() - 2, pageSize, header?.filters, orderingFilters?.sortDirection, orderingFilters?.sortField);
    }

    const nextPage = () => {
        backEndMappedFetchData((getPageIndex()), pageSize, header?.filters, orderingFilters?.sortDirection, orderingFilters?.sortField);
    }

    const handlePageIndex = (event: any) => {
        const pageNumber = Number(event.target.value);
        //check if is valid
        if (!isNaN(pageNumber)) {
            if (pageNumber > totalPages - 1) {
                gotoPage(totalPages - 1);
                return
            }
            if (pageNumber < INITAL_PAGE_INDEX) {
                gotoPage(0);
                return;
            }
            gotoPage(pageNumber - 1);
        }
    }

    return (
        <Box w={'full'} lineHeight={'normal'} m={0}>
            {header && <SearchTableHeader
                columns={columns}
                extraItems={header.children}
                removeDatePiker={header.removeDatePiker}
                filters={header.filters}
                orderingFilters={orderingFilters}
                submitData={backEndMappedFetchData}
                pageSize={pageSize}
                placeholder={header.placeholder}
                customOnReset={() => {
                    const defaultFilteringColumn = columns.find(c => c.isFilterable === true)?.objectKey;
                    header?.setFilters({
                        checkInData: undefined,
                        checkOutData: undefined,
                        searchText: '',
                        filteringColumn: defaultFilteringColumn
                    });

                    const newSortField = defaults?.sortField ?? undefined
                    const newSortDirection = defaults?.sortDirection ?? undefined

                    if (setOrderingFilters) {
                        setOrderingFilters({
                            sortField: newSortField,
                             sortDirection: newSortDirection
                        });
                    }
                    backEndMappedFetchData(0, pageSize,undefined,newSortDirection,newSortField);

                }}
            />}

            {manutenzioneHeader && <ManutenzioniManutenzioniSearchTableHeader
                columns={columns}
                extraItems={manutenzioneHeader.children}
                filters={manutenzioneHeader.filters}
                orderingFilters={orderingFilters}
                submitData={backEndManutenzioneMappedFetchData}
                pageSize={pageSize}
                costoPlaceholder={manutenzioneHeader.costoPlaceholder}
                customOnReset={() => {
                    const defaultFilteringColumn = columns.find(c => c.isFilterable === true)?.objectKey;
                    manutenzioneHeader?.setFilters({
                        data: '',
                    });

                    const newSortField = defaults?.sortField ?? undefined
                    const newSortDirection = defaults?.sortDirection ?? undefined

                    if (setOrderingFilters) {
                        setOrderingFilters({
                            sortField: newSortField,
                            sortDirection: newSortDirection
                        });
                    }
                    backEndManutenzioneMappedFetchData(0, pageSize,undefined,newSortDirection,newSortField);

                }}
            />}



            <Box w={'full'} position={'relative'} overflowX={'auto'}>
                <Table backgroundColor={'background'} rounded={'lg'} variant={'simple'} w={'full'} m={0}>
                    <Thead>
                        <Tr>
                            {columns.map((column,) => (
                                <MyColumnHeader
                                    key={`${column.objectKey}`}
                                    filteringKey={column.filteringKey}
                                    columnName={column.columnName}
                                    isLoading={loading}
                                    customHeaderProps={column.customHeaderProps}
                                    sortField={orderingFilters?.sortField}
                                    sortDirection={orderingFilters?.sortDirection}
                                    pageIndex={rest.pageIndex}
                                    onClick={(sortDirectionValue, sortingFieldValue, resetPageIndex) => {
                                        if (setOrderingFilters) {
                                            setOrderingFilters({
                                                sortField: sortingFieldValue,
                                                sortDirection: sortDirectionValue
                                            });
                                        }
                                        if (resetPageIndex) {
                                            console.log('resetPageIndex');
                                            setPageIndex(0);
                                        }
                                        backEndMappedFetchData(resetPageIndex ? 0 : rest.pageIndex, pageSize, header?.filters, sortDirectionValue, sortingFieldValue,!sortDirectionValue);
                                    }}
                                />
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {!pages || pages.length === 0 && !loading && (
                            <Tr>
                                <Td colSpan={columns.length} align={'center'} justifyContent={'center'}>
                                    <Text fontSize={'md'} textAlign={'center'} color={'subtitleColor'}>Nessun
                                        dato</Text>
                                </Td>
                            </Tr>
                        )}
                        {pages?.map((row, i) => {
                            return (
                                <Tr key={`${row['id']}`}>
                                    {columns.map(({objectKey, customObject}) => {
                                        return (
                                            <Td key={`${row['id']}-${objectKey}`}
                                                onClick={() => onRowClick && !customObject ? onRowClick(row) : undefined}>{customObject ? customObject(row[objectKey]) : row[objectKey]}</Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}

                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Td colSpan={columns.length}>
                                <Flex justify={'center'} h={5}>
                                    {loading && (
                                        <Spinner/>)}
                                </Flex>
                            </Td>
                        </Tr>
                    </Tfoot>
                </Table>
            </Box>
            <Flex justifyContent="space-between" marginY={4} alignItems="center">
                <Flex>
                    <Tooltip label="Prima pagina">
                        <IconButton
                            aria-label="Prima pagina"
                            onClick={() => gotoPage(0)}
                            isDisabled={!canPreviousPage() || loading}
                            icon={<AiFillFastBackward height={3} width={3}/>}
                            mr={4}
                        />
                    </Tooltip>
                    <Tooltip label="Pagina precedente">
                        <IconButton
                            aria-label="Pagina precedente"
                            onClick={previousPage}
                            isDisabled={!canPreviousPage() || loading}
                            icon={<IoChevronBack height={6} width={6}/>}
                        />
                    </Tooltip>
                </Flex>

                <Flex alignItems="center">
                    <Text mr={8}>
                        Pagina{' '}
                        <Text fontWeight="bold" as="span">
                            {getPageIndex()}
                        </Text>{' '}
                        di{' '}
                        <Text fontWeight="bold" as="span">
                            {loading && totalPages === 0 ? '-' : totalPages}
                        </Text>
                    </Text>
                    <Text>Vai a pagina:</Text>{' '}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={INITAL_PAGE_INDEX}
                        clampValueOnBlur={true}
                        max={totalPages}
                        inputMode={'numeric'}
                        onBlur={handlePageIndex}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handlePageIndex(e);
                            }
                        }}
                        defaultValue={getPageIndex()}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <Select
                        w={32}
                        value={pageSize}
                        isDisabled={loading}
                        onChange={(e) => {
                            setPageIndex(0);
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Mostra {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>

                <Flex>
                    <Tooltip label="Pagina successiva">
                        <IconButton
                            aria-label="Pagina successiva"
                            onClick={nextPage}
                            isDisabled={!canNextPage() || loading}
                            icon={<IoChevronForward height={3} width={6}/>}
                        />
                    </Tooltip>
                    <Tooltip label="Ultima pagina">
                        <IconButton
                            aria-label="Ultima pagina"
                            onClick={() => gotoPage(getRealLastPage())}
                            isDisabled={!canNextPage() || loading}
                            icon={<AiFillFastForward height={3} width={3}/>}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </Box>
    );
};
