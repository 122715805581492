import React, {FC, useCallback, useEffect, useState} from 'react';
import {Box, HStack, IconButton, Input, InputGroup, InputLeftElement, Select} from '@chakra-ui/react';
import {DatePicker} from 'antd';
import {datePickerStyle} from 'style/styleConstants';
import {IoCloseCircleSharp, IoSearch} from 'react-icons/io5';
import moment from 'moment';
import {BACKEND_DATE_FORMAT, DATE_FORMAT} from 'utils/dates/date.utils';
import {MyPaginatedColumn} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';
import {COLORS} from 'style/chakraTheme';
import _debounce from 'lodash/debounce';



interface SearchTableHeaderProps {
    placeholder?: string;
    columns: MyPaginatedColumn[];
    removeDatePiker?: boolean;
    filters: PaginatedTableFilters,
    orderingFilters?: OrderingFilters,
    extraItems?: React.ReactNode;
    customOnReset?: () => void;
    submitData: (pageNumber: number, pageSize: number, entity?: PaginatedTableFilters, sortDirection?: string, sortField?: string) => void;
    pageSize: number
}

export const SearchTableHeader: FC<SearchTableHeaderProps> = (props) => {
    const {filters,submitData,orderingFilters,pageSize} = props;
    const defaultFilteringColumn = props.columns.find(c => c.isFilterable === true)?.objectKey;
    const [searchBar, setSearchBar] = useState<string>('');

    useEffect(() => {
        if (filters.searchText){
            console.log('SearchTableHeader useEffect',filters.searchText);
            setSearchBar(filters.searchText);
        }
    }, []);

    const dateFromStore = (datePickerFieldValue: string | undefined): moment.Moment | undefined => {
        if (datePickerFieldValue && !isNaN(new Date(datePickerFieldValue).valueOf()) && datePickerFieldValue.length > 0) {
            try {
                const finalDate = moment(datePickerFieldValue, BACKEND_DATE_FORMAT)
                return finalDate
            } catch (e) {
                return undefined
            }
        }
        return undefined
    }

    const dateToStore = (myDate: moment.Moment | null): string | undefined => {
        return myDate ? myDate.format(BACKEND_DATE_FORMAT) : undefined;
    }

    const resetFilters = () => {
        setSearchBar('');
        if (props.customOnReset){
            props.customOnReset();
        }
    }


    const handleSubmitData = async (persistedPageSize:number,persistedFilters:PaginatedTableFilters,persistedSortDirection?:string,persistedSortField?:string, searchText?:string,checkInData?:string,checkOutData?:string,filteringColumn?:string) =>{
        console.log('SearchTableHeader handleSubmitData persisted filters: ',persistedFilters);
        const data: PaginatedTableFilters = {
            checkInData: checkInData !== undefined? checkInData: persistedFilters.checkInData,
            checkOutData: checkOutData !== undefined? checkOutData : persistedFilters.checkOutData,
            searchText: searchText !== undefined? searchText: persistedFilters.searchText,
            filteringColumn: filteringColumn? filteringColumn : persistedFilters.filteringColumn ? persistedFilters.filteringColumn : defaultFilteringColumn
        }
        console.log('SearchTableHeader handleSubmitData',searchText,data)
        console.log("PAGE SIZE",persistedPageSize)
        await submitData(0, persistedPageSize, data, persistedSortDirection, persistedSortField);

    }

    const debounceInputSearch = useCallback(_debounce(handleSubmitData, 400), []);

    return (
        <HStack my={1} w={'full'} mb={2} justify={'end'} zIndex={0}>
            {props.extraItems}

            <IconButton aria-label="Clear filters" onClick={resetFilters} color={COLORS.ICON}
                        icon={<IoCloseCircleSharp size={22}/>}/>
            {!props.removeDatePiker && <DatePicker
                value={dateFromStore(filters.checkInData)}
                format={DATE_FORMAT}
                onChange={(date) => {
                    handleSubmitData(pageSize,filters,orderingFilters?.sortDirection,orderingFilters?.sortField,undefined, dateToStore(date), undefined, undefined);
                }}
                size={'large'}
                placeholder={'Check In'}
                style={datePickerStyle}
            />}
            {!props.removeDatePiker && <DatePicker
                value={dateFromStore(filters.checkOutData)}
                format={DATE_FORMAT}
                onChange={(date) => {
                    handleSubmitData(pageSize,filters,orderingFilters?.sortDirection,orderingFilters?.sortField,undefined,undefined, dateToStore(date),  undefined);
                }}
                placeholder={'Check Out'}
                size={'large'}
                style={datePickerStyle}
            />}
            <Select width={'auto'} backgroundColor={'background'} value={filters.filteringColumn} onChange={(e) => {
                handleSubmitData(pageSize,filters,orderingFilters?.sortDirection,orderingFilters?.sortField,undefined,undefined, undefined,  e.target.value);
            }}>
                {props.columns.map((c) => {
                    if (c.isFilterable) {
                        return <option key={c.objectKey} value={c.objectKey}>{c.columnName}</option>
                    }
                })}
            </Select>
            <Box w={320}>
                <InputGroup>
                    <InputLeftElement
                        _hover={{cursor: 'pointer'}}
                        children={<IoSearch color={'iconColor'} />}
                    />

                    <Input maxW={320} value={searchBar} onChange={s => {
                        setSearchBar(s.target.value)
                        debounceInputSearch(pageSize,filters,orderingFilters?.sortDirection,orderingFilters?.sortField,s.target.value);
                    }} backgroundColor={'background'} placeholder={props.placeholder}/>

                </InputGroup>
            </Box>

        </HStack>
    );
};
