import moment from 'moment';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const DATE_TIME_BE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const isValidDate = (date:string):boolean => {
    return !isNaN(new Date(date).valueOf());
}

export const momentDateFormatter = (myDate:moment.Moment):string =>{
    return myDate.format(DATE_FORMAT)
}

export const timeFormatter = (myDate:string):string =>{
    return moment(myDate).format(TIME_FORMAT)
}

export const dateFormatter = (myDate:string):string => {
    return moment(myDate).format(DATE_FORMAT)
}

// FOR BACKEND

export const backendMomentDateFormatter = (myDate:moment.Moment):string =>{
    return myDate.format(BACKEND_DATE_FORMAT)
}

export const backendDateFormatter = (myDate:string):string => {
    if(!myDate) return ''
    const parsedDate = moment(myDate,DATE_FORMAT).format(BACKEND_DATE_FORMAT);
    return parsedDate
}


export const backendDateTimeFormatter = (myDate:string):string => {
    const parsedDate = moment(myDate,DATE_TIME_FORMAT).format(DATE_TIME_BE_FORMAT);
    return parsedDate
}

export const backendTimeFormatter = (myDate:string):string => {
    const parsedDate = moment(myDate,DATE_TIME_BE_FORMAT).format(DATE_TIME_FORMAT);
    return parsedDate
}

export const backendToFrontedDateFormatter = (myDate:string):string => {
    if(!myDate) return ''
    const parsedDate = moment(myDate,BACKEND_DATE_FORMAT).format(DATE_FORMAT);
    return parsedDate
}

export const dateTimeFormatter = (dateTime: string | undefined):string => {
    if(dateTime && isValidDate(dateTime)){
        return (moment(dateTime)).format(DATE_TIME_FORMAT)
    }
    return dateTime ? dateTime : 'Nessuna data'
}

export const dateTimeSplitFormatter = (date: string | undefined, time: string | undefined):string => {
    const dateTime = `${date} ${time}`
    if(dateTime && isValidDate(dateTime)){
        return (moment(dateTime)).format(DATE_TIME_FORMAT)
    }
    return dateTime
}

