import {Prenotazione, PrenotazioneTableModel} from 'models/prenotazione.model';
import {dateFormatter} from 'utils/dates/date.utils';
import {getPrenotazioneStatus} from 'utils/prenotazione.utils';

export const prenotazioneTableModelMapper = (prenotazione: Prenotazione[]): PrenotazioneTableModel[] => {
    const dataList: PrenotazioneTableModel[] = prenotazione?.map(p => {

        const prenotazioneTable: PrenotazioneTableModel = {
            appartamento: `${p.struttura.nomeStruttura}`,
            nomeInterno: p.struttura.nomeInterno,
            ospite: `${p.nomeOspite} ${p.cognomeOspite}`,
            numeroOspiti: p.numeroAdulti + p.numeroBambini,
            checkIn: dateFormatter(p.arrivo),
            checkOut: dateFormatter(p.partenza),
            canalePrenotazione: p.canalePrenotazione.nome,
            importo: p.prezzo,
            status: getPrenotazioneStatus(p),
            dataCheckInEffettuato: p.dataCheckInEffettuato != undefined ? dateFormatter(p.dataCheckInEffettuato) : p.dataCheckInEffettuato,
            idPrenotazioneChannel: p.idPrenotazioneChannel,
            id: p.id,
        }

        return prenotazioneTable;
    })
    return dataList;
}
