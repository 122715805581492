import {createReducer} from '@reduxjs/toolkit';
import {ReportsState} from './types';
import {ReportsActions} from 'store/reports/reports.action';

const initialState: ReportsState = {
    isAlertOpen: false,
    reportsStruttura: [],
    reportsGeneral: [],
};

export const reportsReducer = createReducer(initialState, (builder) => {
    builder.addCase(ReportsActions.setReportsAlertAction, (state, action) => {
        return {
            ...state,
            isAlertOpen : action.payload
        }
    });
    builder.addCase(ReportsActions.getReportListStrutturaAction.fulfilled, (state, action) => {
        return {
            ...state,
            reportsStruttura: action.payload
        }
    });
    builder.addCase(ReportsActions.getReportListGeneralAction.fulfilled, (state, action) => {
        return {
            ...state,
            reportsGeneral: action.payload
        }
    });
    builder.addDefaultCase((state, action) => {
        return state;
    });
});
