import {createAction} from '@reduxjs/toolkit';
import {defaultLanguage, setUserSelectedLanguage, SUPPORTED_LANGUAGES} from 'i18n/i18n.config';
import {SetLanguageParams} from 'store/settings/types';

export const enum SETTINGS_ACTION {
    SET_LANGUAGE = 'settings/SET_LANGUAGE',
}

const setLanguageSetting = createAction(SETTINGS_ACTION.SET_LANGUAGE, (params:SetLanguageParams) => {
    //TODO: server side language change
    setUserSelectedLanguage(params.language);
    switch (params.language){
        case SUPPORTED_LANGUAGES.EN:
            return { payload: params}
        case SUPPORTED_LANGUAGES.IT:
            return { payload: params}
        default:
            return {
                payload: {
                    language: defaultLanguage,
                    overrideServer: true
                }
            }
    }
})
export const SettingsActions = {
    setLanguageSetting
}
