import React, {Dispatch, SetStateAction, useState} from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Spinner, Spacer, Flex, HStack
} from "@chakra-ui/react";
import {Pagamento} from "../../../../store/gestioneManutenzioni/types";
import {Space} from "antd";
import {backendDateFormatter} from "../../../../utils/dates/date.utils";

interface AlertInsertPaymentProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: Pagamento) => void;
}
const AlertInsertPayment: React.FC<AlertInsertPaymentProps> = ({ isOpen, onClose ,onSubmit}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<Pagamento>();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitForm = (data: Pagamento) => {
        onSubmit(data);
        onClose();
    };

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={undefined}>
            <form onSubmit={handleSubmit(onSubmitForm)}>

                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Aggiungi pagamento
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <FormControl isInvalid={!!errors.dataPagamento}>
                                <FormLabel>DataPagamento</FormLabel>
                                <Input type="date" {...register("dataPagamento", {required: true})} />
                            </FormControl>

                            <FormControl isInvalid={!!errors.importo}>
                                <FormLabel>Importo</FormLabel>
                                <Input type="number" {...register("importo", {required: true})} />
                            </FormControl>

                            <FormControl isInvalid={!!errors.statoPagamento}>
                                <FormLabel>Stato</FormLabel>
                                <Select {...register("statoPagamento", {required: true})}>
                                    <option value="PAGATO">PAGATO</option>
                                    <option value="NON_PAGATO">NON PAGATO</option>
                                </Select>
                            </FormControl>


                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <HStack spacing='24px'>
                                <Button type="submit" isLoading={isLoading}>Aggiungi </Button>
                                <Spacer/>
                                <Button onClick={onClose} backgroundColor={"dangerColor.300"}>
                                    Annulla
                                </Button>
                            </HStack>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </form>
        </AlertDialog>
    );
};

export default AlertInsertPayment;