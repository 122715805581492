import {Navigate, RouteProps} from 'react-router-dom';
import React from 'react';
import NotFoundPage from 'screens/notFound/notFound.page';
import {PrivateRoute} from 'navigation/customRouting/privateRoute';
import LoginMaintenance from "../screens/loginMaintenance/login.maintenance";
import {HomePaths} from "./home/home.routes";
import {GestioneStrutturePage} from "../screens/gestioneStrutture/gestioneStrutture.page";

const LoginPage = React.lazy(() => import('screens/login/login.page'));
const HomePage = React.lazy(() => import('screens/home/home.page'));
const SendRecuperaPasswordPage = React.lazy(() => import('screens/recuperaPassword/sendRecuperaPassword.page'));
const ResetPasswordPage = React.lazy(() => import('screens/recuperaPassword/resetPassword.page'));
const NuovoUtentePage = React.lazy(() => import('screens/nuovoUtente/nuovoUtente.page'));
//DEV
const TestComponent = React.lazy(() => import('screens/test/test.component'));

export type RootRoutes = 'LOGIN' |'LOGIN_MAINTENANCE'| 'TEST' | 'NOT_FOUND' | 'HOME' | 'INDEX' | 'RECUPERA_PASSWORD' | 'RECUPERA_PASSWORD_ID' | 'NUOVO_UTENTE' | 'GESTIONE_STRUTTURE'
    ;

export const RoutesPaths: Record<RootRoutes, String> = {
    INDEX: '/',
    LOGIN: '/login',
    LOGIN_MAINTENANCE: '/login-maintenance',
    GESTIONE_STRUTTURE: '/gestioneStrutture/:id',
    HOME: '/home',
    NUOVO_UTENTE: '/nuovo-utente/:id',
    RECUPERA_PASSWORD: '/recupera-password',
    RECUPERA_PASSWORD_ID: '/recupera-password/:id',
    TEST: '/test',
    NOT_FOUND: '*'
}

export const AppRoutes: Record<RootRoutes, RouteProps> = {
    INDEX: {
        index: true,
        element: <Navigate to={RoutesPaths.LOGIN.toString()}/>
    },
    LOGIN: {
        path: RoutesPaths.LOGIN.toString(),
        element: <LoginPage/>,
    },
    LOGIN_MAINTENANCE: {
        path: RoutesPaths.LOGIN_MAINTENANCE.toString(),
        element: <LoginMaintenance/>,
    },
    GESTIONE_STRUTTURE: {
        path: RoutesPaths.GESTIONE_STRUTTURE.toString(),
        element: <GestioneStrutturePage/>,

    },
    TEST: {
        path: RoutesPaths.TEST.toString(),
        element: <NotFoundPage/>,
    },
    NOT_FOUND: {
        path: RoutesPaths.NOT_FOUND.toString(),
        element: <NotFoundPage/>,
    },
    HOME: {
        path: RoutesPaths.HOME.toString(),
        element: <PrivateRoute component={HomePage}/>
    },
    RECUPERA_PASSWORD:{
        path: RoutesPaths.RECUPERA_PASSWORD.toString(),
        element: <SendRecuperaPasswordPage/>,
    },
    RECUPERA_PASSWORD_ID:{
        path: RoutesPaths.RECUPERA_PASSWORD_ID.toString(),
        element: <ResetPasswordPage/>,
    },
    NUOVO_UTENTE:{
        path: RoutesPaths.NUOVO_UTENTE.toString(),
        element: <NuovoUtentePage/>,
    },


}
