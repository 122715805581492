import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {
    SendDatiCatastaliParams,
    SendLinkUtiliParams,
    SendProcedureParams,
    SendProprietarioParams, SendShellyParams,
    SendStrutturaBaseParams,
    SendVarcoParams,
    StrutturaCreationSections
} from 'store/strutturaCreation/types';
import {Struttura} from 'models/struttura/struttura.model';
import {StruttureService} from 'service/home/strutture.service';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {Procedura} from 'models/procedura.model';
import {LinkUtili} from 'models/linkUtili.model';
import {Varco} from 'models/varco.model';
import {ProprietarioStruttura} from 'models/proprietario.model';
import {ProprietariService} from 'service/home/proprietari.service';
import {Shelly} from "../../models/shelly.model";

export const enum STRUTTURA_CREATION_ACTION {
    ADD_SECTION = 'ADD_SECTION',
    SAVE_STRUTTURA = 'SAVE_STRUTTURA',

    SEND_STRUTTURA_BASE = 'SEND_STRUTTURA_BASE',

    SEND_DATI_CATASTALI = 'SEND_DATI_CATASTALI',

    GET_PROPRIETARI = 'GET_PROPRIETARI',
    SEND_PROPRIETARIO = 'SEND_PROPRIETARIO',

    GET_PROCEDURE = 'GET_PROCEDURE',
    SEND_PROCEDURA = 'SEND_PROCEDURA',
    DELETE_PROCEDURA = 'DELETE_PROCEDURA',
    DELETE_SHELLY = 'DELETE_SHELLY',

    GET_LINK_UTILI = 'GET_LINK_UTILI',
    SEND_LINK = 'SENT_LINK',
    SEND_SHELLY = 'SEND_SHELLY',
    DELETE_LINK_UTILI = 'DELETE_LINK_UTILI',

    GET_VARCHI = 'GET_VARCHI',
    GET_SHELLY = 'GET_SHELLY',
    SEND_VARCO = 'SEND_VARCO',
    DELETE_VARCO = 'DELETE_VARCO',
    EDIT_VARCO = 'EDIT_VARCO',
    EDIT_SHELLY = 'EDIT_SHELLY',
    FETCH_STRUTTURA = 'FETCH_STRUTTURA',
    ABILITA_STRUTTURA = 'ABILITA_STRUTTURA',
    DISABILITA_STRUTTURA = 'DISABILITA_STRUTTURA',

    CLEAR_STORE = 'CLEAR_STORE',
}

const addSectionAction = createAction(STRUTTURA_CREATION_ACTION.ADD_SECTION, (payload:StrutturaCreationSections) => {
    return {
        payload
    }
})

const saveStrutturaAction = createAction(STRUTTURA_CREATION_ACTION.SAVE_STRUTTURA, (payload:Struttura) => {
    return {
        payload
    }
})


const clearStoreAction = createAction(STRUTTURA_CREATION_ACTION.CLEAR_STORE, () => {
    return {payload: null}
})

const sendStrutturaAction = createAsyncThunk<boolean,SendStrutturaBaseParams>(STRUTTURA_CREATION_ACTION.SEND_STRUTTURA_BASE, async (params, thunkAPI) => {
    try {
        await StruttureService.sendStrutturaBase(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio della struttura base',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const sendDatiCatastaliAction = createAsyncThunk<boolean,SendDatiCatastaliParams>(STRUTTURA_CREATION_ACTION.SEND_DATI_CATASTALI, async (params, thunkAPI) => {
    try {
        await StruttureService.sendDatiCatastali(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio dei dati catastali',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const getProprietariStrutturaAction = createAsyncThunk<ProprietarioStruttura[],string | number>(STRUTTURA_CREATION_ACTION.GET_PROPRIETARI, async (idStruttura, thunkAPI) => {
    try {
        return await ProprietariService.fetchProprietariStruttura(idStruttura);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i proprietari della struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const sendProprietarioAction = createAsyncThunk<boolean,SendProprietarioParams>(STRUTTURA_CREATION_ACTION.SEND_PROPRIETARIO, async (params, thunkAPI) => {
    try {
        await StruttureService.sendProprietario(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio del proprietario',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const getProcedureAction = createAsyncThunk<Procedura[],string | number>(STRUTTURA_CREATION_ACTION.GET_PROCEDURE, async (params, thunkAPI) => {
    try {
        return await StruttureService.getProcedure(params);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere le procedure',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const sendProceduraAction = createAsyncThunk<boolean,SendProcedureParams>(STRUTTURA_CREATION_ACTION.SEND_PROCEDURA, async (params, thunkAPI) => {
    try {
        await StruttureService.sendProcedura(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio della procedura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const deleteProceduraAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.DELETE_PROCEDURA, async (params, thunkAPI) => {
    try {
        await StruttureService.deleteProcedura(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare la procedura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const deleteShellyAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.DELETE_SHELLY, async (params, thunkAPI) => {
    try {
        await StruttureService.deleteShelly(params);
        return true
    } catch (e: any) {
        console.log(e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: e.message,
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const getLinkUtiliAction = createAsyncThunk<LinkUtili[],string | number>(STRUTTURA_CREATION_ACTION.GET_LINK_UTILI, async (params, thunkAPI) => {
    try {
        return await StruttureService.getLink(params);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i link utili',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const sendLinkAction = createAsyncThunk<boolean,SendLinkUtiliParams>(STRUTTURA_CREATION_ACTION.SEND_LINK, async (params, thunkAPI) => {
    try {
        await StruttureService.sendLink(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio del link',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const deleteLinkUtiliAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.DELETE_LINK_UTILI, async (params, thunkAPI) => {
    try {
        await StruttureService.deleteLink(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare il link',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});


const getVarchiAction = createAsyncThunk<Varco[],string | number>(STRUTTURA_CREATION_ACTION.GET_VARCHI, async (params, thunkAPI) => {
    try {
        return await StruttureService.getVarchi(params);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i varchi',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const sendVarcoAction = createAsyncThunk<boolean,SendVarcoParams>(STRUTTURA_CREATION_ACTION.SEND_VARCO, async (params, thunkAPI) => {
    try {
        await StruttureService.sendVarco(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare il salvataggio del varco',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const sendShellyAction = createAsyncThunk<boolean,SendShellyParams>(STRUTTURA_CREATION_ACTION.SEND_SHELLY, async (params, thunkAPI) => {
    try {
        await StruttureService.sendShelly(params);
        return true
    } catch (e: any) {
        console.log(e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: e.message,
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const deleteVarcoAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.DELETE_VARCO, async (params, thunkAPI) => {
    try {
        await StruttureService.deleteVarco(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare il varco',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchStrutturaAction = createAsyncThunk<Struttura,string | number>(STRUTTURA_CREATION_ACTION.FETCH_STRUTTURA, async (params, thunkAPI) => {
    try {
        return await StruttureService.fetchStruttura(params);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a caricare la struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const abilitaStrutturaAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.ABILITA_STRUTTURA, async (params, thunkAPI) => {
    try {
        await StruttureService.abilitaStruttura(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad abilitare la struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const disabilitaStrutturaAction = createAsyncThunk<boolean,string | number>(STRUTTURA_CREATION_ACTION.DISABILITA_STRUTTURA, async (params, thunkAPI) => {
    try {
        await StruttureService.disabilitaStruttura(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a disabilita la struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const editVarcoAction = createAsyncThunk<boolean,Varco>(STRUTTURA_CREATION_ACTION.EDIT_VARCO, async (params, thunkAPI) => {
    try {
        await StruttureService.editVarco(params);
        return true
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica del varco',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const editShellyAction = createAsyncThunk<boolean,Shelly>(STRUTTURA_CREATION_ACTION.EDIT_SHELLY, async (params, thunkAPI) => {
    try {
        await StruttureService.editShelly(params);
        return true
    } catch (e: any) {
        console.log(e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: e.message,
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const getShellyAction = createAsyncThunk<Varco[],string | number>(STRUTTURA_CREATION_ACTION.GET_SHELLY, async (params, thunkAPI) => {
    try {
        return await StruttureService.getShelly(params);
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: e.message,
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});





export const StrutturaCreationActions = {
    addSectionAction,
    saveStrutturaAction,
    sendStrutturaAction,
    sendDatiCatastaliAction,

    getProprietariStrutturaAction,
    sendProprietarioAction,

    getProcedureAction,
    deleteProceduraAction,
    sendProceduraAction,

    getLinkUtiliAction,
    sendLinkAction,
    deleteLinkUtiliAction,

    getVarchiAction,
    sendVarcoAction,
    sendShellyAction,
    deleteVarcoAction,
    deleteShellyAction,
    editVarcoAction,
editShellyAction,
    fetchStrutturaAction,
    abilitaStrutturaAction,
    clearStoreAction,
    disabilitaStrutturaAction,
    getShellyAction,
}
