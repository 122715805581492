import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {useNavigate} from 'react-router-dom';
import {MyPaginatedColumn, MyPaginatedTable} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {HomeAction} from 'store/home/home.action';
import {AppartamentiTableAction} from 'store/home/paginatedActions/appartamentiTable.action';
import {Button, Stack} from '@chakra-ui/react';
import {primaryShortButtonProps} from 'shared/components/default/myButton/myButton.types';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {StrutturaTableModel} from 'models/struttura/struttura.model';
import {DynamicCheckIconComponent} from 'shared/components/custom/dynamicCheckIcon/dynamicCheckIcon.component';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';

export const StrutturePage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {strutturePaginated} = useAppSelector(state => state.homeReducer)
    const [loading, setLoading] = useState(false)
    const [isUpdateDataSmobuLoading, setIsUpdateDataSmobuLoading] = useState(false);

    const tableColumns: MyPaginatedColumn[] = [
        {
            columnName: 'Stato',
            objectKey: 'disabled',
            filteringKey: 'disabled',
            customHeaderProps: {w: '28px'},
            customObject: (disabled: boolean) => (
                <Stack w={'full'} align={'center'} justifyContent={'center'}>
                    <DynamicCheckIconComponent size={25} isChecked={!disabled} label={disabled?'Struttura non attiva':'Struttura attiva'}/>
                </Stack>

            )
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:NOME_STRUTTURA'),
            objectKey: 'appartamento',
            filteringKey: 'nomeStruttura',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:NOME_INTERNO'),
            objectKey: 'nomeInterno',
            filteringKey: 'nomeInterno',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:INTERNO'),
            objectKey: 'interno',
            filteringKey: 'strutturaIndirizzo_interno',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:POSTI'),
            objectKey: 'maxOccupancy',
            filteringKey: 'maxOccupancy',
            isFilterable: true,
        },
    ]

    const columnEntityMapping = (params: PaginatedTableFilters) => {
        switch (params.filteringColumn) {
            case 'appartamento':
                return {
                    nomeStruttura: params.searchText,
                };
            case 'nomeInterno':
                return {
                    nomeInterno: params.searchText,
                };
            case 'interno':
                return {
                    strutturaIndirizzo: {
                        interno: params.searchText
                    },
                };
            case 'maxOccupancy':
                return {
                    maxOccupancy: params.searchText,
                };
            default:
                return {};
        }
    }

    const handleUpdateSmobuData = () => {
        setIsUpdateDataSmobuLoading(true)
        dispatch(HomeAction.updateSmobuDataAction()).then((response) => {
            if ((response.payload as number) >= 0) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'I dati sono stati aggiornati correttamente, dati aggiunti: ' + response.payload,
                    status: 'success',
                }))
                fetchData(strutturePaginated.pageIndex, strutturePaginated.pageSize)
            }
        }).finally(() => {
            setIsUpdateDataSmobuLoading(false)
        })

    }

    const fetchData = async (pageIndex: number, pageSize: number, entity?: any,sortDirection?:string,sortField?:string) => {
        console.log('fetchData called ', pageIndex, pageSize, entity)
        setLoading(true)
        await dispatch(HomeAction.fetchStruttureAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection && sortDirection != ''? sortDirection : 'DESC',
            sortField: sortField && sortField != ''? sortField : 'disabled',
        }))
        setLoading(false)

    }

    return (
        <SimplePage title={t('homePage:TITLES:APPARTAMENTI')}>
            <MyPaginatedTable
                pages={strutturePaginated?.dataTable}
                fetchData={fetchData}
                totalPages={strutturePaginated?.totalPages}
                columns={tableColumns}
                loading={loading}
                pageIndex={strutturePaginated.pageIndex}
                pageSize={strutturePaginated.pageSize}
                setPageIndex={(pageIndex: number) => dispatch(AppartamentiTableAction.setPageIndexAction(pageIndex))}
                setPageSize={(pageSize: number) => dispatch(AppartamentiTableAction.setPageSizeAction(pageSize))}
                setOrderingFilters={(value: OrderingFilters) => dispatch(AppartamentiTableAction.setOrderingFiltersAction(value))}
                orderingFilters={{sortField:strutturePaginated.sortField,sortDirection:strutturePaginated.sortDirection}}
                onRowClick={(data: StrutturaTableModel) => navigate(`${data.id}`)}
                columnEntityMapping={columnEntityMapping}
                header={{
                    placeholder: t('homePage:TEXTS:TYPE_SOMETHING'),
                    removeDatePiker: true,
                    filters:strutturePaginated.filters,
                    setFilters: (filters: PaginatedTableFilters) => dispatch(AppartamentiTableAction.setFiltersAction(filters)),
                    children: (
                        <Button {...primaryShortButtonProps} isLoading={isUpdateDataSmobuLoading}
                                onClick={handleUpdateSmobuData}>Aggiorna dati Smobu</Button>
                    )
                }}
            />
        </SimplePage>

    );
};
