import React from 'react';
import { Box, Button, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { IoCloudDownloadOutline, IoTrashBin } from "react-icons/all";
import {Allegato} from "../../../../store/gestioneManutenzioni/types";


interface AllegatiTableProps {
    allegati: Allegato[];
    downloadAttachment: (id: number, fileName : string) => void;
    deleteAttachment: (id: number) => void;
}

const AllegatiTable: React.FC<AllegatiTableProps> = ({ allegati,  downloadAttachment, deleteAttachment }) => {
    return (
        <Box w={'full'} position={'relative'} overflowX={'auto'} my={16}>
            <Table backgroundColor={'background'} rounded={'m'} size='m' variant={'simple'} w={'full'} m={0}>
                <Tbody>
                    {!allegati || allegati.length === 0  && (
                        <Tr>
                            <Td colSpan={3} align={'center'} justifyContent={'center'}>
                                <Text fontSize={'xs'} textAlign={'center'} color={'subtitleColor'}>Nessun allegato presente</Text>
                            </Td>
                        </Tr>
                    )}
                    {allegati?.map((row: Allegato) => {
                        return (
                            <Tr key={`${row.id}`}>
                                <Td>{row.nome}</Td>
                                <Td>
                                    <Button onClick={() => downloadAttachment(row.id, row.nome)}>
                                        <IoCloudDownloadOutline/>
                                    </Button>
                                </Td>
                                <Td>
                                    <Button onClick={() => deleteAttachment(row.id)}>
                                        <IoTrashBin/>
                                    </Button>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Box>
    );
};

export default AllegatiTable;