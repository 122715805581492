import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {GestioneStruttura} from 'models/struttura/struttura.be';
import {OpenVarcoMaintenanceRequestParams, OpenVarcoRequestParams} from 'store/gestioneStrutture/types';
import { LoginMaintenanceParams} from "../../store/auth/types";

const fetchStruttureMaintenance = async (params : LoginMaintenanceParams ): Promise<GestioneStruttura[]> => {
    console.log('Request [fetchGestioneStrutture]');

    const response: AxiosResponse<GestioneStruttura[]> = await appAxios.get(`/api/public/fetchStruttureMaintenance/${params.codice}`);

    console.log('Response [fetchGestioneStrutture] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchGestioneStrutture')
}

const openVarcoStruttura = async ({strutturaId,varcoId}:OpenVarcoRequestParams): Promise<boolean> => {
    console.log('Request [OpenVarcoStrutture]');

    const response: AxiosResponse<void> = await appAxios.post(`/api/cerbero/struttura/openVarcoExtend/${strutturaId}/${varcoId}`);

    console.log('Response [OpenVarcoStrutture] ', response.data);
    if (response && response.status === 200) {
        return true
    }

    throw new Error('Error during OpenVarcoStrutture')
}
const openVarcoMaintenanceStruttura = async ({varcoId,codice}:OpenVarcoMaintenanceRequestParams): Promise<boolean> => {
    console.log('Request [openVarcoMaintenance]');

    const response: AxiosResponse<void> = await appAxios.post(`/api/public/openVarcoMaintenance/${codice}/${varcoId}`);

    console.log('Response [openVarcoMaintenance] ', response.data);
    if (response && response.status === 200) {
        return true
    }

    throw new Error('Error during openVarcoMaintenance')
}
export const GestioneStruttureService = {
    fetchStruttureMaintenance,
    openVarcoStruttura,
    openVarcoMaintenanceStruttura
};
