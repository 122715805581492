import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {authReducer} from 'store/auth/auth.reducer';
import {settingsReducer} from 'store/settings/settings.reducer';
import {uiManagerReducer} from 'store/uiManager/uiManager.reducer';
import Interceptor from 'service/interceptor/auth.interceptor';
import {homeReducer} from 'store/home/home.reducer';
import {strutturaCreationReducer} from 'store/strutturaCreation/strutturaCreation.reducer';
import {reportsReducer} from 'store/reports/reports.reducer';
import {gestioneStruttureReducer} from 'store/gestioneStrutture/gestioneStrutture.reducer';

export const rootReducer = combineReducers( {
        //Add yours reducers
        authReducer,
        settingsReducer,
        uiManagerReducer,
        homeReducer,
        strutturaCreationReducer,
        reportsReducer,
        gestioneStruttureReducer,
})

export const store = configureStore({
    reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type RootStore = typeof store
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
Interceptor.interceptor(store);
