import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Text
} from '@chakra-ui/react';
import {loadTranslations} from 'i18n/i18n.service';
import MyButton from "../../../../shared/components/default/myButton/myButton.component";

interface AlertVideoProps{
    nomeCitofono: string
    isOpen: boolean,
    onClose: () => void,
    onConfirm: () => void
}

export const AlertNomeCitofono:FC<AlertVideoProps> = (props) => {
    const {t} = useTranslation();
    const cancelRef = React.useRef<any>()
    const [isLoading, setIsLoading] = useState(false)

    const handleContinue = async () => {
        setIsLoading(true)
        await props.onConfirm()
        setIsLoading(false)
        props.onClose()
    }

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={props.onClose}
            size={'lg'}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="md" fontWeight="bold">

                        <Text fontSize={'md'} color={"titleColor"}>{'Attenzione'}</Text>

                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text fontSize={'md'} color={'titleColor'}>{'Per procedere è necesario suonare il seguente citofono'}</Text>
                        <Text fontSize={'xl'} fontWeight={"bold"} color={'titleColor'}>{props.nomeCitofono}</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button variant={'outline'} w={'full'} onClick={props.onClose} ml={3}>
                            {'indietro'}
                        </Button>
                        <MyButton myType={'PRIMARY'} onClick={handleContinue} isLoading={isLoading} ml={3}>{'continua'}</MyButton>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
