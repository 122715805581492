import React, {FC, useEffect} from 'react';
import {useAppSelector} from 'store/store.config';
import {useToast} from '@chakra-ui/react';
import {ToastMessage} from 'store/uiManager/types';
import {useTranslation} from 'react-i18next';

export const ToastProvider: FC = ({children}) => {
    const {t} = useTranslation()
    const {toast} = useAppSelector(state => state.uiManagerReducer)
    const chakraToast = useToast()
    const TOAST_DURATION = 3000;

    useEffect(() => {
        if(toast?.description) {
            showToast(toast)
        }
    }, [toast])


    const showToast = (toastMessage:ToastMessage) => {
        chakraToast({
            title: toastMessage.isI18nKey? t(`${toastMessage.title}`) : toastMessage.title,
            description: toastMessage.isI18nKey? t(toastMessage.description) : toastMessage.description,
            duration: toastMessage.duration ? toastMessage.duration : TOAST_DURATION, //
            status: toastMessage.status,
            isClosable: toastMessage.isClosable,
            position: toastMessage.position? toastMessage.position : 'top-right'
        })
    }


    return (
        <>
            {children}
        </>
    );
};
