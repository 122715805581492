import {Struttura} from 'models/struttura/struttura.model';
import {StrutturaBe} from 'models/struttura/struttura.be';
import {struttureDetailModelMapper} from 'service/mappers/strutturaDetail.mapper';
import {struttureBaseModelMapper} from 'service/mappers/strutturaBase.mapper';

export const struttureModelMapper = (s: StrutturaBe) : Struttura => {
    return ({
        id: s.id,
        nomeStruttura:  s.nomeStruttura,
        channelManagerId: s.channelManagerId,
        maxOccupancy: s.maxOccupancy,
        disabled: s.disabled,
        servizi: s.servizi,
        strutturaIndirizzo: s.strutturaIndirizzo,
        strutturaCatasto: s.strutturaCatasto,
        proprietariStruttura: s.proprietariStruttura,
        procedure: s.procedure,
        strutturaLinks: s.strutturaLinks,
        strutturaDetail: struttureDetailModelMapper(s),
        ...struttureBaseModelMapper(s),
    })

}
