// import React, {FC, useState} from 'react';
// import {
//     Center,
//     Image,
//     ScaleFade,
//     Text,
//     VStack,
//     chakra, Box,
// } from '@chakra-ui/react';
// import {IoCloudUploadOutline} from "react-icons/io5";
//
// interface SingleFileUploaderProps {
//     size?: string;
//     onFileUpload: (file: File) => void;
//     id: string;
// }
//
// const SingleFileUploader: FC<SingleFileUploaderProps> = ({
//                                                              size = '100px',
//                                                              onFileUpload,
//                                                              id
//                                                          }) => {
//     const [uploadedFile, setUploadedFile] = useState(null);
//
//     const handleFileChange = async (event: any) => {
//         const file = event.target.files[0];
//         setUploadedFile(file);
//         const formData = new FormData();
//         formData.append("file", file);
//
//         onFileUpload(file);
//
//         // const response = await fetch("/api/upload", {
//         //     method: "POST",
//         //     body: formData,
//         // });
//
//     }
//
//
//     return (
//         <Box
//             style={{
//                 width: "100%",
//                 height: size,
//                 position: "relative",
//                 borderRadius: "4px",
//             }}
//             color={'primary.500'}
//             border={'1px'}
//             borderColor={'primary.500'}
//         >
//             <label htmlFor={id}>
//                 <input
//                     type="file"
//                     id={id}
//                     style={{display: 'none'}}
//                     onChange={handleFileChange}
//                 />
//
//                 <div
//                     style={{
//                         width: "100%",
//                         height: "100%",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         transition: "background-color 0.3s",
//                         gap: "8px"
//                     }}
//                 >
//                     <IoCloudUploadOutline size={24}/>
//                     <Text fontSize={'md'} fontWeight={'medium'}>Upload</Text>
//                 </div>
//             </label>
//         </Box>
//     );
// };
//
//
// export default SingleFileUploader;

import React, {FC, useState} from 'react';
import {
    Center,
    Image,
    ScaleFade,
    Text,
    VStack,
    chakra, Box,
} from '@chakra-ui/react';
import {IoCloudUploadOutline} from "react-icons/all";
interface SingleFileUploaderProps {
    size?: string;
    onFileUpload: (file: File, isAllegatoPre: boolean) => void;
    id: string;
    isAllegatoPre: boolean;
}
const SingleFileUploader: FC<SingleFileUploaderProps> = ({
                                                             size = '100px',
                                                             onFileUpload,
                                                             id,
                                                             isAllegatoPre,
                                                         })  => {
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        setUploadedFile(file);
        const formData = new FormData();
        formData.append("file", file);

        onFileUpload(file,isAllegatoPre);

        // const response = await fetch("/api/upload", {
        //     method: "POST",
        //     body: formData,
        // });

    }


    return (
        <Box
            style={{
                width: "100%",
                height: size,
                position: "relative",
                borderRadius: "4px",
            }}
            color={'primary.500'}
            border={'1px'}
            borderColor={'primary.500'}
        >
            <label htmlFor={id}>
                <input
                    type="file"
                    id={id}
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                />

                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background-color 0.3s",
                        gap: "8px"
                    }}
                >
                    <IoCloudUploadOutline size={24}/>
                    <Text fontSize={'md'} fontWeight={'medium'}>Upload</Text>
                </div>
            </label>
        </Box>
    );
};


export default SingleFileUploader;