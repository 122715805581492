import {createAction} from '@reduxjs/toolkit';
import {ToastMessage} from 'store/uiManager/types';


export const enum UI_MANAGER_ACTION {
    SHOW_TOAST = 'uiManager/SHOW_TOAST',
}

const showToast = createAction(UI_MANAGER_ACTION.SHOW_TOAST, (params:ToastMessage) => {
    return { payload: params };
})


export const uiManagerActions = {
    showToast,
}
