import React, {useEffect, useState} from 'react';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {MyCard} from 'shared/components/default/myCard/myCard.component';
import {GestioneStruttureActions} from 'store/gestioneStrutture/gestioneStrutture.action';
import {Box, Flex, Input, InputGroup, InputLeftElement, SimpleGrid, Stack, Text} from '@chakra-ui/react';
import {GestioneStrutturaCard} from 'screens/gestioneStrutture/components/gestioneStrutturaCard.component';
import {VarcoButton} from 'shared/components/custom/varcoButton/varcoButton.component';
import {useNavigate, useParams} from "react-router-dom";
import {uiManagerActions} from "../../store/uiManager/uiManager.action";
import {RoutesPaths} from "../../navigation/root.routes";
import {AlertSafeAccess} from "./components/alertSafeAccess/alertSafeAccess.component";
import {AlertNomeCitofono} from "./components/alertNomeCitofono/alertNomeCitofono.component";
import {AlertSecretPin, AlertSecretVarco} from "./components/alertSecretPin/alertSecretPin.component";
import {Varco} from "../../models/varco.model";
import {useTranslation} from "react-i18next";
import {loadTranslations} from "../../i18n/i18n.service";
import enTexts from './i18n/en.json';
import itTexts from './i18n/it.json'
import {IoSearch} from "react-icons/io5";
export const GestioneStrutturePage = () => {
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('gestStrutture', i18nTexts)
    const {gestioneStrutture, isError, isLoading} = useAppSelector(state => state.gestioneStruttureReducer)
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate()
    const codice = params.id
    // Varco
    const [isLoadingVarco, setIsLoadingVarco] = useState(false);

    const [varcoSelectedButton, setVarcoSelectedButton] = useState<Varco | undefined>(undefined)

    //Multiple opening
    const [isAlertBackupAccessOpen, setIsAlertBackupAccessOpen] = React.useState(false)
    const onClose = () => setIsAlertBackupAccessOpen(false)

    // varcoSelectedAlert
    const [alertSecretVarco, setAlertSecretVarco] = useState<AlertSecretVarco | undefined>(undefined);

    //Alert nome citofono
    const [isNomeCitofonoAlertOpen, setIsNomeCitofonoAlertOpen] = React.useState(false)
    const onNomeCitofonoAlertClose = () => setIsNomeCitofonoAlertOpen(false)
    const [nomeCitofonoAlert, setNomeCitofonoAlert] = useState<string>('ciao')

    //Alert Safe Delete
    const [isSafeAccessOpen, setIsSafeAccessOpen] = React.useState(false)
    const onSafeAccessClose = () => setIsSafeAccessOpen(false)

    const [searchTerm, setSearchTerm] = useState('');
    const filteredGestioneStrutture = gestioneStrutture
        .filter(s => s.nomeStruttura.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => b.varchi.length - a.varchi.length);

    useEffect(() => {
        if(codice){
        fetchData(codice);
        }

    }, []);

    const fetchData =async (id: string) => {

             await dispatch(GestioneStruttureActions.fetchStruttureMaintenanceAction({
                    codice: id
                }
            )).then((response) => {
                if (!response.payload) {
                        dispatch(uiManagerActions.showToast({
                            title: 'Errore',
                            description: 'Si è verificato un errore',
                            status: 'error',
                        }))
                        navigate(RoutesPaths.LOGIN_MAINTENANCE.toString());
                }
            }
        )

    };

    // const handleAperturaVarco = async (strutturaId: number, varcoId: number | undefined) => {
    //     if (varcoId) {
    //         setIsLoadingVarco(varcoId);
    //         await dispatch(GestioneStruttureActions.OpenVarcoMaintenanceAction({
    //             varcoId,
    //             strutturaId,
    //             codice
    //         }))
    //         setIsLoadingVarco(undefined)
    //     }
    // }
    const handleOpenVarco = async (varco: Varco) => {
        console.log('Varco', varco)
        setVarcoSelectedButton(varco)
        if (varco.richiestaCitofono && varco.nomeCitofono) {
            setIsNomeCitofonoAlertOpen(true)
            setNomeCitofonoAlert(varco.nomeCitofono)
        } else {
            handleOpenVarcoSecondStep(varco)
        }
    }

    const handleOpenVarcoSecondStep = async (varco?: Varco) => {

        // const handleMultipleOpenings = (newOpening: Varco) => {
        //     console.log(`New opening for ${newOpening.id}, old multiple opening ${multipleOpeningsCounter.counter} times for varco ${multipleOpeningsCounter.id}`)
        //     const openingsCounter = multipleOpeningsCounter.counter + 1
        //
        //     if (multipleOpeningsCounter.id === newOpening.id) {
        //         setMultipleOpeningsCounter({id: newOpening.id, counter: openingsCounter})
        //         return openingsCounter >= 3
        //     } else {
        //         setMultipleOpeningsCounter({id: newOpening.id, counter: 1})
        //     }
        //     return false
        // }

        if (varco) {
            // Alert too many openings
            // if (handleMultipleOpenings(varco)) {
            //     setIsAlertBackupAccessOpen(true)
            //     setMultipleOpeningsCounter({id: varco.id, counter: 0})
            //     return
            // }

            if (varco.tipoAutomazione === 0 || varco.tipoAutomazione === 1) {
                handleShowAlternativeMethodAlert(varco, undefined, false)
                setVarcoSelectedButton(undefined)
                return
            }
            if (varco.tipoAutomazione === 2) {
                handleMetodoAlternativo(varco, false)
                setVarcoSelectedButton(undefined)
                return
            }

            setIsLoadingVarco(true);
            // const realTimeGeolocation = await handleGetRealTimeGeolocation()
            // const openVarcoParams = {id: varco.id, geolocation: realTimeGeolocation, force: false}
            const openVarcoResponse =  await dispatch(GestioneStruttureActions.OpenVarcoMaintenanceAction({
                varcoId : varco.id,
                            codice
                        }))

            // if (openVarcoResponse.payload === false) {
            //     handleOpenVarcoWithBackupForce(openVarcoParams)
            // }

            setIsLoadingVarco(false);
        }
    }


    // /**
    //  * Open varco with backup force, this method is called on the popup alert of the 3 times opening varco if the first open varco
    //  * fails because of 409 error
    //  */
    // const handleOpenVarcoWithBackupForce = async (params: { id: number, geolocation: GeolocationModel }) => {
    //     dispatch(SettingsActions.showAlert({
    //         title: t('common:TEXTS:WARNING'),
    //         onConfirm: async () => {
    //             await dispatch(postPrenotazioneActions.openVarco({
    //                 ...params,
    //                 force: true
    //             }));
    //         },
    //         customConfirmText: t('common:BUTTONS:OPEN_VARCO'),
    //         customCancelText: t('common:BUTTONS:CANCEL'),
    //         body: () => {
    //             return (
    //                 <Box>
    //                     <Text fontSize={'md'}>
    //                         {t('common:ERRORS:OPEN_VARCO:IN_FRONT')}
    //                     </Text>
    //                 </Box>
    //             )
    //         }
    //     }))
    // }

    const handleMetodoAlternativo = (varco: Varco | undefined, isBackup: boolean) => {
        if (varco) {
            onClose()
                handleShowAlternativeMethodAlert(varco, varco.codiceFisso, isBackup)
        }
    }

    const handleShowAlternativeMethodAlert = (varco: Varco, pin?: string, isBackup?: boolean) => {
        let title = 'gestStrutture:TEXTS:ALTERNATIVE_ACCESS:WATCH_VIDEO_TITLE'
        let description = 'gestStrutture:TEXTS:ALTERNATIVE_ACCESS:WATCH_VIDEO_DESCRIPTION'

        if (varco.tipoAutomazione !== 0 && pin !== undefined) {
            title = 'gestStrutture:TEXTS:ALTERNATIVE_ACCESS:SECRET_PIN_TITLE'
            description = 'gestStrutture:TEXTS:ALTERNATIVE_ACCESS:SECRET_PIN_DESCRIPTION'
            setAlertSecretVarco({
                varco,
                pin,
                title,
                description,
                isBackup
            })
            return
        }
        setAlertSecretVarco({
            varco,
            pin: undefined,
            title,
            description,
            isBackup
        })
    }

    return (
        <SimplePage title={'Gestione Strutture'} isError={isError} isLoading={isLoading} >
            <>
                {/*<AlertBackupAccessComponent isOpen={isAlertBackupAccessOpen}*/}
                {/*                            onClose={onClose}*/}
                {/*                            onAlternativeAccess={() => handleMetodoAlternativo(varcoSelectedButton, true)}/>*/}

                {alertSecretVarco && <AlertSecretPin alertSecretVarco={alertSecretVarco}
                                                     isOpen={!!alertSecretVarco}
                                                     onClose={() => {
                                                         setAlertSecretVarco(undefined)
                                                     }}/>
                }

                <AlertNomeCitofono nomeCitofono={nomeCitofonoAlert}
                                   isOpen={isNomeCitofonoAlertOpen}
                                   onConfirm={() => handleOpenVarcoSecondStep(varcoSelectedButton)}
                                   onClose={() => {
                                       setNomeCitofonoAlert('')
                                       onNomeCitofonoAlertClose()
                                   }}
                />

                <AlertSafeAccess
                    isSafeAccessOpen={isSafeAccessOpen}
                    onSafeAccessClose={onSafeAccessClose}
                    onOpenVarco={() => {
                        if (varcoSelectedButton) {
                            handleOpenVarco(varcoSelectedButton)
                        }
                        onSafeAccessClose()
                    }}
                    varco={varcoSelectedButton}
                />
            </>

            <Flex justifyContent={"center"}>
            <Box w={320} >

                <InputGroup>
                    <InputLeftElement
                        _hover={{cursor: 'pointer'}}
                        children={<IoSearch color={'iconColor'} />}
                    />

                    <Input
                        maxW={320}
                        value={searchTerm}
                        onChange={e => {
                            setSearchTerm(e.target.value);
                        }}
                        backgroundColor={'background'}
                        placeholder="Nome Struttura"
                    />
                </InputGroup>
            </Box>
            </Flex>
            {Array.isArray(filteredGestioneStrutture) && filteredGestioneStrutture.map(s => {
                return (
                    <Flex justifyContent={"center"}>
                        <Box  my={10} key={s.id}>
                            <MyCard title={s.nomeStruttura}  containerProps={{
                                w: '100%',
                                minH: '150px',
                                backgroundColor: 'background',
                                position: 'relative'
                            }}>
                                <GestioneStrutturaCard gestioneStruttura={s}/>
                                <SimpleGrid columns={{sm: 1, lg: 2, xl: 3}} spacing={3} alignContent={'center'}
                                            justifyItems={"center"} justifyContent={'center'}>
                                    {s.varchi.map((v) => (
                                        <VarcoButton varco={v} key={v.id}
                                                     isLoading={varcoSelectedButton?.id === v.id && isLoadingVarco}

                                                     m={0}
                                                     onClick={() => {
                                                         setVarcoSelectedButton(v)
                                                         setIsSafeAccessOpen(true)
                                                     }}
                                        />
                                    ))}
                                    {!s.varchi.length && (
                                        <Text fontSize={'sm'}
                                              fontWeight={'thin'}
                                              w={'100%'}
                                              m={2}>Nessun varco disponibile per la struttura</Text>)}
                                </SimpleGrid>
                            </MyCard>
                        </Box>
                    </Flex>
                )
            })}
        </SimplePage>
    );
};

