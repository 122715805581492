import React from 'react';
import { Box, Table, Thead, Tr, Th, HStack, Text, Tbody, Td, Button } from '@chakra-ui/react';
import { IoTrashBin } from "react-icons/all";
import {StatoPagamento} from "../../../../store/gestioneManutenzioni/types";
import {backendToFrontedDateFormatter} from "../../../../utils/dates/date.utils";

interface PagamentiTableProps {
    tableColumns: any[];
    pagamenti: any[];
    handleStatoPagamentoClick: (id: number) => void;
    handleDeletePayment: (id: number) => void;
}

const PagamentiTable: React.FC<PagamentiTableProps> = ({ tableColumns, pagamenti, handleStatoPagamentoClick, handleDeletePayment }) => {
    return (
        <Box w={'full'} position={'relative'} overflowX={'auto'}>
            <Table backgroundColor={'background'} rounded={'lg'} variant={'simple'} w={'full'} m={0}>
                <Thead>
                    <Tr>
                        {tableColumns.map((column,) => (
                            <Th style={{
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                wordWrap: 'break-word'
                            }}>
                                <HStack>
                                    <Text size={'sm'} >
                                        {column.columnName}
                                    </Text>
                                </HStack>
                            </Th>
                        ))}
                        <Th>
                            Elimina
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {!pagamenti || pagamenti.length === 0  && (
                        <Tr>
                            <Td colSpan={tableColumns.length} align={'center'} justifyContent={'center'}>
                                <Text fontSize={'md'} textAlign={'center'} color={'subtitleColor'}>Nessun dato</Text>
                            </Td>
                        </Tr>
                    )}
                    {pagamenti?.map((row: any, i) => {
                        return (
                            <Tr key={`${row['id']}`}>
                                {tableColumns.map(({objectKey, customObject}) => {
                                    if (objectKey === 'statoPagamento') {
                                        return (
                                            <Td key={`${row['id']}-${objectKey}`} onClick={() => handleStatoPagamentoClick(row['id'])}>
                                                {customObject ? customObject(row[objectKey]) : StatoPagamento[row[objectKey] as keyof typeof StatoPagamento]}
                                            </Td>
                                        );
                                    } else if (objectKey === 'dataPagamento') {
                                        return (
                                            <Td key={`${row['id']}-${objectKey}`}>
                                                {backendToFrontedDateFormatter(row[objectKey])}
                                            </Td>
                                        );
                                    } else {
                                        return (
                                            <Td key={`${row['id']}-${objectKey}`}>
                                                {customObject ? customObject(row[objectKey]) : row[objectKey]}
                                            </Td>
                                        );
                                    }
                                })}
                                <Td>
                                    <Button onClick={() => handleDeletePayment(row['id'])}>
                                        <IoTrashBin/>
                                    </Button>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Box>
    );
};

export default PagamentiTable;