import React, {FC, ReactNode} from 'react';
import {HStack, Text} from '@chakra-ui/react';
import {priceFormatter} from 'utils/numbers.util';
import {dateFormatter, dateTimeFormatter, timeFormatter} from 'utils/dates/date.utils';

export const BoldInlineText = ({children}: { children: ReactNode }) => (
    <Text as={'span'} fontWeight={'semibold'} fontSize={'md'} color={'titleColor'}>
        {children}
    </Text>
);

interface TextWithStartFrontLeadingProps {
    leading: string,
    main?: string | number| boolean
    isPrice?: boolean,
    isDate?: boolean,
    isDateTime?: boolean,
    isTime?: boolean,
    isLink?: boolean,
    isBoolean?: boolean,
    hiddenOnEmpty?: boolean
}

export const TextWithStartFrontLeading: FC<TextWithStartFrontLeadingProps> = ({leading,hiddenOnEmpty, main, isLink, isPrice,isDate,isTime,isDateTime}) => {

    const renderMain = () => {
        if (main == undefined || main == null) return 'Dato non disponibile';

        if (isPrice && typeof main === 'number') {
            return priceFormatter(main)
        }

        if (isLink && typeof main === 'string') {
            return <a href={main}>{main}</a>
        }

        if (isDate && typeof main === 'string') {
            return dateFormatter(main)
        }

        if (isDateTime && typeof main === 'string') {
            return dateTimeFormatter(main)
        }

        if (isTime && typeof main === 'string') {
            return timeFormatter(main)
        }

        if (typeof main === 'boolean') {
            return main === undefined ? 'Dato non disponibile' : main === true ? 'Si' : 'No'
        }

        return main
    }

    if (hiddenOnEmpty && ( main == undefined || main === '')){
        return null;
    }

    return (
        <HStack justify={'center'} w={'auto'}>
            <Text><BoldInlineText>{leading}</BoldInlineText> {renderMain()}</Text>
        </HStack>
    )
};

export const percentagePrice = (price: string | number) =>{
    return `${price}%`

}
