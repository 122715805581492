import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import FileSaver from 'file-saver';
import {convertBase64ToBlob} from 'utils/utils';

interface FileDownloaderProps {
    url: string;
    fileName: string;
    multipleFileNames?: string[];
    params?: any;
}

const downloadFile = async (props: FileDownloaderProps): Promise<void> => {
    console.log('Request [downloadFile] params:', props);
    const response: AxiosResponse<any> = await appAxios.get(props.url, { responseType: 'blob', params: props.params });

    console.log('Response [downloadFile] ', response);
    if (response && response.data) {
        let effectiveFileName = props.fileName;
        try {
            const fileNameHeader = 'content-disposition';
            const contentDisposition = response.headers[fileNameHeader];
            if (contentDisposition) {
                const regex = /filename="([^"]+)"/;
                const match = contentDisposition.match(regex);

                if (match && match[1]) {
                    effectiveFileName = match[1];
                }
            }
            console.log(`Received header [${fileNameHeader}]: ${contentDisposition}, effective fileName: ${effectiveFileName}`);
        } catch (e) {
            console.log('Request [downloadFile] error:', e);
        }

        FileSaver.saveAs(response.data, effectiveFileName);
        return;
    }

    throw new Error('Error during downloadFile');
}

const downloadMultipleImages = async (props: FileDownloaderProps): Promise<void> => {
    console.log('Request [downloadMultipleImages] params:', props);
    const response: AxiosResponse<string[]> = await appAxios.get(props.url, {params: props.params});

    console.log('Request [downloadMultipleImages] ', response);
    if (response && response.data) {
        if(response.data.length === 0) {
            throw new Error('No images to download')
        }
        response.data.map((file: string, index) => {
            const dataFile = convertBase64ToBlob(file);
            FileSaver.saveAs(dataFile, props.multipleFileNames ? props.multipleFileNames[index] : props.fileName);
        })
        return
    }
    throw new Error('Error during downloadMultipleImages')
}

export enum ResourcesAvailable {
    portaleAlloggiati = 'portaleAlloggiati',
    portaleROS1000 = 'portaleROS1000',
    fotoDocumenti = 'fotoDocumenti',
    reportStruttura = 'reportStruttura',
    reportGenerale = 'reportGenerale',
}


export interface DownloadReportStrutturaParams {
    idStruttura: number;
    idReport: number;
}

export interface DownloadReportGeneraleParams {
    idReport: number;
    dataInizio: string;
    dataFine: string;
}


export interface DownloaderManagerProps {
    resource: ResourcesAvailable;
    payload: any;
}

const downloaderManager = (props: DownloaderManagerProps) => {
    switch (props.resource) {
        case ResourcesAvailable.fotoDocumenti:
            return downloadMultipleImages({
                url: `/api/cerbero/prenotazione/getFotoDocumenti/${props.payload}`,
                fileName: 'fotoDocumento.jpg',
                multipleFileNames: ['fronteDocumento.jpg', 'retroDocumento.jpg']
            }); //TODO check the data that the backend returns
        case ResourcesAvailable.portaleAlloggiati:
            return downloadFile({
                url: `/api/cerbero/prenotazione/portaleAlloggiati/${props.payload}`,
                fileName: 'portaleAlloggiati.txt'
            });
        case ResourcesAvailable.portaleROS1000:
            return downloadFile({
                url: `/api/cerbero/prenotazione/portaleROS1000/${props.payload}`,
                fileName: 'portaleROS1000.txt'
            });
        case ResourcesAvailable.reportStruttura:
            const paramsReportStruttura = props.payload as DownloadReportStrutturaParams;
            return downloadFile({
                url: `/api/cerbero/getReport/${paramsReportStruttura.idReport}/${paramsReportStruttura.idStruttura}`,
                fileName: 'reportStruttura.xlsx'
            });
        case ResourcesAvailable.reportGenerale:
            const paramsReportGenerale = props.payload as DownloadReportGeneraleParams;
            return downloadFile({
                url: `/api/cerbero/getReportGeneral/${paramsReportGenerale.idReport}`,
                fileName: 'reportGenerale.xls',
                params: {
                    start: paramsReportGenerale.dataInizio,
                    end: paramsReportGenerale.dataFine
                }
            });
        default:
            return;
    }
}


export const DownloadsService = {
    downloaderManager
};
