import {createReducer} from '@reduxjs/toolkit';
import {SettingsState} from './types';
import {defaultLanguage} from 'i18n/i18n.config';
import {SettingsActions} from 'store/settings/settings.action';

const initialState: SettingsState = {
    language: defaultLanguage,
    serverLanguage:true
};

export const settingsReducer = createReducer(initialState, (builder) => {
    builder.addCase(SettingsActions.setLanguageSetting, (state, action) => {
        const {language,overrideServer} = action.payload;
        return {
            ...state,
            language,
            serverLanguage: !overrideServer
        }
    });
    builder.addDefaultCase((state, action) => {
        return state;
    });
});
