import React, {FC} from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Text
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {getValidUrl} from 'utils/utils';
import {TipoAutomazione, Varco} from 'models/varco.model';

export interface AlertSafeAccessProps {
    isSafeAccessOpen: boolean,
    onSafeAccessClose: () => void,
    onOpenVarco: () => void,
    varco?: Varco,
}

export const AlertSafeAccess: FC<AlertSafeAccessProps> = ({
                                                              isSafeAccessOpen,
                                                              onSafeAccessClose,
                                                              onOpenVarco,
                                                              varco
                                                          }) => {
    const {t} = useTranslation();
    const cancelSafeAccessRef = React.useRef<any>()

    const renderVideoForMQTT = () => {
        if ((varco?.tipoAutomazione === TipoAutomazione.MQTT && varco?.videoYT && varco?.videoYT !== '')) {
            return (
                <Box mb={1}>
                    <iframe src={getValidUrl(varco?.videoYT)}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                            width={'100%'}
                            height={'250px'}
                    />
                </Box>
            )
        }
    }

    return (
        <AlertDialog
            isOpen={isSafeAccessOpen}
            leastDestructiveRef={cancelSafeAccessRef}
            onClose={onSafeAccessClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t('gestStrutture:TEXTS:SAFE_ACCESS:TITLE')}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {renderVideoForMQTT()}
                        <Box>
                            <Text fontSize={'md'}>
                                {t('gestStrutture:TEXTS:SAFE_ACCESS:SUBTITLE')}
                            </Text>
                            <Text fontSize={'sm'}
                                  fontWeight={'semibold'}>
                                {t('gestStrutture:TEXTS:SAFE_ACCESS:IN_FRONT')}
                            </Text>
                        </Box>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelSafeAccessRef} onClick={onSafeAccessClose}>
                            {t('common:BUTTONS:CANCEL')}
                        </Button>
                        <Button colorScheme="red" ml={3} onClick={onOpenVarco}>
                            {t('common:BUTTONS:OPEN')}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
