import React, {FC, useEffect} from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Divider,
    HStack,
    Text
} from '@chakra-ui/react';
import {Varco} from 'models/varco.model';
import {useTranslation} from 'react-i18next';
import {getValidUrl} from 'utils/utils';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {SecretPin} from "./alertSecretPin.slave.component";

interface AlertSecretPinProps {
    alertSecretVarco: AlertSecretVarco,
    urlProcedura?: string,
    isOpen: boolean,
    onClose: () => void,
}

export interface AlertSecretVarco {
    varco: Varco,
    pin?: string,
    title: string,
    description: string,
    isBackup?: boolean,
}

type SectionType = 'SECRET_PIN' | 'WATCH_VIDEO' | 'WATCH_VIDEO_BACKUP' | 'PROCEDURES'

export const AlertSecretPin: FC<AlertSecretPinProps> = (props) => {
    const {t} = useTranslation();

    const cancelRefSecretPin = React.useRef<any>()

    useEffect(() => {
        console.log('props.alertSecretVarco', props.alertSecretVarco)
    }, [])

    const renderDivider = (type: SectionType) => {
        // check if there is a smaller section already rendered
        return (
            <HStack align={'center'} mt={2} justifyContent={'flex-start'}>
                <Box flex={1} w={'full'}>
                    <Text my={2} color={'subtitleColor'}>{t(`homePage:TEXTS:ALERTS:${type}`)}</Text>
                </Box>
                <Box flex={1}>
                    <Divider/>
                </Box>
            </HStack>
        )
    }

    const renderVideoBackupSection = (videoBackup?: string) => {
        if (!videoBackup || !props.alertSecretVarco?.isBackup) return null;
        return (
            <iframe src={getValidUrl(videoBackup)}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    width={'100%'}
                    height={'250px'}
            />
        )
    }

    const renderPinSection = (pin?: string) => {
        if (!pin || pin === '') return null;

        return (
            <>
                {renderDivider('SECRET_PIN')}
                <SecretPin secretPin={pin}/>
            </>
        )
    }

    const renderVideoYTSection = (videoYT?: string) => {
        console.log('videoYT', videoYT)
        if (!videoYT || props.alertSecretVarco?.isBackup) return null;
        return (
            <>
                {renderDivider('WATCH_VIDEO')}
                <iframe src={getValidUrl(videoYT)}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                        width={'100%'}
                        height={'250px'}
                />
            </>
        )
    }

    const renderProcedureSection = (urlProcedura?: string) => {
        if (!urlProcedura || props.alertSecretVarco?.isBackup) return null;
        const handleOpenLink = (link: string) => {
            const url = getValidUrl(link);
            window.open(url, '_blank');
        }
        return (
            <>
                {renderDivider('PROCEDURES')}
                <Button myType={'OUTLINE'} justifyItems={'start'} my={2} alignContent={'start'}
                          leftIcon={<IoDocumentTextOutline/>}
                          onClick={() => handleOpenLink(urlProcedura)}>
                    <Box w={'full'} textAlign={'start'}>
                        <Text>{t('common:BUTTONS:PROCEDURA')}</Text>
                    </Box>
                </Button>
            </>
        )
    }

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRefSecretPin}
            onClose={props.onClose}
            size={'lg'}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="md" fontWeight="bold">
                        {props.alertSecretVarco?.title && t(props.alertSecretVarco?.title)}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {renderVideoBackupSection(props.alertSecretVarco.varco.videoBackup)}
                        {renderPinSection(props.alertSecretVarco.pin)}
                        {renderVideoYTSection(props.alertSecretVarco.varco.videoYT)}
                        {renderProcedureSection(props.alertSecretVarco.varco.urlProcedura)}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button variant={'outline'} onClick={props.onClose} ml={3}>
                            {t('common:BUTTONS:BACK')}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
