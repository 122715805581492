import React, {FC} from 'react';
import {COLORS} from 'style/chakraTheme';
import {Tooltip} from '@chakra-ui/react';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';

interface DynamicCheckIconProps {
    isChecked: boolean;
    size?: number;
    label?: string;
}

export const DynamicCheckIconComponent: FC<DynamicCheckIconProps> = (props) => {
    if (props.label) {
        return (
            <Tooltip label={props.label}>
                <span>
                    {props.isChecked ?
                        <IoCheckmarkCircleSharp size={props.size ? props.size : 32} color={COLORS.PRIMARY_500}/>
                        :
                        <IoCloseCircleSharp size={props.size ? props.size : 32} color={COLORS.ICON}/>
                    }
                </span>
            </Tooltip>
        );
    }

    return (
        props.isChecked ?
            <IoCheckmarkCircleSharp size={props.size ? props.size : 32} color={COLORS.PRIMARY_500}/>
            :
            <IoCloseCircleSharp size={props.size ? props.size : 32} color={COLORS.ICON}/>
    )
};
