import {
    PaginatedRequestParamsAndBody,
} from "../home/type";
import {PageableResponse} from "../../models/pageableResponse.model";
import {Accesso} from "../gestioneAccessi/types";
import {Struttura} from "../../models/struttura/struttura.model";

export enum StatoManutenzione {
    IN_ATTESA = 'In attesa',
    IN_CORSO = 'In corso',
    COMPLETATA = 'Completata',
    CANCELLATA = 'Cancellata',
    ALTRO = 'Altro'
}

export enum StatoOta {
    RICHIEDERE_RIMBORSO = 'Richiedere rimborso',
    RIMBORSO_RICHIESTO = 'Rimborso richiesto',
    RIMBORSO_ACCETTATO = 'Rimborso accettato',
    RIMBORSO_RIFIUTATO = 'Rimborso rifiutato',
    ALTRO = 'Altro'
}

export enum Onere {
    OSPITE = 'Ospite',
    Lion = 'Lion',
    CONDOMINIALE = 'Condominiale',
    PROPRIETARIO = 'Proprietario',
    ALTRO = 'Altro'

}

export interface Manutenzione {
    id?: number | null;
    data: string;
    stato?: StatoManutenzione;
    costo?: number;
    numeroDocumento?: string;
    riferimentoOspite?: string;
    importoPagatoManutentore?: number;
    importoRichiestoResponsabile?: number;
    importoPagatoRimborsoPortale?: number;
    statoOta?: StatoOta;
    dataRimborsoPortale?: string;
    onere?: Onere;
    note?: string;
    pagamenti?: Pagamento[];
    struttura?: Struttura;
    nomeStruttura?  : string;
    allegati?: Allegato[];
}

export interface Allegato {
    id: number;
    nome: string;
    isAllegatoPre: boolean;
}

export interface Pagamento {
    id: number;
    dataPagamento: string;
    importo: number;
    statoPagamento: string;
}

export enum StatoPagamento {
    PAGATO = 'Pagato',
    NON_PAGATO = 'Non pagato'
}

export interface FetchManutenzioniParamsAndBody extends PaginatedRequestParamsAndBody {
    entity?: {
        id?: string,
    }
}

export interface FetchManutenzioniResponse extends PageableResponse<Manutenzione[]> {
}