import {CheckIconStatusType} from 'shared/components/custom/checkIconStatus/checkIconStatus.component';
import moment from 'moment';
import {Prenotazione} from 'models/prenotazione.model';

export const getPrenotazioneStatus = (prenotazione:Prenotazione|undefined): CheckIconStatusType => {
    if (prenotazione?.stato === 'cancellation') {
        return 'deleted'
    }
    if (prenotazione?.disabled) {
        return 'error'
    }

    if (moment().isAfter(prenotazione?.partenza)) {
        return 'archived'
    }

    if (prenotazione?.arrivo && prenotazione?.dataCheckInEffettuato &&
        !prenotazione?.disabled &&
        moment(prenotazione.arrivo).isSameOrBefore(moment(), 'day')) {
        return 'success'
    }

    return 'warning'
}
