import * as yup from 'yup';
import moment from 'moment';
import {DATE_FORMAT} from 'utils/dates/date.utils';
import {Manutenzione, Onere, StatoManutenzione, StatoOta} from "../../../../store/gestioneManutenzioni/types";

export const ManutenzioniValidationSchema = yup.object().shape({
    // costo: yup.number().required('Il costo è obbligatorio').min(0),
    // data: yup
    //     .mixed()
    //     .required('La data è obbligatoria'),
    stato : yup.string().required('Lo stato è obbligatorio'),
    statoOta : yup.string().required('Lo stato del portale ota è obbligatorio'),
    onere : yup.string().required('L\'onere è obbligatorio'),
});

export const ManutenzioniFormInitialFrom: Manutenzione = {
    data: moment().format(DATE_FORMAT),
    stato:  undefined,
    costo: 0,
    numeroDocumento: '',
    riferimentoOspite: '',
    importoPagatoManutentore: 0,
    importoRichiestoResponsabile: 0,
    importoPagatoRimborsoPortale: 0,
    statoOta: undefined,
    dataRimborsoPortale: '',
    onere: undefined,
    note: '',
    pagamenti: []
};

