export const borderRadius = 4
export const borderDefault= { border:'1px solid' , borderRadius: `${borderRadius}px`,borderColor: '#E2E8F0',shadow:'lg'}
export const noBorder= { border:undefined ,shadow:undefined}
export const borderDividerRight= { borderRight:'1px solid' ,borderColor: '#E2E8F0'}


export const datePickerStyle={ borderRadius: `${borderRadius}px`,borderColor: '#E2E8F0'}
export const datePickerFullWidthStyle={ borderRadius: `${borderRadius}px`,borderColor: '#E2E8F0',width: '100%'}
export const datePickerHalfWidthStyle={ borderRadius: `${borderRadius}px`,borderColor: '#E2E8F0',width: '50%'}

export const selectAutoCompleteStyle={ width: '100%', border:'1px soldi', borderRadius: `${borderRadius}px`,borderColor: '#E2E8F0'}

export const myCardStyle = {shadow:'md', w:'full', flexGrow:1, borderRadius:borderRadius, align:'flex-start', border:'solid 1px', borderColor:'borderColor', p:5}
export const stretchCardContainerStyle = {w:"full", h:"full", alignItems:"stretch", align:"flex-start"}
