import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PrenotazioneTableModel} from 'models/prenotazione.model';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import 'antd/dist/antd.css';
import {HomeAction} from 'store/home/home.action';
import {MyPaginatedColumn, MyPaginatedTable} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {useNavigate} from 'react-router-dom';
import {PrenotazioniTableAction} from 'store/home/paginatedActions/prenotazioniTable.action';
import {priceFormatter} from 'utils/numbers.util';
import {Stack, Text} from '@chakra-ui/react'
import {CheckIconStatus, CheckIconStatusType} from 'shared/components/custom/checkIconStatus/checkIconStatus.component';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';
import {SortingDirection} from 'shared/components/custom/paginatedTable/components/myColumnHeader.component';
import {IconStatusCheckIn} from "../../../shared/components/custom/IconStatusCheckIn/iconStatusCheckIncomponent";

export const PrenotazioniPage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {prenotazioniPaginated} = useAppSelector(state => state.homeReducer)
    const [loading, setLoading] = useState(false)

    const tableColumns:MyPaginatedColumn[] = [
        {
            columnName: ' stato',
            objectKey: 'status',
            filteringKey: '',
            customHeaderProps: {w: '28px'},
            customObject: (status: CheckIconStatusType) => (
                <Stack w={'full'} align={'center'} justifyContent={'center'}>
                    <CheckIconStatus status={status}/>
                </Stack>
            )
        },
        {
            columnName: 'stato check-in',
            objectKey: 'dataCheckInEffettuato',
            filteringKey: '',
            customHeaderProps: {w: '28px'},
            customObject: (dataCheckInEffettuato: string) => (
                <Stack w={'full'} align={'center'} justifyContent={'center'}>
                    <IconStatusCheckIn dataCheckInEffettuato={dataCheckInEffettuato}/>
                </Stack>
            )
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:APPARTAMENTI'),
            objectKey: 'appartamento',
            filteringKey: 'struttura_nomeStruttura',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:NOME_INTERNO'),
            objectKey: 'nomeInterno',
            filteringKey: 'struttura_nomeInterno',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:OSPITE'),
            objectKey: 'ospite',
            filteringKey: 'nomeOspite',
            isFilterable: true,
        },
        {
            columnName: 'Numero ospiti',
            objectKey: 'numeroOspiti',
            filteringKey: 'numeroAdulti',
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:CHECKIN'),
            objectKey: 'checkIn',
            filteringKey: 'arrivo',
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:CHECKOUT'),
            objectKey: 'checkOut',
            filteringKey: 'partenza',
        },
        {
            columnName: 'Canale prenotazione',
            objectKey: 'canalePrenotazione',
            filteringKey: 'canalePrenotazione',
        },
        {
            columnName: 'Id Smoobu',
            objectKey: 'idPrenotazioneChannel',
            filteringKey: 'idPrenotazioneChannel',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:PRENOTAZIONI:IMPORTO'),
            objectKey: 'importo',
            filteringKey: 'prezzo',
            customObject: (importo: number) => (
                <Text>{priceFormatter(importo)}</Text>
            )
        },]

    const columnEntityMapping = (params:PaginatedTableFilters) => {
        const entity = {
            partenza: params.checkOutData,
            arrivo: params.checkInData
        }
        switch (params.filteringColumn) {
            case 'appartamento':
                return {
                    struttura:{
                        nomeStruttura: params.searchText
                    },
                    ...entity,

                };
            case 'nomeInterno':
                return {
                    struttura:{
                        nomeInterno: params.searchText
                    },
                    ...entity,

                };
            case 'ospite':
                return {
                    nomeOspite: params.searchText,
                    cognomeOspite: params.searchText,
                    ...entity,
                };
            case 'idPrenotazioneChannel':
                return {
                    idPrenotazioneChannel: params.searchText,
                    ...entity,
                };
            default:
                return entity;
        }
    }

    const fetchData = async (pageIndex:number,pageSize:number,entity?:any,sortDirection?:string,sortField?:string) => {
        setLoading(true)
        await dispatch(HomeAction.fetchPrenotazioniAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection,
            sortField: sortField
        }))
        setLoading(false)
    }

    return (
        <SimplePage title={t('homePage:TITLES:PRENOTAZIONI')}>
            <MyPaginatedTable
                pages={prenotazioniPaginated?.dataTable}
                fetchData={fetchData}
                totalPages={prenotazioniPaginated?.totalPages}
                columns={tableColumns}
                loading={loading}
                pageIndex={prenotazioniPaginated.pageIndex}
                pageSize={prenotazioniPaginated.pageSize}
                setPageIndex={(pageIndex: number) => dispatch(PrenotazioniTableAction.setPageIndexAction(pageIndex))}
                setPageSize={(pageSize: number) => dispatch(PrenotazioniTableAction.setPageSizeAction(pageSize))}
                setOrderingFilters={(value: OrderingFilters) => dispatch(PrenotazioniTableAction.setOrderingFiltersAction(value))}
                orderingFilters={{sortField:prenotazioniPaginated.sortField,sortDirection:prenotazioniPaginated.sortDirection}}
                onRowClick={(data:PrenotazioneTableModel)=> navigate(`${data.id}`)}
                columnEntityMapping={columnEntityMapping}
                defaults={{
                    sortField: 'arrivo',
                    sortDirection: SortingDirection.DESC,
                }}
                header={{
                    placeholder: t('homePage:TEXTS:TYPE_SOMETHING'),
                    filters:prenotazioniPaginated.filters,
                    setFilters: (filters: PaginatedTableFilters) => dispatch(PrenotazioniTableAction.setFiltersAction(filters)),
                }}
            />
        </SimplePage>

    );
};
