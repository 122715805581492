import {StrutturaDetailModel} from 'models/struttura/struttura.model';
import {StrutturaBe} from 'models/struttura/struttura.be';

export const struttureDetailModelMapper = (s: StrutturaBe) : StrutturaDetailModel => {
    return ({
        costoImmobile: s.costoImmobile,
        sconto: s.sconto,
        valutazionePeriziaBanca: s.valutazionePeriziaBanca,
        aliquotaIva: s.aliquotaIva,
        notaioAcquisto: s.notaioAcquisto,
        mutuoRichiesto: s.mutuoRichiesto,
        notaioMutuo: s.notaioMutuo,
        accolloMuoto: s.accolloMuoto,
        assicurazioneMutuo: s.assicurazioneMutuo,
        primaPerizia: s.primaPerizia,
        secondaPerizia: s.secondaPerizia,
        terzaPerizia: s.terzaPerizia,
        pratica: s.pratica,
        oneriCdu: s.oneriCdu,
        ristrutturazione: s.ristrutturazione,
        arredamento: s.arredamento,
        rataMutuo: s.rataMutuo,
        spesaIncasso: s.spesaIncasso,
        bonusSuCostoRistrutturazione: s.bonusSuCostoRistrutturazione,
        bonusSuAcquistoRistrutturato: s.bonusSuAcquistoRistrutturato,
    })

}
