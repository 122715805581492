import React, {FC} from 'react';
import {DatePicker} from 'antd';
import {datePickerFullWidthStyle} from 'style/styleConstants';
import {Control, Controller} from 'react-hook-form';
import {DATE_FORMAT} from 'utils/dates/date.utils';
import {antdPickerMapper} from 'utils/dates/momentDates.utils';

export interface MyDatePickerProps {
    control: Control<any>;
    name: string;
    placeholder: string;
    allowClear?: boolean;
}

export const MyDatePicker:FC<MyDatePickerProps> = ({name,control,allowClear,placeholder}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => (
                <DatePicker
                    ref={field.ref}
                    allowClear={allowClear}
                    placeholder={placeholder}
                    value={antdPickerMapper(field.value,allowClear)}
                    onChange={(date) => {
                        field.onChange(date?.format(DATE_FORMAT));
                    }}
                    size={'large'}
                    format={DATE_FORMAT}
                    style={datePickerFullWidthStyle}

                />
            )}
        />
    );
};
