import {ProprietarioTableModel,Proprietario} from 'models/proprietario.model';
import {getNomeProprietario} from 'utils/names.utils';

export const proprietariTableModelMapper = (proprietari: Proprietario[]) : ProprietarioTableModel[] => {
    const dataList: ProprietarioTableModel[] = proprietari?.map(p => ({
        nome: getNomeProprietario(p),
        email: p.email,
        telefono: p.telefono,
        id: `${p.id}`
    }))
    return dataList;
}
