import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {ReportService} from 'service/home/reports.service';
import {
    DownloaderManagerProps,
    DownloadReportGeneraleParams, DownloadReportStrutturaParams,
    DownloadsService,
    ResourcesAvailable
} from 'service/downloads/downloads.service';


export const enum REPORTS_ACTION {
    SET_REPORTS_ALERT = 'reports/SET_REPORTS_ALERT',
    GET_REPORTS_LIST_STRUTTURA = 'reports/GET_REPORTS_LIST_STRUTTURA',
    GET_REPORTS_LIST_GENERAL = 'reports/GET_REPORTS_LIST_GENERAL',
    DOWNLOAD_REPORT_STRUTTURA = 'reports/DOWNLOAD_REPORT_STRUTTURA',
    DOWNLOAD_REPORT_GENERAL = 'reports/DOWNLOAD_REPORT_GENERAL',
}

const setReportsAlertAction = createAction(REPORTS_ACTION.SET_REPORTS_ALERT, (params:boolean) => {
    return { payload: params };
})

const getReportListStrutturaAction = createAsyncThunk<any,void>(REPORTS_ACTION.GET_REPORTS_LIST_STRUTTURA, async (params,thunkAPI) => {
    try {
        return await ReportService.getReportListStruttura();
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere la lista dei vari report',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const getReportListGeneralAction = createAsyncThunk<any,void>(REPORTS_ACTION.GET_REPORTS_LIST_GENERAL, async (params,thunkAPI) => {
    try {
        return await ReportService.getReportListGeneral();
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere la lista dei vari report',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const downloadReportGeneralAction = createAsyncThunk<boolean,DownloadReportGeneraleParams>(REPORTS_ACTION.DOWNLOAD_REPORT_GENERAL, async (params,thunkAPI) => {
    try {
        const downloadParams:DownloaderManagerProps = {
            resource: ResourcesAvailable.reportGenerale,
            payload: params
        }
        await DownloadsService.downloaderManager(downloadParams);
        return true;
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a scaricare il report',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const downloadReportStrutturaAction = createAsyncThunk<boolean,DownloadReportStrutturaParams>(REPORTS_ACTION.DOWNLOAD_REPORT_STRUTTURA, async (params,thunkAPI) => {
    try {
        const downloadParams:DownloaderManagerProps = {
            resource: ResourcesAvailable.reportStruttura,
            payload: params
        }
        await DownloadsService.downloaderManager(downloadParams);
        return true;
    } catch (e: any) {
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a scaricare il report',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});




export const ReportsActions = {
    setReportsAlertAction,
    getReportListStrutturaAction,
    getReportListGeneralAction,
    downloadReportGeneralAction,
    downloadReportStrutturaAction
}
