//Proprietari
import {FetchProprietariParamsAndBody, FetchProprietariResponse, PaginatedRequestParamsAndBody} from 'store/home/type';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {Proprietario, ProprietarioStruttura} from 'models/proprietario.model';
import {orderArrayByDate} from '../../utils/utils';
import {backendToFrontedDateFormatter} from 'utils/dates/date.utils';

const fetchProprietari = async (requestParams: FetchProprietariParamsAndBody): Promise<FetchProprietariResponse> => {
    console.log('Request [fetchProprietari] params:', requestParams);
    const params:PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size ,
        //FIXED BY DEFAULT
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }
    const response: AxiosResponse<FetchProprietariResponse> = await appAxios.post(`/api/cerbero/proprietario/getList`,{
        ...requestParams.entity
    },{
        params
    });

    console.log('Request [fetchProprietari] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchProprietari')
}

const fetchProprietariStruttura = async (idStruttura: string | number): Promise<ProprietarioStruttura[]> => {
    console.log('Request [fetchProprietariStruttura] params:', idStruttura);
    const response: AxiosResponse<ProprietarioStruttura[]> = await appAxios.get(`/api/cerbero/struttura/${idStruttura}/getPropietari`);

    console.log('Request [fetchProprietariStruttura] ', response.data);
    const dateOrderedProprietari = orderArrayByDate<ProprietarioStruttura>(response.data, 'dataRogito');

    if (response && response.data) {
        return dateOrderedProprietari
    }
    throw new Error('Error during fetchProprietariStruttura')
}

const createProprietario = async (proprietario: Proprietario): Promise<void> => {
    console.log('Request [createProprietario] params:', proprietario);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/proprietario`,proprietario);

    console.log('Request [createProprietario] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during createProprietario')
}

const editProprietario = async (proprietario: Proprietario): Promise<void> => {
    console.log('Request [editProprietario] params:', proprietario);
    const {id, ...proprietarioRest} = proprietario;
    const response: AxiosResponse = await appAxios.put(`/api/cerbero/proprietario/${id}`,proprietarioRest);

    console.log('Request [editProprietario] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editProprietario')
}

const fetchProprietario = async (id: string): Promise<Proprietario> => {
    console.log('Request [fetchProprietario] params:', id);
    const response: AxiosResponse<Proprietario> = await appAxios.get(`/api/cerbero/proprietario/${id}`);
    console.log('Request [fetchProprietario] ', response.data);
    const responseData = {
        ...response.data,
        dataScadenzaDocumento: backendToFrontedDateFormatter(response.data.dataScadenzaDocumento),
        dataDiNascita: backendToFrontedDateFormatter(response.data.dataDiNascita),
    }
    console.log('Request [fetchProprietario] Mapped Date ', responseData);

    if (response && response.data) {
        return responseData
    }
    throw new Error('Error during fetchProprietario')
}



export const ProprietariService = {
    fetchProprietari,
    createProprietario,
    editProprietario,
    fetchProprietariStruttura,
    fetchProprietario
};
