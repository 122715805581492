import moment from 'moment';
import {BACKEND_DATE_FORMAT, DATE_FORMAT, isValidDate, TIME_FORMAT} from 'utils/dates/date.utils';

export const datePickerMapper = (datePickerFieldValue: string | undefined | null): moment.Moment | undefined => {
    if (datePickerFieldValue !== null && datePickerFieldValue !== undefined) {
        if (isValidDate(datePickerFieldValue) && datePickerFieldValue.length > 0) {
            try {
                const finalDate = moment(datePickerFieldValue, BACKEND_DATE_FORMAT)
                return finalDate
            } catch (e) {
                return undefined
            }
        }
    }
    return undefined
}
export const timePickerMapper = (datePickerFieldValue: string | undefined | null): moment.Moment | undefined => {
    if (datePickerFieldValue !== null && datePickerFieldValue !== undefined) {
        if (datePickerFieldValue.length > 0) {
            try {
                const finalDate = moment(datePickerFieldValue, TIME_FORMAT)
                return finalDate
            } catch (e) {
                return undefined
            }
        }
    }
    return undefined
}

export const antdPickerMapper = (value:string|undefined, allowClear :boolean |undefined) =>{
    if (!value && !allowClear){
        return moment()
    }
    if (!value && allowClear){
        return undefined
    }
    return moment(value,DATE_FORMAT)
}
