import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {AuthActions} from 'store/auth/auth.action';
import {SUPPORTED_LANGUAGES} from 'i18n/i18n.config';
import {SettingsActions} from 'store/settings/settings.action';
import {useLocation} from 'react-router-dom';
import {Center, Spinner} from '@chakra-ui/react';


export const AuthProvider: FC = ({children}) => {

    const dispatch = useAppDispatch()
    const isAuth = useAppSelector(state => state.authReducer.isAuth);
    const initialized = useAppSelector(state => state.authReducer.initialized);
    // const {prenotazione} = useAppSelector(state => state.checkinReducer);
    const {serverLanguage} = useAppSelector(state => state.settingsReducer);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    //
    // useEffect(() => {
    //     const lingua = prenotazione?.extras.lingua;
    //     if(lingua && serverLanguage){
    //         dispatch(SettingsActions.setLanguageSetting({language:lingua as SUPPORTED_LANGUAGES,overrideServer:false}));
    //     }
    // }, [prenotazione?.extras.lingua]);


    useEffect(() => {
        handleAuth()
    }, [])


    const handleAuth = async () => {
        if (!isAuth && !initialized) {
            await dispatch(AuthActions.authenticateWithToken())
        }
        setIsLoading(false);
    }


    return (
        <>
            {isLoading || !initialized ?
                <Center h={'100vh'} w={'full'}>
                    <Spinner/>
                </Center>
                : children}
        </>
    );
};

