
export interface Varco{
    id?: number;
    nome?: string;
    nomeEng?: string;
    tipoAutomazione?: number,
    tipologia?: number,
    gsmPresente?: boolean,
    urlProcedura?: string,
    codiceFisso?: string,
    videoYT?: string,
    richiestaCitofono?: boolean,
    nomeCitofono?: string,
    topic?: string,
    payload?: string,
    videoBackup?: string,
}
export enum TipoAutomazione {
    'MQTT'= 6
}
