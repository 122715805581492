import React, {FC} from 'react';
import {HStack, PinInput, PinInputField} from '@chakra-ui/react';

interface SecretPinProps {
    secretPin: string;
}

export const SecretPin: FC<SecretPinProps> = ({secretPin}) => {
    return (
        <HStack w={'full'} justifyContent={'center'}>
            <PinInput value={secretPin} type={'alphanumeric'} isDisabled={true}>
                {
                    secretPin.split('').map((letter,index) => {
                        return <PinInputField textColor={'#000'}  color={'#000'} _disabled={{color:"#000",borderColor:"borderColor"}} key={`${letter}${index}`}/>
                    })
                }
            </PinInput>
        </HStack>
    );
};
