import React from 'react';
import 'shared/components/default/myButton/myButton.css'
import {Button, ButtonProps} from '@chakra-ui/react';

interface MyButtonProps extends ButtonProps {
    myType: 'PRIMARY' | 'OUTLINE'
}

const MyButton:React.FC<MyButtonProps> = (props ) => {
    const {myType, ...rest} = props

    const chooseType = () => {
        switch (props.myType) {
            case 'PRIMARY': return {
                w:'full',
                colorScheme:'primary',
                color:'lightButtonColor'
            }
            case 'OUTLINE': return {
                w:'full',
                border:'solid 1px',
                borderColor:'borderColor',
                fontSize:'sm',
                color:'darkButtonColor',
                backgroundColor:'background'
            }
        }

    }

    return (
    <Button {...chooseType()}{...rest} >{props.children}</Button>
    )
};

export default MyButton
