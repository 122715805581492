import * as yup from 'yup';
import moment from 'moment';
import {Proprietario} from 'models/proprietario.model';
import {backendDateFormatter, DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT} from 'utils/dates/date.utils';
import {Accesso} from "../../../../store/gestioneAccessi/types";

export const AccessoValidationSchema = yup.object().shape({
    codice: yup.number().required('Il codice è obbligatorio').max(999999999),
    fineValidita: yup
        .mixed()
        .required('La data di fine validità è obbligatoria'),

    descrizione: yup.string(),
    struttureIds:   yup.array().of(yup.number()).required('Selezionare almeno una struttura').min(1)
});

export const AccessoFormInitialFrom: Accesso = {
    codice: 0,
    fineValidita: moment().format(DATE_TIME_FORMAT),
    descrizione: '',
    struttureIds: [],
};

