import React, {FC} from 'react';
import {Box, BoxProps, Center, Spinner, Text, useDisclosure, VStack} from '@chakra-ui/react';
import {MobileNav} from 'shared/components/custom/sidebar/mobileNav.component';
import {ReactComponent as GenericError} from 'assets/general/generic_error.svg';
import {useTranslation} from 'react-i18next';

interface SimplePageProps {
    title: string;
    hasBack?: boolean;
    customBackRoute?: string;
    isLoading?: boolean;
    isError?: boolean;
    mainContainerProps?: BoxProps;
}

export const SimplePage: FC<SimplePageProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {t} = useTranslation()

    const renderContent = () => {
        if (props.isLoading){
            return (
                <Center h={'100vh'} w={'full'}>
                    <Spinner/>
                </Center>
            )
        }

        if (props.isError){
            return (
                <Center h={'80vh'} backgroundColor={'background'} overflowY={'hidden'}>
                    <VStack>
                        <GenericError width={'300px'} height={'300px'}/>
                        <Text color={'titleColor'} fontSize={'large'}>{t('common:ERRORS:500:DESCRIPTION')}</Text>
                    </VStack>
                </Center>
            )
        }

        return props.children;


    }

    return (
        <Box w={'full'} h={'full'} {...props.mainContainerProps}>
            <MobileNav hasBack={props.hasBack} customBackRoute={props.customBackRoute} onOpen={onOpen} title={props.title}  mb={6}/>
            {renderContent()}
        </Box>
    )
};
