import React, {FC, useEffect, useState} from 'react';
import {Box, HStack, Text, Th, Tooltip} from '@chakra-ui/react';
import {IoCaretDownOutline, IoCaretUpOutline } from 'react-icons/io5';

export interface MyColumnHeaderProps {
    filteringKey?: string;
    customHeaderProps: any;
    columnName: string;
    isLoading: boolean;
    sortField?: string;
    sortDirection?: string;
    pageIndex: number;
    defaultSortDirection?: SortingDirection;
    onClick: (direction: string | undefined, sortingField: string | undefined, resetPageIndex?: boolean) => void;
}

export enum SortingDirection {
    ASC = 'ASC',
    DESC = 'DESC',
    NONE = ''
}

export const MyColumnHeader: FC<MyColumnHeaderProps> = ({
                                                            filteringKey,
                                                            customHeaderProps,
                                                            columnName,
                                                            onClick,
                                                            defaultSortDirection,
                                                            isLoading,
                                                            sortField,
                                                            sortDirection
                                                        }) => {
    const [direction, setDirection] = useState<string>(defaultSortDirection?defaultSortDirection:SortingDirection.NONE);

    useEffect(() => {
        console.log('HEADER sortDirection init:', direction);
        if (sortDirection) {
            setDirection(sortDirection);
        }
    }, []);


    const changeDirection = () => {
        if (sortField !== filteringKey) {
            return SortingDirection.DESC;
        }
        if (direction == SortingDirection.NONE) {
            return SortingDirection.DESC;
        }
        if (direction == SortingDirection.DESC) {
            return SortingDirection.ASC;
        }
        return SortingDirection.NONE;
    }

    const getIcon = () => {
        if (filteringKey && sortField == filteringKey && !isLoading) {
            if (SortingDirection.ASC == direction) {
                return (
                    <Tooltip label="Ordinamento crescente">
                      <span>
                        <IoCaretUpOutline size={15}/>
                      </span>
                    </Tooltip>
                )
            }
            if (SortingDirection.DESC == direction) {
                return (
                    <Tooltip label="Ordinamento decrescente">
                      <span>
                        <IoCaretDownOutline size={15}/>
                      </span>
                    </Tooltip>
                )
            }
        }
    }

    const handleOnClick = () => {
        if (filteringKey && !isLoading) {
            const newDirection = changeDirection();
            setDirection(newDirection);
            console.log('HEADER newDirection:', newDirection);
            onClick(newDirection?newDirection:undefined, filteringKey, sortField != filteringKey);
        }
    }

    return (
        <Th {...customHeaderProps} style={{maxWidth: '100%', whiteSpace: 'nowrap', wordWrap: 'break-word'}}
            onClick={handleOnClick}>
            <HStack>
                <Text size={'sm'} color={isLoading ? 'borderColor' : 'titleColor'}>
                    {columnName}
                </Text>
                <Box w={15} h={15}>
                    {getIcon()}
                </Box>
            </HStack>
        </Th>
    );
};
