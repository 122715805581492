import React, {createContext, FC, useContext, useState} from 'react';

export type HomeContextContent = {
    title: string
    setTitle:(t: string) => void
    hasBack: boolean
    setHasBack:(h: boolean) => void
}

const HomeContext = createContext<HomeContextContent>({
    title: "",
    setTitle: () => {},
    hasBack: false,
    setHasBack: () => {}
});

export const useHome = () => useContext(HomeContext);

export const HomeProvider:FC = ({ children }) => {
    const [title, setTitle] = useState<string>("");
    const [hasBack, setHasBack] = useState<boolean>(false);
    return (
        <HomeContext.Provider value={{ title, setTitle,setHasBack,hasBack }}>
            {children}
        </HomeContext.Provider>
    );
};
