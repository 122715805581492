import {ChangeResetPasswordParams, LoginAuthenticateResponse, LoginParams, LoginResponseDto} from 'store/auth/types';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {UtenteRoleNames} from 'models/utente.model';

const loginMethod = async (params: LoginParams):Promise<LoginAuthenticateResponse> => {
    console.log('Request [loginRequest] params:', params);
    const response: AxiosResponse<LoginResponseDto> = await appAxios.post(`/api/authenticate`,{},{
        params:{username:params.username, password:params.password}
    });
    console.log('Request [loginRequest] ', response.data);
    if (response && response.data && response.data.token) {
        AuthService.setAccessToken(response.data.token)
        AuthService.setRefreshToken(response.data.refreshToken)
        let userRole:UtenteRoleNames = 'ROLE_LION'

        if (response.data.role && response.data.role.filter(e => e.role === 'ROLE_ADMIN').length > 0 ) {
            userRole = 'ROLE_ADMIN'
            console.log('Request [loginRequest] admin role')
        }

        return {
            isAuth: !!response.data.token,
            token:response.data.token,
            username: response.data.username,
            id: response.data.id,
            role: userRole,
        }
    }
    throw new Error('Error during login')
}



const sendResetPassword = async (username: string): Promise<void> => {
    console.log('Request [sendResetPassword] params:' + username);
    const response: AxiosResponse = await appAxios.post(`/api/auth/recupero/recuperaPassword`,{},{
        params:{username:username}
    });

    console.log('Request [sendResetPassword] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendResetPassword')
}

const changeResetPassword = async (params:ChangeResetPasswordParams): Promise<void> => {
    console.log('Request [changeResetPassword] params:' + params);
    const response: AxiosResponse = await appAxios.post(`/api/auth/recupero/setRecuperaPassword`,{},{
        params:{
            token:params.token,
            newPassword:params.password
        }
    });
    console.log('Request [changeResetPassword] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during changeResetPassword')
}



//LOCAL
const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}

const setAccessToken = (accessToken: string) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
    }
}

const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

const setRefreshToken = (token: string) => {
    if (token) {
        localStorage.setItem('refreshToken', token);
    }
}

const setUser = (id: number,username:string,role:UtenteRoleNames) => {
    localStorage.setItem('username', username);
    localStorage.setItem('id', `${id}`);
    localStorage.setItem('role', role);
}

const getUser = ():{username?:string,id?:number,role:UtenteRoleNames} => {
    const username = localStorage.getItem('username');
    const id = localStorage.getItem('id');
    const role = localStorage.getItem('role');

    return {
        username: username ?username:undefined,
        id: id ? Number(id) : undefined,
        role: role ? role as UtenteRoleNames :'ROLE_LION',
    }
}



const resetAccessToken = () => {
    localStorage.clear()
}

export const AuthService = {
    loginMethod,
    getAccessToken,
    setAccessToken,
    resetAccessToken,
    setUser,
    getUser,
    sendResetPassword,
    changeResetPassword,
    getRefreshToken,
    setRefreshToken
}
