import React, {FC, useCallback, useEffect, useState} from 'react';
import {Box, HStack, IconButton, Input, InputGroup, InputLeftElement, Select} from '@chakra-ui/react';
import {DatePicker} from 'antd';
import {datePickerStyle} from 'style/styleConstants';
import {IoCloseCircleSharp, IoSearch} from 'react-icons/io5';
import moment from 'moment';
import {BACKEND_DATE_FORMAT, DATE_FORMAT} from 'utils/dates/date.utils';
import {MyPaginatedColumn} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {OrderingFilters, PaginatedManutenzioneFilters, PaginatedTableFilters} from 'store/home/type';
import {COLORS} from 'style/chakraTheme';
import _debounce from 'lodash/debounce';
import {Onere, StatoManutenzione, StatoOta} from "../../../../../store/gestioneManutenzioni/types";
import {useTranslation} from "react-i18next";


interface ManutenzioniManutenzioniSearchTableHeaderProps {
    costoPlaceholder?: string;
    columns: MyPaginatedColumn[];
    filters: PaginatedManutenzioneFilters,
    orderingFilters?: OrderingFilters,
    extraItems?: React.ReactNode;
    customOnReset?: () => void;
    submitData: (pageNumber: number, pageSize: number, entity?: PaginatedManutenzioneFilters, sortDirection?: string, sortField?: string) => void;
    pageSize: number
}

enum FilterSelect {
    STRUTTURA = 'STRUTTURA',
    RIFERIMENTO_OSPITE = 'RIFERIMENTO_OSPITE',
}

const FilterInput = (params: { value: string, placeholder: string, onChange: (value: string) => void }) => {
    return <Box w={320}>
        <InputGroup>
            <InputLeftElement
                _hover={{cursor: 'pointer'}}
                children={<IoSearch color={'iconColor'}/>}
            />

            <Input maxW={320} value={params.value} onChange={s => {
                params.onChange(s.target.value)
            }} backgroundColor={'background'} placeholder={params.placeholder}/>

        </InputGroup>
    </Box>
}

export const ManutenzioniManutenzioniSearchTableHeader: FC<ManutenzioniManutenzioniSearchTableHeaderProps> = (props) => {
    const {t} = useTranslation()

    const {filters, submitData, orderingFilters, pageSize} = props;

    const [filterSelect, setFilterSelect] = useState<FilterSelect>(FilterSelect.STRUTTURA);

    const [data, setData] = useState<string>('');
    const [stato, setStato] = useState<StatoManutenzione>()
    const [costo, setCosto] = useState<number>()
    const [riferimentoOspite, setRiferimentoOspite] = useState<string>('')
    const [statoOta, setStatoOta] = useState<StatoOta>()
    const [dataRimborsoPortale, setDataRimborsoPortale] = useState<string>('')
    const [onere, setOnere] = useState<Onere>()
    const [nomeStruttura, setNomeStruttura] = useState<string>('');

    useEffect(() => {
        /*if (filters.searchText){
            console.log('Filters.searchText',filters.searchText);
            console.log('ManutenzioniSearchTableHeader useEffect',filters.searchText);
            setSearchBar(filters.searchText);
        }*/
        console.log('ManutenzioniSearchTableHeader useEffect filters: ', filterSelect);
    }, [filterSelect]);

    useEffect(() => {
        handleSubmitData(pageSize, filters, orderingFilters?.sortDirection, orderingFilters?.sortField);
    }, [data, stato, costo, riferimentoOspite, statoOta, dataRimborsoPortale, onere, nomeStruttura]);

    const resetFilters = () => {
        setData('');
        setStato(undefined);
        setCosto(undefined);
        setRiferimentoOspite('');
        setStatoOta(undefined);
        setDataRimborsoPortale('');
        setOnere(undefined);
        setNomeStruttura('');

        if (props.customOnReset) {
            props.customOnReset();
        }
    }


    const handleSubmitData = async (persistedPageSize: number, persistedFilters: PaginatedManutenzioneFilters, persistedSortDirection?: string, persistedSortField?: string) => {
        console.log('ManutenzioniSearchTableHeader handleSubmitData persisted filters: ', persistedFilters);
        let filtersData: PaginatedManutenzioneFilters = {
            data,
            costo,
            dataRimborsoPortale,
            onere,
            stato,
            statoOta
        }

        if (filterSelect === FilterSelect.STRUTTURA) {
            filtersData.nomeStruttura = nomeStruttura;
        } else if (filterSelect === FilterSelect.RIFERIMENTO_OSPITE) {
            filtersData.riferimentoOspite = riferimentoOspite;
        }

        console.log("PAGE SIZE", persistedPageSize)
        console.log("FILTERS DATA", filtersData)
        await submitData(0, persistedPageSize, filtersData, persistedSortDirection, persistedSortField);
    }

    const debounceInputSearch = useCallback(_debounce(handleSubmitData, 400), []);

    const formatDate = (dateValue: string) => {
        console.log('ManutenzioniSearchTableHeader formatDate dateValue: ', dateValue, moment(new Date(), BACKEND_DATE_FORMAT));
        return moment(new Date(dateValue), BACKEND_DATE_FORMAT)
    }

    return (
        <HStack my={1} w={'full'} mb={2} justify={'end'} zIndex={0}>
            {props.extraItems}

            <IconButton aria-label="Clear filters" onClick={resetFilters} color={COLORS.ICON}
                        icon={<IoCloseCircleSharp size={22}/>}/>

            <Select width={'auto'} backgroundColor={'background'} value={filterSelect} onChange={(e) => {
                const newFilterSelect: FilterSelect = e.target.value as FilterSelect;
                console.log('ManutenzioniSearchTableHeader newFilterSelect: ', newFilterSelect, e.target.value)
                setFilterSelect(newFilterSelect);
            }}>
                <>
                    {Object.keys(FilterSelect).map((filterSelect, index) => {
                        return <option key={index} value={filterSelect}>{t(`homePage:TABLES:MANUTENZIONE:SELECT:${filterSelect}`)}</option>
                    })}
                </>
            </Select>

            {
                filterSelect === FilterSelect.STRUTTURA &&
                <FilterInput value={nomeStruttura} placeholder={t('homePage:TABLES:MANUTENZIONE:STRUTTURA')}
                             onChange={setNomeStruttura}/>
            }

            {
                filterSelect === FilterSelect.RIFERIMENTO_OSPITE &&
                <FilterInput value={riferimentoOspite}
                             placeholder={t('homePage:TABLES:MANUTENZIONE:RIFERIMENTO_OSPITE')}
                             onChange={setRiferimentoOspite}/>
            }

            <DatePicker
                format={DATE_FORMAT}
                onChange={(date) => {
                    if (date) {
                        setData(moment(date).format(BACKEND_DATE_FORMAT));
                    } else {
                        setData('');
                    }
                }}
                size={'large'}
                placeholder={'Data'}
                style={datePickerStyle}
            />

            <Box w={150}>
                <InputGroup>
                    <InputLeftElement
                        _hover={{cursor: 'pointer'}}
                        children={<IoSearch color={'iconColor'}/>}
                    />

                    <Input maxW={320} value={costo} type={'number'} onChange={s => {
                        if (s.target.value === '') {
                            setCosto(undefined)
                        } else {
                            setCosto(Number(s.target.value))
                        }
                    }} backgroundColor={'background'} placeholder={props.costoPlaceholder}/>

                </InputGroup>
            </Box>
            <Select width={150} backgroundColor={'background'} value={stato|| ''} onChange={(e) => {
                if (e.target.value === t('homePage:TABLES:MANUTENZIONE:STATO:NAME')) {
                    setStato(undefined)
                } else {
                    const statoValue: StatoManutenzione = e.target.value as StatoManutenzione;
                    setStato(statoValue)
                }
            }}>
                <>
                    <option value={undefined}>{t('homePage:TABLES:MANUTENZIONE:STATO:NAME')}</option>
                    {Object.keys(StatoManutenzione).map((c, index) => {
                        return <option key={index}
                                       value={c}>{t(`homePage:TABLES:MANUTENZIONE:STATO:${c}`)}</option>
                    })}
                </>
            </Select>
            <Select width={'auto'} backgroundColor={'background'} value={statoOta || ''} onChange={(e) => {
                if (e.target.value === t('homePage:TABLES:MANUTENZIONE:STATO_OTA:NAME')) {
                    setStatoOta(undefined)
                } else {
                    const statoValue: StatoOta = e.target.value as StatoOta;
                    setStatoOta(statoValue)
                }
            }}>
                <>
                    <option value={undefined}>{t('homePage:TABLES:MANUTENZIONE:STATO_OTA:NAME')}</option>
                    {Object.keys(StatoOta).map((c, index) => {
                        return <option key={index}
                                       value={c}>{t(`homePage:TABLES:MANUTENZIONE:STATO_OTA:${c}`)}</option>
                    })}

                </>
            </Select>

            <DatePicker
                format={DATE_FORMAT}
                onChange={(date) => {
                    if (date) {
                        setDataRimborsoPortale(moment(date).format(BACKEND_DATE_FORMAT));
                    } else {
                        setDataRimborsoPortale('');
                    }
                }}
                size={'large'}
                placeholder={'Rimborso'}
                style={datePickerStyle}
            />

            <Select width={150} backgroundColor={'background'} value={onere|| ''} onChange={(e) => {
                if (e.target.value === t('homePage:TABLES:MANUTENZIONE:ONERE:NAME')) {
                    setOnere(undefined)
                } else {
                    const onereValue: Onere = e.target.value as Onere;
                    setOnere(onereValue)
                }
            }}>
                <>
                    <option value={undefined}>{t('homePage:TABLES:MANUTENZIONE:ONERE:NAME')}</option>
                    {Object.keys(Onere).map((c, index) => {
                        return <option key={index}
                                       value={c}>{t(`homePage:TABLES:MANUTENZIONE:ONERE:${c}`)}</option>
                    })}
                </>
            </Select>
        </HStack>
    );
};
