import {Navigate, RouteProps} from 'react-router-dom';
import {PrenotazioniPage} from 'screens/home/prenotazioni/prenotazioni.page';
import {StrutturePage} from 'screens/home/strutture/strutture.page';
import {ProprietariPage} from 'screens/home/proprietari/proprietari.page';
import React from 'react';
import {LinkItemProps} from 'shared/components/custom/sidebar/types';
import {FiCompass, FiHome, FiKey, FiPieChart, FiStar, FiTrendingUp, FiUsers} from 'react-icons/fi';
import NotFoundPage from 'screens/notFound/notFound.page';
import {ReportsActions} from 'store/reports/reports.action';
import {store} from 'store/store.config';
import {AdminRoute} from 'navigation/customRouting/adminRoute';
import {PrivateRoute} from 'navigation/customRouting/privateRoute';
import {GestioneStrutturePage} from 'screens/gestioneStrutture/gestioneStrutture.page';
import {GestioneAccessiPage} from "../../screens/home/gestioneAccessi/gestioneAccessi.page";
import AccessoCreationEditPage from "../../screens/home/gestioneAccessi/accesso.creation.edit.page";
import ManutenzioniCreationEditPage from "../../screens/home/manutenzioni/manutenzioni.creation.edit.page";
import {GestioneManutenzioniPage} from "../../screens/home/manutenzioni/gestioneManutenzioni.page";

const PrenotazioniDetailPage = React.lazy(() => import('screens/home/prenotazioni/prenotazioni.detail.page'));
const StruttureDetailPage = React.lazy(() => import('screens/home/strutture/strutture.detail.page'));
const ProprietariDetailPage = React.lazy(() => import('screens/home/proprietari/proprietari.detail.page'));
const StruttureCreationPage = React.lazy(() => import('screens/home/strutture/strutture.creation.page'));
const ProprietariCreationEditPage = React.lazy(() => import('screens/home/proprietari/proprietari.creation.edit.page'));
const UtentiCreationPage = React.lazy(() => import('screens/home/utenti/utenti.creation.page'));
const UtentiPage = React.lazy(() => import('screens/home/utenti/utenti.page'));

type HomeRoutes =
    'PRENOTAZIONI'
    | 'APPARTAMENTI'
    | 'APPARTAMENTI_DETAIL'
    | 'APPARTAMENTI_EDIT'
    | 'APPARTAMENTI_CREATION'
    |
    'PROPRIETARI'
    | 'INDEX'
    | 'NOT_FOUND'
    | 'PRENOTAZIONI_DETAIL'
    | 'PROPRIETARI_DETAIL'
    | 'PROPRIETARI_EDIT'
    | 'PROPRIETARI_CREATION'
    |
    'UTENTI'
    | 'UTENTI_CREATION'
    | 'GESTIONE_ACCESSI'
    | 'GESTIONE_ACCESSI_CREATION'
    | 'GESTIONE_ACCESSI_EDIT'
    | 'GESTIONE_MANUTENZIONI'
    | 'MANUTENZIONE'
    | 'MANUTENZIONE_EDIT'
    ;

export const HomePaths: Record<HomeRoutes, String> = {
    PRENOTAZIONI: 'prenotazioni',
    PRENOTAZIONI_DETAIL: 'prenotazioni/:id',
    APPARTAMENTI: 'appartamenti',
    APPARTAMENTI_DETAIL: 'appartamenti/:id',
    APPARTAMENTI_EDIT: 'appartamenti/:id/edit',
    APPARTAMENTI_CREATION: 'appartamenti/:id/creation',
    PROPRIETARI: 'proprietari',
    PROPRIETARI_DETAIL: 'proprietari/:id',
    PROPRIETARI_EDIT: 'proprietari/:id/edit',
    PROPRIETARI_CREATION: 'proprietariCreation',
    UTENTI: 'utenti',
    UTENTI_CREATION: 'utentiCreation',
    GESTIONE_ACCESSI: 'accessi',
    GESTIONE_ACCESSI_CREATION: 'accessiCreation',
    GESTIONE_ACCESSI_EDIT: 'accessi/:id',
    GESTIONE_MANUTENZIONI: 'manutenzioni',
    MANUTENZIONE: 'appartamenti/:idAppartamento/manutenzione',
    MANUTENZIONE_EDIT: 'manutenzioni/:idManutenzione',
    NOT_FOUND: '*',
    INDEX: ''
}

export const HomeRoutes: Record<HomeRoutes, RouteProps> = {
    INDEX: {
        index: true,
        element: <Navigate to={HomePaths.PRENOTAZIONI.toString()}/>
    },
    PRENOTAZIONI: {
        path: HomePaths.PRENOTAZIONI.toString(),
        element: <PrenotazioniPage/>
    },
    PRENOTAZIONI_DETAIL: {
        path: HomePaths.PRENOTAZIONI_DETAIL.toString(),
        element: <PrenotazioniDetailPage/>,
    },

    APPARTAMENTI: {
        path: HomePaths.APPARTAMENTI.toString(),
        element: <StrutturePage/>,
    },
    APPARTAMENTI_DETAIL: {
        path: HomePaths.APPARTAMENTI_DETAIL.toString(),
        element: <StruttureDetailPage/>,
    },
    APPARTAMENTI_EDIT: {
        path: HomePaths.APPARTAMENTI_EDIT.toString(),
        element: <StruttureCreationPage isEdit={true}/>,
    },
    APPARTAMENTI_CREATION: {
        path: HomePaths.APPARTAMENTI_CREATION.toString(),
        element: <StruttureCreationPage/>,
    },

    PROPRIETARI: {
        path: HomePaths.PROPRIETARI.toString(),
        element: <ProprietariPage/>,
    },
    PROPRIETARI_DETAIL: {
        path: HomePaths.PROPRIETARI_DETAIL.toString(),
        element: <ProprietariDetailPage/>,
    },
    PROPRIETARI_EDIT: {
        path: HomePaths.PROPRIETARI_EDIT.toString(),
        element: <ProprietariCreationEditPage/>,
    },
    PROPRIETARI_CREATION: {
        path: HomePaths.PROPRIETARI_CREATION.toString(),
        element: <ProprietariCreationEditPage/>,
    },
    UTENTI: {
        path: HomePaths.UTENTI.toString(),
        element: <AdminRoute component={UtentiPage}/>,
    },
    UTENTI_CREATION: {
        path: HomePaths.UTENTI_CREATION.toString(),
        element: <AdminRoute component={UtentiCreationPage}/>,
    },

    GESTIONE_ACCESSI: {
        path: HomePaths.GESTIONE_ACCESSI.toString(),
        element: <PrivateRoute component={GestioneAccessiPage}/>
    },
    GESTIONE_ACCESSI_CREATION: {
        path: HomePaths.GESTIONE_ACCESSI_CREATION.toString(),
        element: <PrivateRoute component={AccessoCreationEditPage}/>
    },
    GESTIONE_MANUTENZIONI: {
        path: HomePaths.GESTIONE_MANUTENZIONI.toString(),
        element: <PrivateRoute component={GestioneManutenzioniPage}/>
    },

    GESTIONE_ACCESSI_EDIT:
        {
            path: HomePaths.GESTIONE_ACCESSI_EDIT.toString(),
            element: <PrivateRoute component={AccessoCreationEditPage}/>
        },

    MANUTENZIONE:{
        path: HomePaths.MANUTENZIONE.toString(),
        element: <PrivateRoute component={ManutenzioniCreationEditPage}/>
    },
    MANUTENZIONE_EDIT:{
        path: HomePaths.MANUTENZIONE_EDIT.toString(),
        element: <PrivateRoute component={ManutenzioniCreationEditPage}/>
    },
    NOT_FOUND: {
        path: HomePaths.NOT_FOUND.toString(),
        element: <NotFoundPage/>,
    }
}

export const LinkItems: Array<LinkItemProps> = [
    {name: 'Prenotazioni', icon: FiHome, route: HomePaths.PRENOTAZIONI.toString()},
    {name: 'Appartamenti', icon: FiTrendingUp, route: HomePaths.APPARTAMENTI.toString()},
    {name: 'Proprietari', icon: FiCompass, route: HomePaths.PROPRIETARI.toString()},
    {name: 'Utenti', icon: FiUsers, route: HomePaths.UTENTI.toString(), isAdmin: true},
    {
        name: 'Estrai Report',
        icon: FiStar,
        route: '',
        onClick: () => store.dispatch(ReportsActions.setReportsAlertAction(true))
    },
    {name: 'Gestione Accessi', icon: FiKey, route: HomePaths.GESTIONE_ACCESSI.toString()},
    {name: 'Gestione Manutenzioni', icon: FiPieChart, route: HomePaths.GESTIONE_MANUTENZIONI.toString()},

];


export interface PrenotazioniDetailPageParams {
    id?: string
}
