import {createReducer} from '@reduxjs/toolkit';
import {HomeState} from 'store/home/type';
import {HomeAction} from 'store/home/home.action';
import {prenotazioneTableModelMapper} from 'service/mappers/prenotazioneTableModel.mapper';
import {PrenotazioniTableAction} from 'store/home/paginatedActions/prenotazioniTable.action';
import {AppartamentiTableAction} from 'store/home/paginatedActions/appartamentiTable.action';
import {ProprietariTableAction} from 'store/home/paginatedActions/proprietariTable.action';
import {proprietariTableModelMapper} from 'service/mappers/proprietariTableModel.mapper';
import {struttureTableModelMapper} from 'service/mappers/strutturaTableModel.mapper';
import { UtentiTableAction } from './paginatedActions/utentiTable.action';
import {UtenteTableModelMapper} from 'service/mappers/utenteTableModel.mapper';
import {StrutturaInfoTableAction} from 'store/home/paginatedActions/strutturaInfoTable.action';
import {SortingDirection} from 'shared/components/custom/paginatedTable/components/myColumnHeader.component';
import {GestioneAccessiTableAction} from "./paginatedActions/gestioneAccessiTable.action";
import {GestioneManutenzioniTableAction} from "./paginatedActions/gestioneManutenzioniTable.action";

const initialState: HomeState = {
    isError: false,
    isLoading: false,
    prenotazioniPaginated:{
        data: [],
        dataTable: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        sortField: 'arrivo',
        sortDirection: SortingDirection.DESC,
        filters: {}
    },
    proprietariPaginated:{
        data: [],
        dataTable: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    },
    strutturePaginated:{
        data: [],
        dataTable: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    },
    utentiPaginated:{
        data: [],
        dataTable: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    },
    strutturaInfoPaginated:{
        dataTable: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    },
    accessiPaginated:{
        data: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    },
    manutenzioniPaginated:{
        data: [],
        totalElements: 0,
        totalPages: 0,
        pageSize:10,
        pageIndex:0,
        filters: {}
    }


};

export const homeReducer = createReducer(initialState, (builder) => {
    // Prenotazioni Detail Error
    builder.addCase(HomeAction.fetchPrenotazioneAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    //LIST PRENOTAZIONI
    builder.addCase(HomeAction.fetchPrenotazioniAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchPrenotazioniAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchPrenotazioniAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;
        const dataTable = prenotazioneTableModelMapper(content)

        return {
            ...state,
            isLoading: false,
            isError: false,
            prenotazioniPaginated: {
                ...state.prenotazioniPaginated,
                data: content,
                dataTable,
                totalElements,
                totalPages
            }
        }

    });

    //LIST STRUTTURA INFO
    builder.addCase(HomeAction.fetchStrutturaInfoAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchStrutturaInfoAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchStrutturaInfoAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;

        return {
            ...state,
            isLoading: false,
            isError: false,
            strutturaInfoPaginated: {
                ...state.strutturaInfoPaginated,
                dataTable:content,
                totalElements,
                totalPages
            }
        }

    });

    //LIST PROPRIETARI
    builder.addCase(HomeAction.fetchProprietariAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchProprietariAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchProprietariAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;
        const dataTable = proprietariTableModelMapper(content)

        return {
            ...state,
            isLoading: false,
            isError: false,
            proprietariPaginated: {
                ...state.proprietariPaginated,
                data: content,
                dataTable,
                totalElements,
                totalPages
            }
        }

    });

    //LIST STRUTTURE
    builder.addCase(HomeAction.fetchStruttureAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchStruttureAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchStruttureAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;
        const dataTable = struttureTableModelMapper(content)

        return {
            ...state,
            isLoading: false,
            isError: false,
            strutturePaginated: {
                ...state.strutturePaginated,
                data: content,
                dataTable,
                totalElements,
                totalPages
            }
        }

    });

    //LIST UTENTI
    builder.addCase(HomeAction.fetchUtentiAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchUtentiAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchUtentiAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;
        const dataTable = UtenteTableModelMapper(content)

        return {
            ...state,
            isLoading: false,
            isError: false,
            utentiPaginated: {
                ...state.utentiPaginated,
                data: content,
                dataTable,
                totalElements,
                totalPages
            }
        }

    });

    //Prenotazioni
    builder.addCase(PrenotazioniTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            prenotazioniPaginated: {
                ...state.prenotazioniPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(PrenotazioniTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            prenotazioniPaginated: {
                ...state.prenotazioniPaginated,
                pageSize: action.payload
            }
        }
    });

    builder.addCase(PrenotazioniTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            prenotazioniPaginated:{
                ...state.prenotazioniPaginated,
                filters:{
                    ...state.prenotazioniPaginated.filters,
                    ...action.payload
                }
            }
        }
    });

    builder.addCase(PrenotazioniTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            prenotazioniPaginated:{
                ...state.prenotazioniPaginated,
                ...action.payload
            }
        }
    });


    //Appartamenti
    builder.addCase(AppartamentiTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            strutturePaginated: {
                ...state.strutturePaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(AppartamentiTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            strutturePaginated: {
                ...state.strutturePaginated,
                pageSize: action.payload
            }
        }
    });

    builder.addCase(AppartamentiTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            strutturePaginated:{
                ...state.strutturePaginated,
                filters:{
                    ...state.strutturePaginated.filters,
                    ...action.payload
                }
            }
        }
    });

    builder.addCase(AppartamentiTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            strutturePaginated:{
                ...state.strutturePaginated,
                ...action.payload
            }
        }
    });


    //Proprietari
    builder.addCase(ProprietariTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            proprietariPaginated: {
                ...state.proprietariPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(ProprietariTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            proprietariPaginated: {
                ...state.proprietariPaginated,
                pageSize: action.payload
            }
        }
    });

    builder.addCase(ProprietariTableAction.proprietariTableResetAction, (state, action) => {
        return {
            ...state,
            proprietariPaginated: {
                data: [],
                dataTable: [],
                searchTableData:{
                },
                totalElements: 0,
                totalPages: 0,
                pageSize:10,
                pageIndex:0,
                filters:{}
            }
        }
    });

    builder.addCase(ProprietariTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            proprietariPaginated:{
                ...state.proprietariPaginated,
                filters:{
                    ...state.proprietariPaginated.filters,
                    ...action.payload
                }
            }
        }
    })

    builder.addCase(ProprietariTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            proprietariPaginated:{
                ...state.proprietariPaginated,
                ...action.payload
            }
        }
    });

    //Utenti
    builder.addCase(UtentiTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            utentiPaginated: {
                ...state.utentiPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(UtentiTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            utentiPaginated: {
                ...state.utentiPaginated,
                pageSize: action.payload
            }
        }
    });

    builder.addCase(UtentiTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            utentiPaginated:{
                ...state.utentiPaginated,
                filters:{
                    ...state.proprietariPaginated.filters,
                    ...action.payload
                }
            }
        }
    });

    builder.addCase(UtentiTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            utentiPaginated:{
                ...state.utentiPaginated,
                ...action.payload
            }
        }
    });

    //Struttura info
    builder.addCase(StrutturaInfoTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            strutturaInfoPaginated: {
                ...state.strutturaInfoPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(StrutturaInfoTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            strutturaInfoPaginated: {
                ...state.strutturaInfoPaginated,
                pageSize: action.payload
            }
        }
    });

    //LIST ACCESSI
    builder.addCase(HomeAction.fetchAccessiAction .pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchAccessiAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchAccessiAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;

        return {
            ...state,
            isLoading: false,
            isError: false,
            accessiPaginated: {
                ...state.accessiPaginated,
                data: content,
                totalElements,
                totalPages
            }
        }
    });

    //LIST MANUTENZIONI
    builder.addCase(HomeAction.fetchManutenzioniAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(HomeAction.fetchManutenzioniAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(HomeAction.fetchManutenzioniAction.fulfilled, (state, action) => {
        const {content, totalElements, totalPages} = action.payload;

        return {
            ...state,
            isLoading: false,
            isError: false,
            manutenzioniPaginated: {
                ...state.manutenzioniPaginated,
                data: content,
                totalElements,
                totalPages
            }
        }
    });

    //MANUTENZIONI
    builder.addCase(GestioneManutenzioniTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            manutenzioniPaginated: {
                ...state.manutenzioniPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(GestioneManutenzioniTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            manutenzioniPaginated: {
                ...state.manutenzioniPaginated,
                pageSize: action.payload
            }
        }
    });

    builder.addCase(GestioneManutenzioniTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            manutenzioniPaginated:{
                ...state.manutenzioniPaginated,
                filters:{
                    ...state.manutenzioniPaginated.filters,
                    ...action.payload
                }
            }
        }
    })

    builder.addCase(GestioneManutenzioniTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            manutenzioniPaginated:{
                ...state.manutenzioniPaginated,
                ...action.payload
            }
        }
    });


    //Accessi
    builder.addCase(GestioneAccessiTableAction.setPageIndexAction, (state, action) => {
        return {
            ...state,
            accessiPaginated: {
                ...state.accessiPaginated,
                pageIndex: action.payload
            }
        }
    });

    builder.addCase(GestioneAccessiTableAction.setPageSizeAction, (state, action) => {
        return {
            ...state,
            accessiPaginated: {
                ...state.accessiPaginated,
                pageSize: action.payload
            }
        }
    });


    builder.addCase(GestioneAccessiTableAction.setFiltersAction, (state, action) => {
        return {
            ...state,
            accessiPaginated:{
                ...state.accessiPaginated,
                filters:{
                    ...state.accessiPaginated.filters,
                    ...action.payload
                }
            }
        }
    })

    builder.addCase(GestioneAccessiTableAction.setOrderingFiltersAction, (state, action) => {
        return {
            ...state,
            accessiPaginated:{
                ...state.accessiPaginated,
                ...action.payload
            }
        }
    });
    builder.addDefaultCase((state, action) => {
        return state;
    });


});
