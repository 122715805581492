import React, {useEffect, useState} from 'react';
import {
    Box, Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading, Image,
    Input, Link,
    Stack,
    Text
} from '@chakra-ui/react';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import BuildingImg from 'assets/general/building.svg';
import {loadTranslations} from 'i18n/i18n.service';
import {SettingsActions} from 'store/settings/settings.action';
import versionObject from 'assets/version.json';
import {borderRadius} from 'style/styleConstants';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {LoginAuthenticateResponse,  LoginMaintenanceParams, LoginParams} from "../../store/auth/types";
import {primaryButtonProps} from "../../shared/components/default/myButton/myButton.types";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {loginFormDefaultValue} from "../login/types";
import {uiManagerActions} from "../../store/uiManager/uiManager.action";
import {RoutesPaths} from "../../navigation/root.routes";
import {loginMaintenanceFormDefaultValue, LoginMaintenanceValidationSchema} from "./login.validation";
import itTexts from './i18n/it.json';
import enTexts from './i18n/en.json';
import {GestioneStruttureActions} from "../../store/gestioneStrutture/gestioneStrutture.action";
import {GestioneStruttura} from "../../models/struttura/struttura.be";
import {HomePaths} from "../../navigation/home/home.routes";
const LoginMaintenance = () => {
    const isLoading = useAppSelector(state => state.authReducer.isLoading);
    const navigate = useNavigate()
    const {t} = useTranslation();
    const i18nTexts = {
        'en': enTexts,
        'it': itTexts,
    };
    loadTranslations('loginPage', i18nTexts)
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<LoginMaintenanceParams>({
        resolver: yupResolver(LoginMaintenanceValidationSchema),
        defaultValues: loginMaintenanceFormDefaultValue,
    });

    const dispatch = useAppDispatch();

    const handleLogin = async (params: LoginMaintenanceParams) => {
        await dispatch(GestioneStruttureActions.fetchStruttureMaintenanceAction(params)).then((responseData) => {
            const response = responseData.payload as GestioneStruttura[]
            if (!response ) {
                dispatch(uiManagerActions.showToast({
                    title: 'Ops...',
                    description: 'Sembra che non ci sia nessun utente con queste credenziali',
                    duration: 3000,
                    status: 'error',
                }))
            }else{
                navigate('/gestioneStrutture/' + params.codice)
            }
        })
    }



    return (
        <Stack minH={'100vh'} direction={{base: 'column'}} overflowY={'hidden'} >
            <Flex  paddingTop={5} overflowY={'hidden'} justify={'center'} >
            <Image
                    w={'20vh'}
                    h={'20vh'}
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={BuildingImg}
                />
            </Flex>
            <Flex p={8} flex={1} justify={'center'}>
                <Stack  w={'full'} maxW={'md'}>
                <Heading fontSize={'2xl'}>{t('loginPage:TEXTS.TITLE_MAINTENANCE')}</Heading>
                    <form className={'formStyle'} onSubmit={handleSubmit(handleLogin)}>
                        <FormControl id="codice" isInvalid={!!errors.codice} my={4}>
                            <FormLabel>{t('loginPage:INPUT_FIELDS.PASSWORD')}</FormLabel>
                            <Input {...register('codice')} type={'number'}/>
                            {!!errors.codice ?

                                <FormErrorMessage data-testid={'loginPage:formErrorMessage'} >{t(`${errors.codice?.message}`)}</FormErrorMessage>
                                :
                                <FormHelperText>{t('loginPage:INPUT_FIELDS.PASSWORD_SUBTITLE')}</FormHelperText>
                            }
                        </FormControl>
                        <Stack spacing={6}>
                            <Button {...primaryButtonProps} type={'submit'} my={4}
                                    data-testid={'loginPage:loginButton'}
                                    isLoading={isLoading}>{t('loginPage:BUTTONS.JOIN')}</Button>
                        </Stack>
                    </form>
                </Stack>
            </Flex>
        </Stack>
    )
};


export default LoginMaintenance
