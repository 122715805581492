import {Struttura, StrutturaTableModel} from 'models/struttura/struttura.model';

export const struttureTableModelMapper = (prenotazione: Struttura[]) : StrutturaTableModel[] => {
    const dataList: StrutturaTableModel[] = prenotazione?.map(p => ({
        appartamento: p?.nomeStruttura,
        nomeInterno: p?.nomeInterno,
        interno: p?.strutturaIndirizzo?.interno,
        maxOccupancy: p?.maxOccupancy,
        disabled: p?.disabled,
        id: `${p.id}`
    }))
    return dataList;
}
