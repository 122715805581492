import React, {FC} from 'react';
import {COLORS} from 'style/chakraTheme';
import {Tooltip} from '@chakra-ui/react'
import {
    IoAlertCircle, IoCheckmark,
    IoCheckmarkCircleSharp,
    IoCloseCircleSharp, IoCloseOutline,
    IoCloudCircle,
    IoRemoveCircleSharp
} from 'react-icons/io5';

export interface IconStatusCheckInProps {
    dataCheckInEffettuato: string;
}

export const IconStatusCheckIn: FC<IconStatusCheckInProps> = (dataCheckInEffettuato) => {
    let label ="Check-in effettuato "+ dataCheckInEffettuato.dataCheckInEffettuato
    if (dataCheckInEffettuato.dataCheckInEffettuato === null) {
        return (

            <Tooltip label="Check-in non effettuato">
                <span>
                    <IoCloseOutline size={32} color={COLORS.DANGER_500}/>
                </span>
            </Tooltip>
        )
    } else {
        return (
            <Tooltip label={label} >
                <span>
                    <IoCheckmark size={32} color={COLORS.PRIMARY_500}/>
                </span>
            </Tooltip>
        )
    }
};
