import {UtenteModel, UtenteTableModel} from 'models/utente.model';

export const UtenteTableModelMapper = (prenotazione: UtenteModel[]) : UtenteTableModel[] => {
    const dataList: UtenteTableModel[] = prenotazione?.map(p => ({
        id: p.id,
        nome: `${p.firstName} ${p.lastName}`,
        email: p.email,
        enabled: p.enabled,
        role: p.roles.find( r => r.name === 'ROLE_ADMIN') != undefined ? 'Admin' : 'Utente',
    }))
    return dataList;
}
