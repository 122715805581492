import {createAction} from '@reduxjs/toolkit';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';

export const enum GESTIONE_MANUTENZIONI_TABLE_ACTION {
    SET_PAGE_INDEX = 'GESTIONE_MANUTENZIONI_SET_PAGE_INDEX',
    SET_PAGE_SIZE = 'GESTIONE_MANUTENZIONI_SET_PAGE_SIZE',
    SET_SEARCH_TABLE_DATA = 'GESTIONE_MANUTENZIONI_SET_SEARCH_TABLE_DATA',
    SET_FILTERS = 'GESTIONE_MANUTENZIONI_SET_FILTERS',
    SET_ORDERING_FILTERS = 'GESTIONE_MANUTENZIONI_SET_ORDERING_FILTERS',
}

const setPageIndexAction = createAction(GESTIONE_MANUTENZIONI_TABLE_ACTION.SET_PAGE_INDEX, (payload:number) => {
    return {payload}
})

const setPageSizeAction = createAction(GESTIONE_MANUTENZIONI_TABLE_ACTION.SET_PAGE_SIZE, (payload:number) => {
    return {payload}
})

const setFiltersAction = createAction(GESTIONE_MANUTENZIONI_TABLE_ACTION.SET_FILTERS, (payload:PaginatedTableFilters) => {
    return {payload}
})
const setOrderingFiltersAction = createAction(GESTIONE_MANUTENZIONI_TABLE_ACTION.SET_ORDERING_FILTERS, (payload:OrderingFilters) => {
    return {payload}
})

export const GestioneManutenzioniTableAction = {
    setPageIndexAction,
    setPageSizeAction,
    setFiltersAction,
    setOrderingFiltersAction
};
