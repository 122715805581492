import {FetchUtentiParamsAndBody, FetchUtentiResponse, PaginatedRequestParamsAndBody} from 'store/home/type';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {CreateUtenteModel} from 'models/utente.model';

const fetchUtenti = async (requestParams: FetchUtentiParamsAndBody): Promise<FetchUtentiResponse> => {
    console.log('Request [fetchUtenti] params:', requestParams);
    const params:PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size ,
        //FIXED BY DEFAULT
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }
    const response: AxiosResponse<FetchUtentiResponse> = await appAxios.post(`/api/admin/getUserList`,{
        ...requestParams.entity
    },{
        params
    });

    console.log('Request [fetchUtenti] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchUtenti')
}


const createUtente = async (utenteModel: CreateUtenteModel): Promise<void> => {
    console.log('Request [createUtente] params:', utenteModel);
    const response: AxiosResponse = await appAxios.post(`/api/admin/user`,utenteModel);

    console.log('Request [createUtente] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during createUtente')
}

const deleteUtente = async (id:number): Promise<void> => {
    console.log('Request [deleteUtente] params:', id);
    const response: AxiosResponse = await appAxios.delete(`/api/admin/user/${id}`);

    console.log('Request [deleteUtente] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteUtente')
}


export const UtenteService = {
    fetchUtenti,
    createUtente,
    deleteUtente
}
