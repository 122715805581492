import {createAction} from '@reduxjs/toolkit';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';

export const enum UTENTI_TABLE_ACTION {
    SET_PAGE_INDEX = 'UTENTI_SET_PAGE_INDEX',
    SET_PAGE_SIZE = 'UTENTI_SET_PAGE_SIZE',
    SET_FILTERS = 'UTENTI_SET_FILTERS',
    SET_ORDERING_FILTERS = 'UTENTI_SET_ORDERING_FILTERS',
}

const setPageIndexAction = createAction(UTENTI_TABLE_ACTION.SET_PAGE_INDEX, (payload:number) => {
    return {payload}
})

const setPageSizeAction = createAction(UTENTI_TABLE_ACTION.SET_PAGE_SIZE, (payload:number) => {
    return {payload}
})

const setFiltersAction = createAction(UTENTI_TABLE_ACTION.SET_FILTERS, (payload:PaginatedTableFilters) => {
    return {payload}
})

const setOrderingFiltersAction = createAction(UTENTI_TABLE_ACTION.SET_ORDERING_FILTERS, (payload:OrderingFilters) => {
    return {payload}
})

export const UtentiTableAction = {
    setPageIndexAction,
    setPageSizeAction,
    setFiltersAction,
    setOrderingFiltersAction
};
