import {
    FetchStrutturaInfoParamsAndBody,
    FetchStrutturaInfoResponse,
    FetchStruttureParamsAndBody,
    FetchStruttureResponse,
    PaginatedRequestParamsAndBody
} from 'store/home/type';
import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {CreateStrutturaInfo, Struttura} from 'models/struttura/struttura.model';
import {
    SendDatiCatastaliParams,
    SendLinkUtiliParams,
    SendProcedureParams,
    SendProprietarioParams, SendShellyParams,
    SendStrutturaBaseParams,
    SendVarcoParams
} from 'store/strutturaCreation/types';
import {LinkUtili} from 'models/linkUtili.model';
import {Procedura} from 'models/procedura.model';
import {Varco} from 'models/varco.model';
import {EditProprietarioStruttura} from 'models/proprietario.model';
import {StrutturaBe} from 'models/struttura/struttura.be';
import {struttureModelMapper} from 'service/mappers/struttura.mapper';
import {Shelly} from "../../models/shelly.model";
import {Accesso} from "../../store/gestioneAccessi/types";

const fetchStrutture = async (requestParams: FetchStruttureParamsAndBody): Promise<FetchStruttureResponse> => {
    console.log('Request [fetchPrenotazioni] params:', requestParams);
    const params: PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size,
        //FIXED BY DEFAULT
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }
    const response: AxiosResponse<FetchStruttureResponse> = await appAxios.post(`/api/cerbero/struttura/getList`, {
        ...requestParams.entity
    }, {
        params
    });

    console.log('Response [fetchStrutture] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchStrutture')
}

const sendStrutturaBase = async ({id, payload}: SendStrutturaBaseParams): Promise<void> => {
    console.log('Request [sendStrutturaBase] params:', payload);

    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/informazioniBase/${id}`, payload);

    console.log('Response [sendStrutturaBase] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendStrutturaBase')
}
const sendDatiCatastali = async ({id, payload}: SendDatiCatastaliParams): Promise<void> => {
    console.log('Request [sendDatiCatastali] params:', payload);

    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/${id}/catasto`, payload);

    console.log('Response [sendDatiCatastali] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendDatiCatastali')
}
const sendProprietario = async ({idStruttura, idProprietario, payload}: SendProprietarioParams): Promise<void> => {
    console.log('Request [sendProprietario] params:', payload);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${idStruttura}/cambioProprietario/${idProprietario}`, payload);

    console.log('Response [sendProprietario] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendProprietario')
}

const getLink = async (idStruttura: string | number): Promise<LinkUtili[]> => {
    console.log('Request [getLink] params:', idStruttura);

    const response: AxiosResponse<LinkUtili[]> = await appAxios.get(`/api/cerbero/struttura/${idStruttura}/link`);

    console.log('Response [getLink] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getLink')
}
const sendLink = async ({id, payload}: SendLinkUtiliParams): Promise<void> => {
    console.log('Request [sendLinkUtili] params:', payload);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${id}/link`, payload);

    console.log('Response [sendLinkUtili] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendLinkUtili')
}
const deleteLink = async (idLink: string | number): Promise<void> => {
    console.log('Request [deleteLink] params:', idLink);

    const response: AxiosResponse = await appAxios.delete(`/api/cerbero/struttura/link/${idLink}`);

    console.log('Response [deleteLink] ', response);
    if (response && response.data) {
        return
    }
    throw new Error('Error during deleteLink')
}

const getProcedure = async (idStruttura: string | number): Promise<Procedura[]> => {
    console.log('Request [getProcedure] params:', idStruttura);

    const response: AxiosResponse<Procedura[]> = await appAxios.get(`/api/cerbero/struttura/${idStruttura}/procedura`);

    console.log('Response [getProcedure] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getProcedure')
}
const sendProcedura = async ({id, payload}: SendProcedureParams): Promise<void> => {
    console.log('Request [sendProcedure] params:', payload);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${id}/procedura`, payload);

    console.log('Request [sendProcedure] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendProcedure')
}
const deleteProcedura = async (idProcedura: string | number): Promise<void> => {
    console.log('Request [deleteProcedura] params:', idProcedura);

    const response: AxiosResponse = await appAxios.delete(`/api/cerbero/struttura/procedura/${idProcedura}`);

    console.log('Response [deleteProcedura] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteProcedura')
}

const getVarchi = async (idStruttura: string | number): Promise<Varco[]> => {
    console.log('Request [getVarchi] params:', idStruttura);

    const response: AxiosResponse<Varco[]> = await appAxios.get(`/api/cerbero/struttura/${idStruttura}/varco`);

    console.log('Response [getVarchi] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getVarchi')
}
const sendVarco = async ({id, payload}: SendVarcoParams): Promise<void> => {
    console.log('Request [sendVarco] params:', payload);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${id}/varco`, payload);

    console.log('Response [sendVarco] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendVarco')
}

const sendShelly = async ({id, payload}: SendShellyParams): Promise<void> => {
    console.log('Request [sendShelly] params:', payload);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${id}/shelly`, payload);

    console.log('Response [sendShelly] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendShelly')
}
const deleteVarco = async (idVarco: string | number): Promise<void> => {
    console.log('Request [deleteVarco] params:', idVarco);

    const response: AxiosResponse = await appAxios.delete(`/api/cerbero/struttura/varco/${idVarco}`);

    console.log('Response [deleteVarco] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteVarco')
}
const deleteShelly = async (idShelly: string | number): Promise<void> => {
    console.log('Request [deleteShelly] params:', idShelly);

    const response: AxiosResponse = await appAxios.delete(`/api/cerbero/struttura/shelly/${idShelly}`);

    console.log('Response [deleteShelly] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteShelly')
}
const fetchStruttura = async (idStruttura: string | number): Promise<Struttura> => {
    console.log('Request [fetchStruttura] params:', idStruttura);

    const response: AxiosResponse<StrutturaBe> = await appAxios.get(`/api/cerbero/struttura/get/${idStruttura}`);
    console.log('Response [fetchStruttura] ', response.data);

    if (response && response.data) {
        //Map struttura
        return struttureModelMapper(response.data)
    }
    throw new Error('Error during fetchStruttura')
}

const updateSmobuData = async (): Promise<number> => {
    console.log('Request [updateSmobuData] ',);

    const response: AxiosResponse<number> = await appAxios.get(`/api/cerbero/struttura/updateDatabaseAppartamenti`);

    console.log('Response [updateSmobuData] ', response);
    if (response.status === 200) {
        console.log('Response [updateSmobuData] ok', response.data);
        return response.data
    }
    throw new Error('Error during updateSmobuData')
}


const abilitaStruttura = async (id: string | number): Promise<void> => {
    console.log('Request [abilitaStruttura] params:', id);

    const response: AxiosResponse = await appAxios.get(`/api/cerbero/struttura/validate/${id}`);

    console.log('Response [abilitaStruttura] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during abilitaStruttura')
}

const disabilitaStruttura = async (id: string | number): Promise<void> => {
    console.log('Request [disabilitaStruttura] params:', id);

    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/disabilita/${id}`);

    console.log('Response [disabilitaStruttura] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during disabilitaStruttura')
}


const fetchStrutturaInfo = async (requestParams: FetchStrutturaInfoParamsAndBody): Promise<FetchStrutturaInfoResponse> => {
    console.log('Request [fetchStrutturaInfo] params:', requestParams);
    const params: PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size,
    }
    const response: AxiosResponse<FetchStrutturaInfoResponse> = await appAxios.post(`/api/cerbero/struttura/${requestParams.idStruttura}/getInfo`, {}, {params});

    console.log('Response [fetchStrutturaInfo] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchStrutturaInfo')
}

const sendStrutturaInfo = async (idStruttura: number | string, strutturaInfo: CreateStrutturaInfo): Promise<void> => {
    console.log('Request [sendStrutturaInfo] params:', strutturaInfo);
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/${idStruttura}/info`, strutturaInfo);

    console.log('Response [sendStrutturaInfo] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during sendStrutturaInfo')
}

const deleteStrutturaInfo = async (idStrutturaInfo: string | number): Promise<void> => {
    console.log('Request [deleteStrutturaInfo] params:', idStrutturaInfo);

    const response: AxiosResponse = await appAxios.delete(`/api/cerbero/struttura/info/${idStrutturaInfo}`);

    console.log('Response [deleteStrutturaInfo] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteStrutturaInfo')
}

const editProprietarioStruttura = async (id: number | string, proprietarioStruttura: EditProprietarioStruttura): Promise<void> => {
    console.log('Request [editProprietarioStruttura] params:', proprietarioStruttura);
    const body: EditProprietarioStruttura = {
        contabilitaId: proprietarioStruttura.contabilitaId,
        costoGestioneLion: proprietarioStruttura.costoGestioneLion,
    }
    const response: AxiosResponse = await appAxios.post(`/api/cerbero/struttura/modificaProprietarioStruttura/${id}`, body);

    console.log('Response [editProprietarioStruttura] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editProprietarioStruttura')
}

const editVarco = async (varco: Varco): Promise<void> => {
    console.log('Request [editVarco] params:', varco);
    const {id, ...varcoBody} = varco
    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/varco/${id}`, varcoBody);

    console.log('Response [editVarco] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editVarco')
}

const editShelly = async (shelly: Shelly): Promise<void> => {
    console.log('Request [editShelly] params:', shelly);
    const {id, ...shellyBody} = shelly
    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/shelly/${id}`, shellyBody);

    console.log('Response [editShelly] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editShelly')
}

const getShelly = async (idStruttura: string | number): Promise<Varco[]> => {
    console.log('Request [getShelly] params:', idStruttura);

    const response: AxiosResponse<Varco[]> = await appAxios.get(`/api/cerbero/struttura/${idStruttura}/shelly`);

    console.log('Response [getShelly] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getShelly')
}


export const StruttureService = {
    fetchStrutture,
    sendStrutturaBase,
    sendDatiCatastali,
    sendProcedura,
    sendProprietario,
    sendShelly,
    getLink,
    deleteProcedura,
    deleteLink,
    getProcedure,
    sendLink,
    getVarchi,
    deleteVarco,
    sendVarco,
    fetchStruttura,
    abilitaStruttura,
    updateSmobuData,
    fetchStrutturaInfo,
    sendStrutturaInfo,
    deleteStrutturaInfo,
    disabilitaStruttura,
    editProprietarioStruttura,
    editVarco,
    getShelly,
    editShelly,
    deleteShelly,
};
