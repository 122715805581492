import {createAsyncThunk} from '@reduxjs/toolkit';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {GestioneStruttureService} from 'service/gestioneStrutture/gestioneStrutture.service';
import {GestioneStruttura} from 'models/struttura/struttura.be';
import {OpenVarcoMaintenanceRequestParams, OpenVarcoRequestParams} from 'store/gestioneStrutture/types';
import {LoginMaintenanceParams} from "../auth/types";

export const enum GESTIONE_STRUTTURE_ACTION {
    FETCH_GESTIONE_STRUTTURE = 'gestioneStrutture/FETCH_GESTIONE_STRUTTURE',
    OPEN_VARCO = 'gestioneStrutture/OPEN_VARCO',
}

const fetchStruttureMaintenanceAction = createAsyncThunk<GestioneStruttura[],LoginMaintenanceParams>(GESTIONE_STRUTTURE_ACTION.FETCH_GESTIONE_STRUTTURE, async (params, thunkAPI) => {
    try {
        return await GestioneStruttureService.fetchStruttureMaintenance(params);
    } catch (e: any) {
        console.log('[ERROR] fetchPrenotazioniAction',e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati delle strutture',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const OpenVarcoAction = createAsyncThunk<void,OpenVarcoRequestParams>(GESTIONE_STRUTTURE_ACTION.OPEN_VARCO, async (params, thunkAPI) => {
    try {
        await GestioneStruttureService.openVarcoStruttura(params);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Successo',
            description: `Il varco con id: ${params.varcoId} è stato aperto con successo!`,
            duration: 3000,
            status: 'success'
        }));

    } catch (e: any) {
        console.log('[ERROR] OpenVarcoAction',e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad aprire il varco della struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const OpenVarcoMaintenanceAction = createAsyncThunk<void,OpenVarcoMaintenanceRequestParams>(GESTIONE_STRUTTURE_ACTION.OPEN_VARCO, async (params, thunkAPI) => {
    try {
        await GestioneStruttureService.openVarcoMaintenanceStruttura(params);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Successo',
            description: `Il varco con id: ${params.varcoId} è stato aperto con successo!`,
            duration: 3000,
            status: 'success'
        }));

    } catch (e: any) {
        console.log('[ERROR] OpenVarcoAction',e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad aprire il varco della struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

export const GestioneStruttureActions = {
    fetchStruttureMaintenanceAction,
    OpenVarcoAction,
    OpenVarcoMaintenanceAction
}
