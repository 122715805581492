import {
    Box,
    Flex,
    FlexProps,
    HStack,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    VStack
} from '@chakra-ui/react';
import {FiChevronDown, FiMenu} from 'react-icons/fi';
import LogoImage from 'assets/logos/homehotel_b.png';
import {IoChevronBack, IoPersonCircle} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {AuthActions} from 'store/auth/auth.action';
import {COLORS} from 'style/chakraTheme';
import {version} from 'assets/version.json';

interface MobileProps extends FlexProps {
    onOpen: () => void;
    title: string;
    hasBack?: boolean;
    customBackRoute?: string;
}



export const MobileNav = ({onOpen,title,hasBack,customBackRoute, ...rest}: MobileProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {username,role} = useAppSelector(state => state.authReducer)
    function handleLogout() {
        dispatch(AuthActions.logoutAction())
        navigate('/')
    }
    return (
        <Flex
            m={0}
            px={{base: 4, md: 4}}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={'space-between'}
            {...rest}>
            {/*<IconButton*/}
            {/*    display={{base: 'flex', md: 'none'}}*/}
            {/*    onClick={onOpen}*/}
            {/*    variant="outline"*/}
            {/*    aria-label="open menu"*/}
            {/*    icon={<FiMenu/>}*/}
            {/*/>*/}
            <HStack justifyContent={'space-between'} w={'full'}>
                    <HStack>
                        {hasBack && <IoChevronBack size={24} onClick={() => {
                            customBackRoute ? navigate(customBackRoute) : navigate(-1)
                        }}/>}
                        <Text display={{base: 'block', md: 'block'}} fontWeight={'bold'} fontSize={'lg'}
                              color={'titleColor'}>{title}</Text>
                    </HStack>
                <Box>
                    {/*<Image display={{base: 'flex', md: 'none'}} src={LogoImage} width={180}/>*/}
                    <HStack spacing={{base: '0', md: '6'}}>
                        {/* FOR FUTURE NOTIFICATION BELL ICON
                        <IconButton
                            size="lg"
                            variant="ghost"
                            aria-label="open menu"
                            icon={<FiBell />}
                        />*/}
                        <Flex alignItems={'center'}>
                            <Menu>
                                <MenuButton
                                    py={2}
                                    transition="all 0.3s"
                                    _focus={{boxShadow: 'none'}}>
                                    <HStack>
                                        <IoPersonCircle size={34} color={COLORS.PRIMARY_500} />
                                        <VStack
                                            display={{base: 'none', md: 'flex'}}
                                            alignItems="flex-start"
                                            spacing="1px"
                                            ml="2">
                                            <Text fontSize="sm">{username}</Text>
                                            <Text fontSize="xs" color="gray.600">
                                                {role}
                                            </Text>
                                        </VStack>
                                        <Box display={{base: 'none', md: 'flex'}}>
                                            <FiChevronDown/>
                                        </Box>
                                    </HStack>
                                </MenuButton>
                                <MenuList
                                    bg={useColorModeValue('white', 'gray.900')}
                                    borderColor={useColorModeValue('gray.200', 'gray.700')}>
                                    <MenuItem><Text textColor={'captionColor'}>{'v'+version}</Text></MenuItem>
                                    <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </HStack>
                </Box>
            </HStack>
        </Flex>
    );
};
