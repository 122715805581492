import React, {FC} from 'react';
import {MyCard} from 'shared/components/default/myCard/myCard.component';
import {HStack, VStack} from '@chakra-ui/react';
import {TextWithStartFrontLeading} from 'utils/texts.utils';
import {GestioneStruttura} from 'models/struttura/struttura.be';

export interface GestioneStrutturaCardProps{
    gestioneStruttura: GestioneStruttura
}

export const GestioneStrutturaCard:FC<GestioneStrutturaCardProps> = ({gestioneStruttura}) => {
    return (
        <MyCard title={`Id Struttura: ${gestioneStruttura.id}`} containerProps={{w: 'full'}}>
                    <HStack w={'full'} justify={'space-between'}>
                        <VStack w={'full'} justify={'start'} align={'start'}>
                            <TextWithStartFrontLeading
                                leading={'Nome Struttura:'}
                                main={`${gestioneStruttura?.nomeStruttura}`}/>
                            <TextWithStartFrontLeading
                                leading={'Channel Manager:'}
                                main={gestioneStruttura?.channelManagerId}/>

                        </VStack>
                        <VStack w={'full'} justify={'start'} align={'start'}>
                            <TextWithStartFrontLeading
                                leading={'Nome Interno:'}
                                main={`${gestioneStruttura?.nomeInterno}`}/>
                            <TextWithStartFrontLeading
                                leading={'Piano:'}
                                main={`${gestioneStruttura?.piano}`}/>
                        </VStack>
                </HStack>
        </MyCard>
    );
};
