import {createAction} from '@reduxjs/toolkit';

export const enum STRUTTURA_INFO_TABLE_ACTION {
    SET_PAGE_INDEX = 'STRUTTURA_INFO_SET_PAGE_INDEX',
    SET_PAGE_SIZE = 'STRUTTURA_INFO_SET_PAGE_SIZE',
}

const setPageIndexAction = createAction(STRUTTURA_INFO_TABLE_ACTION.SET_PAGE_INDEX, (payload:number) => {
    return {payload}
})

const setPageSizeAction = createAction(STRUTTURA_INFO_TABLE_ACTION.SET_PAGE_SIZE, (payload:number) => {
    return {payload}
})


export const StrutturaInfoTableAction = {
    setPageIndexAction,
    setPageSizeAction,
};
