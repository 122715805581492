import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    FetchPrenotazioniParamsAndBody,
    FetchPrenotazioniResponse,
    FetchProprietariParamsAndBody,
    FetchProprietariResponse,
    FetchStrutturaInfoParamsAndBody,
    FetchStrutturaInfoResponse,
    FetchStruttureParamsAndBody,
    FetchStruttureResponse,
    FetchUtentiParamsAndBody,
    FetchUtentiResponse,
    ModificaPuliziaExtraParams
} from 'store/home/type';
import {Prenotazione} from 'models/prenotazione.model';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {PrenotazioniService} from 'service/home/prenotazioni.service';
import {ProprietariService} from 'service/home/proprietari.service';
import {StruttureService} from 'service/home/strutture.service';
import {EditProprietarioStruttura, Proprietario} from 'models/proprietario.model';
import {DownloaderManagerProps, DownloadsService} from 'service/downloads/downloads.service';
import {Varco} from 'models/varco.model';
import {UtenteService} from 'service/home/utente.service';
import {CreateUtenteModel} from 'models/utente.model';
import {CreateStrutturaInfo} from 'models/struttura/struttura.model';
import {
    Accesso,
    FetchAccessiParamsAndBody,
    FetchAccessiResponse,
} from "../gestioneAccessi/types";
import {GestioneAccessiService} from "../../service/gestioneAccessi/gestioneAccessi.service";
import {GestioneManutenzioniService} from "../../service/gestioneManutenzioni/gestioneManutenzioni.service";
import {getPathToAppartamentoDetail} from "../../navigation/navigation.service";
import {
    FetchManutenzioniParamsAndBody,
    FetchManutenzioniResponse,
    Manutenzione,
    Pagamento
} from "../gestioneManutenzioni/types";

export const enum HOME_ACTION {
    //FETCH
    FETCH_PRENOTAZIONI = 'FETCH_PRENOTAZIONI',
    FETCH_UTENTI = 'FETCH_UTENTI',
    FETCH_PRENOTAZIONE = 'FETCH_PRENOTAZIONE',
    FETCH_PROPRIETARI = 'FETCH_PROPRIETARI',
    FETCH_PROPRIETARIO = 'FETCH_PROPRIETARIO',
    FETCH_STRUTTURE = 'FETCH_STRUTTURE',
    FETCH_VARCHI = 'FETCH_VARCHI',
    FETCH_STRUTTURA_INFO = 'FETCH_STRUTTURA_INFO',
    FETCH_ACCESSI = 'gestioneStrutture/FETCH_ACCESSI',
    FETCH_MANUTENZIONE = 'FETCH_MANUTENZIONE',
    FETCH_MANUTENZIONI = 'FETCH_MANUTENZIONI',

    //EDIT
    EDIT_PROPRIETARIO = 'EDIT_PROPRIETARIO',
    EDIT_PROPRIETARIO_STRUTTURA = 'EDIT_PROPRIETARIO_STRUTTURA',
    EDIT_MANUTENZIONE = 'EDIT_MANUTENZIONE',
    EDIT_ACCESSO = 'EDIT_ACCESSO',
    EDIT_PAGAMENTO = 'EDIT_PAGAMENTO',
    DELETE_PAGAMENTO = 'DELETE_PAGAMENTO',
    //Create
    CREATE_UTENTE = 'CREATE_USER',
    CREATE_PROPRIETARIO = 'CREATE_PROPRIETARIO',
    CREATE_STRUTTURA_INFO = 'CREATE_STRUTTURA_INFO',
    CREATE_ACCESSO = 'CREATE_ACCESSO',
    CREATE_MANUTENZIONE = 'CREATE_MANUTENZIONE',
    CREATE_PAGAMENTO = 'CREATE_PAGAMENTO',
    //Prenotazione
    SBLOCCA_PRENOTAZIONE = 'SBLOCCA_PRENOTAZIONE',
    BLOCCA_PRENOTAZIONE = 'BLOCCA_PRENOTAZIONE',
    RESET_PRENOTAZIONE = 'RESET_PRENOTAZIONE',
    RIMUOVI_PULIZIA = 'RIMUOVI_PULIZIA',
    SEND_TO_PS = 'SEND_TO_PS',

    //DELETE
    DELETE_UTENTE = 'DELETE_UTENTE',
    DELETE_STRUTTURA_INFO = 'DELETE_STRUTTURA_INFO',
    DELETE_ACCESSO = 'DELETE_ACCESSO',
    //ALTRO
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',
    EFFETTUA_CHECK_IN = 'EFFETTUA_CHECK_IN',
    MODIFICA_PULIZIA_EXTRA = 'MODIFICA_PULIZIA_EXTRA',
    UPDATE_SMOBU_DATA = 'UPDATE_SMOBU_DATA',
    UPDATE_CONSUMI = 'UPDATE_CONSUMI',
    INOLTRA_DATI_PRENOTAZIONE = 'INOLTRA_DATI_PRENOTAZIONE',

    UPLOAD_ALLEGATO = 'UPLOAD_ALLEGATO',
    DELETE_MANUTENZIONE = 'DELETE_MANUTENZIONE',
}

// Fetch LIST
const fetchPrenotazioniAction = createAsyncThunk<FetchPrenotazioniResponse, FetchPrenotazioniParamsAndBody>(HOME_ACTION.FETCH_PRENOTAZIONI, async (params, thunkAPI) => {
    try {
        return await PrenotazioniService.fetchPrenotazioni(params);
    } catch (e: any) {
        console.log('[ERROR] fetchPrenotazioniAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati delle prenotazioni',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchStrutturaInfoAction = createAsyncThunk<FetchStrutturaInfoResponse, FetchStrutturaInfoParamsAndBody>(HOME_ACTION.FETCH_STRUTTURA_INFO, async (params, thunkAPI) => {
    try {
        return await StruttureService.fetchStrutturaInfo(params);
    } catch (e: any) {
        console.log('[ERROR] fetchStrutturaInfoAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere le strutture info',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchUtentiAction = createAsyncThunk<FetchUtentiResponse, FetchUtentiParamsAndBody>(HOME_ACTION.FETCH_UTENTI, async (params, thunkAPI) => {
    try {
        return await UtenteService.fetchUtenti(params);
    } catch (e: any) {
        console.log('[ERROR] fetchUtentiAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati degli utenti',
            duration: 3000,
            status: 'error'
        }));

        throw e;
    }
});


const fetchProprietariAction = createAsyncThunk<FetchProprietariResponse, FetchProprietariParamsAndBody>(HOME_ACTION.FETCH_PROPRIETARI, async (params, thunkAPI) => {
    try {
        return await ProprietariService.fetchProprietari(params);
    } catch (e: any) {
        console.log('[ERROR] fetchProprietariAction', e);

        throw e;

    }
});

const fetchStruttureAction = createAsyncThunk<FetchStruttureResponse, FetchStruttureParamsAndBody>(HOME_ACTION.FETCH_STRUTTURE, async (params, thunkAPI) => {
    try {
        return await StruttureService.fetchStrutture(params);
    } catch (e: any) {
        console.log('[ERROR] fetchStruttureAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati delle strutture',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const fetchAccessiAction = createAsyncThunk<FetchAccessiResponse, FetchAccessiParamsAndBody>(HOME_ACTION.FETCH_ACCESSI, async (params, thunkAPI) => {
    try {
        return await GestioneAccessiService.fetchAccessi(params);
    } catch (e: any) {
        console.log('[ERROR] fetchPrenotazioniAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati degli accessi',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

//FETCH SINGLE
const fetchPrenotazioneAction = createAsyncThunk<Prenotazione, string>(HOME_ACTION.FETCH_PRENOTAZIONE, async (id, thunkAPI) => {
    try {
        return await PrenotazioniService.fetchPrenotazione(id);
    } catch (e: any) {
        console.log('[ERROR] fetchPrenotazioneAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati della prenotazione',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchProprietarioAction = createAsyncThunk<Proprietario, string>(HOME_ACTION.FETCH_PROPRIETARIO, async (id, thunkAPI) => {
    try {
        return await ProprietariService.fetchProprietario(id);
    } catch (e: any) {
        console.log('[ERROR] fetchProprietarioAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati del proprietario',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});


// CREATE
const createProprietarioAction = createAsyncThunk<boolean, Proprietario>(HOME_ACTION.CREATE_PROPRIETARIO, async (params, thunkAPI) => {
    try {
        await ProprietariService.createProprietario(params);
        return true
    } catch (e: any) {
        console.log('[ERROR] createProprietarioAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la creazione del proprietario',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const createAccessoAction = createAsyncThunk<boolean, Accesso>(HOME_ACTION.CREATE_ACCESSO, async (id, thunkAPI) => {
    try {
        await GestioneAccessiService.createAccesso(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] createAccessoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a creare l\'accesso',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const createManutenzioneAction = createAsyncThunk<Manutenzione, {
    idStruttura: number,
    manutenzione: Manutenzione
}>(HOME_ACTION.CREATE_MANUTENZIONE, async (params, thunkAPI) => {
    try {
        return await GestioneManutenzioniService.createManutenzione(params.manutenzione, params.idStruttura);
    } catch (e: any) {
        console.log('[ERROR] createManutenzione', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a registrare la manutenzione',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const createPagamentoAction = createAsyncThunk<Manutenzione, {
    idManutenzione: number,
    pagamento:Pagamento
}>(HOME_ACTION.CREATE_PAGAMENTO, async (params, thunkAPI) => {
    try {
      return  await GestioneManutenzioniService.createPagamento(params.pagamento, params.idManutenzione);

    } catch (e: any) {
        console.log('[ERROR] createPagamentoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a registrare il pagamento',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const createUtenteAction = createAsyncThunk<boolean, CreateUtenteModel>(HOME_ACTION.CREATE_UTENTE, async (params, thunkAPI) => {
    try {
        await UtenteService.createUtente(params);
        return true
    } catch (e: any) {
        console.log('[ERROR] createUtenteAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la creazione dell utente',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const createStrutturaInfoAction = createAsyncThunk<boolean, {
    idStruttura: number | string,
    strutturaInfo: CreateStrutturaInfo
}>(HOME_ACTION.CREATE_STRUTTURA_INFO, async (params, thunkAPI) => {
    try {
        await StruttureService.sendStrutturaInfo(params.idStruttura, params.strutturaInfo);
        return true
    } catch (e: any) {
        console.log('[ERROR] createStrutturaInfoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad aggiungere le informazioni per la struttura',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});


//OTHER

const deleteStrutturaInfoAction = createAsyncThunk<boolean, number | string>(HOME_ACTION.DELETE_STRUTTURA_INFO, async (idStrutturaInfo, thunkAPI) => {
    try {
        await StruttureService.deleteStrutturaInfo(idStrutturaInfo);
        return true
    } catch (e: any) {
        console.log('[ERROR] deleteStrutturaInfoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare le informazioni',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});


const effettuaCheckinAction = createAsyncThunk<boolean, string>(HOME_ACTION.EFFETTUA_CHECK_IN, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.effettuaCheckIn(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] effettuaCheckinAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'common:ERRORS:EFFETTUA_CHECKIN:TITLE',
            description: 'common:ERRORS:EFFETTUA_CHECKIN:DESCRIPTION',
            isI18nKey: true,
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});

const editProprietarioAction = createAsyncThunk<boolean, Proprietario>(HOME_ACTION.EDIT_PROPRIETARIO, async (params, thunkAPI) => {
    try {
        await ProprietariService.editProprietario(params);
        return true
    } catch (e: any) {
        console.log('[ERROR] editProprietarioAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica del proprietario',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const editProprietarioStrutturaAction = createAsyncThunk<boolean, {
    id: string | number,
    proprietarioStruttura: EditProprietarioStruttura
}>(HOME_ACTION.EDIT_PROPRIETARIO_STRUTTURA, async (params, thunkAPI) => {
    try {
        await StruttureService.editProprietarioStruttura(params.id, params.proprietarioStruttura);
        return true
    } catch (e: any) {
        console.log('[ERROR] editProprietarioStrutturaAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica del proprietario',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const downloadFileAction = createAsyncThunk<boolean, DownloaderManagerProps>(HOME_ACTION.DOWNLOAD_FILE, async (params, thunkAPI) => {
    try {
        await DownloadsService.downloaderManager(params);
        return true
    } catch (e: any) {
        console.log('[ERROR] downloadFileAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a scaricare il file',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const bloccaPrenotazioneAction = createAsyncThunk<boolean, string | number>(HOME_ACTION.BLOCCA_PRENOTAZIONE, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.bloccaPrenotazione(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] bloccaPrenotazioneAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a bloccare la prenotazione',
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});

const sbloccaPrenotazioneAction = createAsyncThunk<boolean, string | number>(HOME_ACTION.SBLOCCA_PRENOTAZIONE, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.sbloccaPrenotazione(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] sbloccaPrenotazioneAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a sbloccare la prenotazione',
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});

const resetPrenotazioneAction = createAsyncThunk<boolean, string | number>(HOME_ACTION.RESET_PRENOTAZIONE, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.resetPrenotazione(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] resetPrenotazioneAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a resettare la prenotazione',
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});


const rimuoviPuliziaAction = createAsyncThunk<boolean, string | number>(HOME_ACTION.RIMUOVI_PULIZIA, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.rimuoviPulizia(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] rimuoviPulizia', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a rimuovere la pulizia',
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});

const sendToPSAction = createAsyncThunk<boolean, string | number>(HOME_ACTION.SEND_TO_PS, async (id, thunkAPI) => {
    try {
        await PrenotazioniService.sendToPS(id);
        return true
    } catch (e: any) {
        console.log('[ERROR] sendToPSAction', e);
        let message =  e.response != null && e.response?.data != null ? e.response?.data :  'Non siamo riusciti ad inviare i dati al PS';
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description:message,
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});
const modificaPuliziaExtraAction = createAsyncThunk<boolean, ModificaPuliziaExtraParams>(HOME_ACTION.MODIFICA_PULIZIA_EXTRA, async ({
                                                                                                                                        tipo,
                                                                                                                                        idPrenotazione
                                                                                                                                    }, thunkAPI) => {
    try {
        if (tipo === 'AGGIUNGI') {
            await PrenotazioniService.aggiungiPuliziaExtra(idPrenotazione);
        } else {
            await PrenotazioniService.rimuoviPuliziaExtra(idPrenotazione);
        }
        return true
    } catch (e: any) {
        console.log('[ERROR] modificaPuliziaExtraAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a resettare la prenotazione',
            status: 'error',
            isClosable: true,
        }))
        throw e;
    }
});

const fetchVarchiAction = createAsyncThunk<Varco[], string | number>(HOME_ACTION.FETCH_VARCHI, async (params, thunkAPI) => {
    try {
        return await StruttureService.getVarchi(params);
    } catch (e: any) {
        console.log('[ERROR] fetchVarchiAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i varchi',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const deleteUtenteAction = createAsyncThunk<boolean, number>(HOME_ACTION.DELETE_UTENTE, async (idUtente, thunkAPI) => {
    try {
        await UtenteService.deleteUtente(idUtente);
        return true
    } catch (e: any) {
        console.log('[ERROR] deleteUtenteAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare l\'utente',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const deleteAccessoAction = createAsyncThunk<boolean, number>(HOME_ACTION.DELETE_ACCESSO, async (idAccesso, thunkAPI) => {
    try {
        await GestioneAccessiService.deleteAccesso(idAccesso);
        return true
    } catch (e: any) {
        console.log('[ERROR] deleteAccessoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare l\'accesso',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const updateSmobuDataAction = createAsyncThunk<number>(HOME_ACTION.UPDATE_SMOBU_DATA, async (params, thunkAPI) => {
    try {
        return await StruttureService.updateSmobuData();
    } catch (e: any) {
        console.log('[ERROR] updateSmobuDataAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad aggiornare i dati Smobu',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const inoltraDatiAction = createAsyncThunk<boolean, {
    id: string | number,
    telefono: string
}>(HOME_ACTION.INOLTRA_DATI_PRENOTAZIONE, async (params, thunkAPI) => {
    try {
        await PrenotazioniService.inoltraDatiPrenotazione(params.id, params.telefono);
        return true
    } catch (e: any) {
        console.log('[ERROR] inoltraDatiAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad inoltrare i dati',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const updateConsumiAction = createAsyncThunk<boolean, {
    id: number | string
}>(HOME_ACTION.UPDATE_CONSUMI, async (params, thunkAPI) => {

    try {
        await PrenotazioniService.updateConsumi(params.id);
        return true
    } catch (e: any) {
        console.log('[ERROR] updateConsumiAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti aggiornare i consumi',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchAccessoAction = createAsyncThunk<Accesso, string>(HOME_ACTION.FETCH_ACCESSI, async (id, thunkAPI) => {
    try {
        return await GestioneAccessiService.fetchAccesso(id);
    } catch (e: any) {
        console.log('[ERROR] fetchAccessoAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati dell\'accesso',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const editAccesso = createAsyncThunk<boolean, {
    id: string | number,
    accesso: Accesso
}>(HOME_ACTION.EDIT_PROPRIETARIO_STRUTTURA, async (params, thunkAPI) => {
    try {
        await GestioneAccessiService.editAccesso(params.id, params.accesso);
        return true
    } catch (e: any) {
        console.log('[ERROR] editAccesso', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica dell accesso',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});


const fetchManutenzioneAction = createAsyncThunk<Manutenzione, number>(HOME_ACTION.FETCH_MANUTENZIONE, async (id, thunkAPI) => {
    try {
        return await GestioneManutenzioniService.fetchManutenzione(id);
    } catch (e: any) {
        console.log('[ERROR] fetchManutenzione', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad ottenere i dati ',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const editManutenzione = createAsyncThunk<boolean, {
    id: string | number,
    manutenzione:Manutenzione
}>(HOME_ACTION.EDIT_MANUTENZIONE, async (params, thunkAPI) => {
    try {
        await GestioneManutenzioniService.editManutenzione(params.id, params.manutenzione);
        return true
    } catch (e: any) {
        console.log('[ERROR] editManutenzione', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica della manutenzione',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const editPagamento = createAsyncThunk<boolean, {
    id: string | number,
   pagamento:Pagamento
}>(HOME_ACTION.EDIT_PAGAMENTO, async (params, thunkAPI) => {
    try {
        await GestioneManutenzioniService.editPagamento(params.id, params.pagamento);
        return true
    } catch (e: any) {
        console.log('[ERROR] editAccesso', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad effettuare la modifica del pagamento',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const deletePagamentoAction = createAsyncThunk<boolean, number>(HOME_ACTION.DELETE_PAGAMENTO, async (idPagamento, thunkAPI) => {
    try {
        await GestioneManutenzioniService.deletePagamento(idPagamento);
        return true
    } catch (e: any) {
        console.log('[ERROR] deletePagamentoAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare il pagamento',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const fetchManutenzioniAction = createAsyncThunk<FetchManutenzioniResponse, FetchManutenzioniParamsAndBody>(HOME_ACTION.FETCH_MANUTENZIONI, async (params, thunkAPI) => {
    try {
        return await GestioneManutenzioniService.fetchManutenzioni(params);
    } catch (e: any) {
        console.log('[ERROR] fetchManutenzioniAction', e);

        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a recuperare le manutenzioni',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});
const uploadAllegato = createAsyncThunk<Manutenzione,
    {
        id: string | number,
        file: File,
        isAllegatoPre: boolean
    }>(HOME_ACTION.UPLOAD_ALLEGATO, async (params, thunkAPI) => {
    try {
        const formData = new FormData();
        formData.append('file', params.file);
        return    await GestioneManutenzioniService.uploadAllegato(formData, params.id , params.isAllegatoPre);
    } catch (e: any) {
        console.log('[ERROR] uploadAllegato', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a caricare l\'allegato',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const downloadAllegato = createAsyncThunk<boolean, {
    id : number,
    fileName : string
}>(HOME_ACTION.DOWNLOAD_FILE, async (params, thunkAPI) => {
    try {
        await GestioneManutenzioniService.downloadAllegato(params.id, params.fileName);
        return true
    } catch (e: any) {
        console.log('[ERROR] downloadAllegato', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti a scaricare l\'allegato',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const deleteAllegato = createAsyncThunk<boolean, number>(HOME_ACTION.DELETE_PAGAMENTO, async (idAllegato, thunkAPI) => {
    try {
        await GestioneManutenzioniService.deleteAllegato(idAllegato);
        return true
    } catch (e: any) {
        console.log('[ERROR] deleteAllegato', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare l\'allegato',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }
});

const deleteManutenzioneAction = createAsyncThunk<boolean, number>(HOME_ACTION.DELETE_MANUTENZIONE, async (idManutenzione, thunkAPI) => {
    try {
        await GestioneManutenzioniService.deleteManutenzione(idManutenzione);
        return true
    } catch (e: any) {
        console.log('[ERROR] deleteManutenzioneAction', e);
        thunkAPI.dispatch(uiManagerActions.showToast({
            title: 'Si è verificato un errore',
            description: 'Non siamo riusciti ad eliminare la manutenzione',
            duration: 3000,
            status: 'error'
        }));
        throw e;
    }

});

export const HomeAction = {
    fetchPrenotazioniAction,
    fetchPrenotazioneAction,
    fetchUtentiAction,
    fetchProprietariAction,
    fetchProprietarioAction,
    fetchStrutturaInfoAction,
    fetchAccessiAction,

    editProprietarioAction,
    editProprietarioStrutturaAction,
    fetchStruttureAction,
    effettuaCheckinAction,

    createProprietarioAction,
    createUtenteAction,
    createStrutturaInfoAction,
    rimuoviPuliziaAction,
    downloadFileAction,
    bloccaPrenotazioneAction,
    sbloccaPrenotazioneAction,
    resetPrenotazioneAction,
    sendToPSAction,
    modificaPuliziaExtraAction,
    fetchVarchiAction,
    deleteUtenteAction,
    updateSmobuDataAction,
    deleteStrutturaInfoAction,
    inoltraDatiAction,
    updateConsumiAction,
    createAccessoAction,
    deleteAccessoAction,
    fetchAccessoAction,
    editAccesso,
    createManutenzioneAction,
    fetchManutenzioneAction,
    editManutenzione,
    createPagamentoAction,
    editPagamento,
    deletePagamentoAction,
    fetchManutenzioniAction,
    uploadAllegato,
    downloadAllegato,
    deleteAllegato,
    deleteManutenzioneAction
};
