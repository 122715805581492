import React, { FC } from 'react';
import { DatePicker, TimePicker } from 'antd';
import { datePickerFullWidthStyle } from 'style/styleConstants';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment'; // Import moment library
import {
    backendDateTimeFormatter,
    backendTimeFormatter, backendToFrontedDateFormatter,
    DATE_TIME_FORMAT,
    TIME_FORMAT
} from 'utils/dates/date.utils';

export interface MyDateTimePickerProps {
    control: Control<any>;
    name: string;
    placeholder: string;
    allowClear?: boolean;
}

export const MyDateTimePicker: FC<MyDateTimePickerProps> = ({
                                                                name,
                                                                control,
                                                                allowClear,
                                                                placeholder,
                                                            }) => {

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                // Format the field value using backendDateTimeFormatter
                //if field.value != contains T then it is not a backend date
                const formattedValue = typeof field.value === 'string' && field.value.includes('T') ? field.value : backendDateTimeFormatter(field.value);
                return (
                    <>
                        <DatePicker
                            ref={field.ref}
                            allowClear={allowClear}
                            placeholder={placeholder}
                            defaultValue={backendTimeFormatter(formattedValue) ? moment(backendTimeFormatter(formattedValue),DATE_TIME_FORMAT) : undefined }
                            onChange={(date) => {
                                field.onChange(date);
                            }}
                            showTime={{ format: TIME_FORMAT }}
                            size={'large'}
                            format={DATE_TIME_FORMAT}
                            style={datePickerFullWidthStyle}
                        />
                    </>
                );
            }}
        />
    );
};