export const fakePromise = <T>(response: T, isError = false): Promise<T> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if(isError){
                return reject()
            }
            return resolve(response)
        }, 500)
    })
}

export type ImageEncodedBase64 = string | ArrayBuffer | null

export const convertBase64 = (file:File):Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const getValidUrl = (url :string) => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if(/^(:\/\/)/.test(newUrl)){
        return `http${newUrl}`;
    }
    if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
};


export const addBase64Header = (data:string | undefined) =>{
    return data ? `data:image/png;base64,${data}` : undefined
}

export const validateObjectProperites = (obj:any,completeObject:any) =>{
    let result = true;
    for (let prop in completeObject){
        if(obj[prop] === undefined || obj[prop] === null || obj[prop] === ''){
            console.log(`Property ${prop} is not defined : `,obj[prop])
            result = false;
            break
        }
    }

    if (result){
        console.log('Object validation success')
    } else {
        console.log('Object validation failed')
    }
    return result
}


export const convertBase64ToBlob = (base64Image: string) => {
    // Split into two parts
    const parts = base64Image.split(';base64,');

    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
}

export const orderArrayByDate = <T>(array:T[],key: keyof T) => {
    return array.sort((a:any,b:any) => {
        return new Date(a[key as string]).getTime() - new Date(b[key as string]).getTime()
    })
}

export const getCleanObject = (obj:any) => {
    let newObj:any = {};
    for (let prop in obj){
        if(obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== ''){
            console.log(`Property ${prop} is defined : `,obj[prop])
            newObj[prop] = obj[prop]
        }
    }
    return newObj
}
