import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {
    Accesso,
    FetchAccessiParamsAndBody,
    FetchAccessiResponse,
} from "../../store/gestioneAccessi/types";
import {FetchPrenotazioniResponse, PaginatedRequestParamsAndBody} from "../../store/home/type";
import {
    backendDateFormatter,
    backendDateTimeFormatter,
    backendToFrontedDateFormatter
} from "../../utils/dates/date.utils";
import {
    FetchManutenzioniParamsAndBody,
    FetchManutenzioniResponse,
    Manutenzione,
    Pagamento
} from "../../store/gestioneManutenzioni/types";
import FileSaver from "file-saver";

const fetchManutenzioni = async (requestParams: FetchManutenzioniParamsAndBody): Promise<FetchManutenzioniResponse> => {
    console.log('Request [fetchManutenzioni]');
    const params: PaginatedRequestParamsAndBody = {
        page: requestParams.page,
        size: requestParams.size,
        sortField: requestParams.sortField,
        sortDirection: requestParams.sortDirection,
    }

    const response: AxiosResponse<FetchManutenzioniResponse> = await appAxios.post(`/api/cerbero/struttura/manutenzione/getList`, {
        ...requestParams.entity
    }, {
        params
    });

    console.log('Response [fetchManutenzioni] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during fetchManutenzioni')
}
const createManutenzione = async (manutenzione: Manutenzione, idStruttura: number): Promise<Manutenzione> => {
    console.log('Request [createManutenzione]');
    manutenzione.data = backendDateFormatter(manutenzione.data)
    if(manutenzione.dataRimborsoPortale != undefined && manutenzione.dataRimborsoPortale !="")
        manutenzione.dataRimborsoPortale = backendDateFormatter(manutenzione.dataRimborsoPortale)

    const response: AxiosResponse<Manutenzione> = await appAxios.post(`/api/cerbero/struttura/manutenzione/${idStruttura}`, {
        ...manutenzione
    });

    console.log('Response [createManutenzione] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during createManutenzione')
}
const createPagamento = async (pagamento: Pagamento, idManutenzione: number): Promise<Manutenzione> => {
    console.log('Request [createPagamento]');
    const response: AxiosResponse<Manutenzione> = await appAxios.post(`/api/cerbero/struttura/addPagamentoManutenzione/${idManutenzione}`, {
        ...pagamento
    });

    console.log('Response [createPagamento] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during createPagamento')
}
const fetchManutenzione = async (id: number): Promise<Manutenzione> => {
    console.log('Request [fetchManutenzione]');
    const response: AxiosResponse<Manutenzione> = await appAxios.get(`/api/cerbero/struttura/manutenzione/${id}`);
    const responseData = {
        ...response.data,
        data: backendToFrontedDateFormatter(response.data.data),
        dataRimborsoPortale: response.data.dataRimborsoPortale ? backendToFrontedDateFormatter(response.data.dataRimborsoPortale) : undefined,
    }
    console.log('Response [fetchManutenzione] ', response.data);
    if (responseData) {
        return responseData
    }
    throw new Error('Error during fetchManutenzione')
}

const deleteAccesso = async (id: number): Promise<void> => {
    console.log('Request [deleteAccesso]');
    const response: AxiosResponse<void> = await appAxios.delete(`/api/cerbero/struttura/deleteAccesso/${id}`);
}
const editManutenzione = async (id: string | number, manutenzione: Manutenzione): Promise<void> => {
    manutenzione.data = backendDateFormatter(manutenzione.data)

    if(manutenzione.dataRimborsoPortale != undefined && manutenzione.dataRimborsoPortale !="")
        manutenzione.dataRimborsoPortale = backendDateFormatter(manutenzione.dataRimborsoPortale)

    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/manutenzione/${id}`, manutenzione);
    console.log('Response [editManutenzione] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editManutenzione')
}
const editPagamento = async (id: string | number, pagamento: Pagamento): Promise<void> => {
    console.log('Request [editPagamento]' , pagamento);
    const response: AxiosResponse = await appAxios.put(`/api/cerbero/struttura/updatePagamentoManutenzione/${id}`, pagamento);
    console.log('Response [editPagamento] ', response.data);
    if (response.status === 200) {
        return
    }
    throw new Error('Error during editPagamento')
}

const deletePagamento = async (id: number): Promise<void> => {
    console.log('Request [deletePagamento]');
    const response: AxiosResponse<void> = await appAxios.delete(`/api/cerbero/struttura/deletePagamentoManutenzione/${id}`);

    if (response.status === 200) {
        return
        throw new Error('Error during editPagamento')
    }
}

const uploadAllegato = async (formData: FormData, idManutenzione: number | string, isAllegatoPre: boolean): Promise<Manutenzione> => {
    console.log('Request [uploadAllegato]');
    const response: AxiosResponse<void> = await appAxios.post(`/api/cerbero/struttura/uploadAllegatoManutenzione/${isAllegatoPre}/${idManutenzione}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    console.log('Response [uploadAllegato] ', response.data);
    if (response.status === 200 && response.data) {
        return response.data
    }
    throw new Error('Error during uploadAllegato')
}

const downloadAllegato = async (id: number, fileName : string): Promise<void> => {
    console.log('Request [downloadAllegato]');
    const response: AxiosResponse<Blob> = await appAxios.get(`/api/cerbero/struttura/downloadAllegatoManutenzione/${id}`, {
        responseType: 'blob'
    });

    if (response && response.data) {
        FileSaver.saveAs(response.data, fileName);
        return
    }
}

const deleteAllegato = async (id: number): Promise<void> => {
    console.log('Request [deleteAllegato]');
    const response: AxiosResponse<void> = await appAxios.delete(`/api/cerbero/struttura/deleteAllegatoManutenzione/${id}`);

    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteAllegato')
}

const deleteManutenzione = async (id: number): Promise<void> => {
    console.log('Request [deleteManutenzione]');
    const response: AxiosResponse<void> = await appAxios.delete(`/api/cerbero/struttura/manutenzione/${id}`);

    if (response.status === 200) {
        return
    }
    throw new Error('Error during deleteManutenzione')
}

export const GestioneManutenzioniService = {
    createManutenzione,
    fetchManutenzione,
    editManutenzione,
    createPagamento,
    editPagamento,
    deletePagamento,
    fetchManutenzioni,
    uploadAllegato,
    downloadAllegato,
    deleteAllegato,
    deleteManutenzione
};
