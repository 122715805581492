import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {MyPaginatedColumn, MyPaginatedTable} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {useNavigate} from 'react-router-dom';
import {HomeAction} from 'store/home/home.action';
import {PrenotazioneTableModel} from 'models/prenotazione.model';
import {Proprietario} from 'models/proprietario.model';
import {ProprietariTableAction} from 'store/home/paginatedActions/proprietariTable.action';
import {Button, Stack, Text} from '@chakra-ui/react';
import {primaryShortButtonProps} from 'shared/components/default/myButton/myButton.types';
import {HomePaths} from 'navigation/home/home.routes';
import {RoutesPaths} from 'navigation/root.routes';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';
import {GestioneAccessiTableAction} from "../../../store/home/paginatedActions/gestioneAccessiTable.action";
import {priceFormatter} from "../../../utils/numbers.util";
import {backendTimeFormatter} from "../../../utils/dates/date.utils";
import {IoTrash} from "react-icons/io5";
import {COLORS} from "../../../style/chakraTheme";
import {uiManagerActions} from "../../../store/uiManager/uiManager.action";

export const GestioneAccessiPage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {accessiPaginated} = useAppSelector(state => state.homeReducer)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData(accessiPaginated.pageIndex, accessiPaginated.pageSize, accessiPaginated.filters, accessiPaginated.sortDirection, accessiPaginated.sortField)
    }, []);


    const handleRemoveAccesso = (id: number) => {
        dispatch(HomeAction.deleteAccessoAction(id)).then((response) => {
            if (response.payload) {
                dispatch(uiManagerActions.showToast({
                    title: 'Success',
                    description: 'L eliminazione è avvenuta con successo',
                    status: 'success',
                }))
               fetchData(accessiPaginated.pageIndex, accessiPaginated.pageSize, accessiPaginated.filters, accessiPaginated.sortDirection, accessiPaginated.sortField)
            }
        })
    }

    const tableColumns: MyPaginatedColumn[] = [
        {
            columnName: t('homePage:TABLES:GESTIONE_STRUTTURE:CODICE'),
            objectKey: 'codice',
            filteringKey: 'codice',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:GESTIONE_STRUTTURE:FINE_VALIDITA'),
            objectKey: 'fineValidita',
            filteringKey: 'fineValidita',
            isFilterable: true,
            customObject: (fineValidita: string) => (
                <Text>{backendTimeFormatter(fineValidita)}</Text>
            )
        },
        {
            columnName: t('homePage:TABLES:GESTIONE_STRUTTURE:DESCRIZIONE'),
            objectKey: 'descrizione',
            filteringKey: 'descrizione',
            isFilterable: true,
        },
        {
            columnName: 'Delete',
            objectKey: 'id',
            customObject: (id: number) => (
                <Stack w={'full'} align={'start'} justifyContent={'center'}>
                    <IoTrash size={24} onClick={() => handleRemoveAccesso(id)} color={COLORS.DANGER_500}/>
                </Stack>
            )
        }

    ]

    const columnEntityMapping = (params: PaginatedTableFilters) => {
        switch (params.filteringColumn) {
            case 'codice':
                return {
                    codice: params.searchText,
                };

            case 'fineValidita':
                return {
                    fineValidita: params.searchText,
                };
            case 'descrizione':
                return {
                    descrizione: params.searchText,
                };

            default:
                return {};
        }
    }

    const fetchData = async (pageIndex: number, pageSize: number, entity?: any, sortDirection?: string, sortField?: string) => {
        setLoading(true)
        await dispatch(HomeAction.fetchAccessiAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection,
            sortField: sortField
        }))
        setLoading(false)
    }

    return (
        <SimplePage title={t('homePage:TITLES:GESTIONE_STRUTTURE')}>

            <MyPaginatedTable
                pages={accessiPaginated?.data}
                fetchData={fetchData}
                totalPages={accessiPaginated?.totalPages}
                columns={tableColumns}
                loading={loading}
                pageIndex={accessiPaginated.pageIndex}
                pageSize={accessiPaginated.pageSize}
                setPageIndex={(pageIndex: number) => dispatch(GestioneAccessiTableAction.setPageIndexAction(pageIndex))}
                setPageSize={(pageSize: number) => dispatch(GestioneAccessiTableAction.setPageSizeAction(pageSize))}
                setOrderingFilters={(value: OrderingFilters) => dispatch(GestioneAccessiTableAction.setOrderingFiltersAction(value))}
                orderingFilters={{sortField: accessiPaginated.sortField, sortDirection: accessiPaginated.sortDirection}}
                onRowClick={(data: PrenotazioneTableModel) => navigate(`${data.id}`)}
                columnEntityMapping={columnEntityMapping}
                header={{
                    placeholder: t('homePage:TEXTS:TYPE_SOMETHING'),
                    removeDatePiker: true,
                    filters: accessiPaginated.filters,
                    setFilters: (filters: PaginatedTableFilters) => dispatch(GestioneAccessiTableAction.setFiltersAction(filters)),
                    children: (
                        <Button {...primaryShortButtonProps}
                                onClick={() => navigate(`${RoutesPaths.HOME.toString()}/${HomePaths.GESTIONE_ACCESSI_CREATION.toString()}`)}>Aggiungi
                            Accesso</Button>
                    )
                }}
            />
        </SimplePage>

    );
};
