import {AxiosResponse} from 'axios';
import {appAxios} from 'service/axios.config';
import {ReportModel} from 'models/report.model';


const getReportListStruttura = async (): Promise<ReportModel[]> => {
    console.log('Request [getReportListStruttura] ', );

    const response: AxiosResponse<ReportModel[]> = await appAxios.get(`/api/cerbero/getReportListStruttura`);

    console.log('Request [getReportListStruttura] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getReportListStruttura')
}

const getReportListGeneral = async (): Promise<number> => {
    console.log('Request [getReportListGeneral] ', );

    const response: AxiosResponse<any> = await appAxios.get(`/api/cerbero/getReportListGeneral`);

    console.log('Request [getReportListGeneral] ', response.data);
    if (response && response.data) {
        return response.data
    }
    throw new Error('Error during getReportListGeneral')
}


export const ReportService ={
    getReportListStruttura,
    getReportListGeneral
}
