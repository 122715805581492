import React, {FC, useEffect, useState} from 'react';
import 'shared/components/default/myInput/myInput.css'
import {Box, Input, InputGroup, InputProps, InputRightAddon, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {IoChevronForward} from 'react-icons/io5';
import {Control, Controller, UseFormRegisterReturn} from 'react-hook-form';
import NumberFormat from 'react-number-format';

const defaultInputProps = {variant: 'flushed', colorScheme: 'textColor'}
const outlineInputProps = {variant: 'outline', colorScheme: 'textColor'}


interface MyInputProps extends InputProps {
    myType: 'FLUSHED' | 'OUTLINE' | 'EDITABLE' | 'ARROW_LINK' | 'CURRENCY'
    setEditToDisabled?: boolean,
    currencyParams?: {
        control: Control<any>,
        name: string,
    }
    formReg?: UseFormRegisterReturn
}

const MyInput: FC<MyInputProps> = (props) => {
    const {myType, setEditToDisabled, formReg, type, currencyParams, ...rest} = props
    const [editValue, setEditValue] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (setEditToDisabled) {
            setEditValue(false)
        }
    }, [setEditToDisabled])

    const chooseType = () => {
        switch (props.myType) {
            case 'FLUSHED':
                return defaultInputProps;
            case 'OUTLINE':
                return outlineInputProps
            case 'EDITABLE':
                return outlineInputProps
            case 'ARROW_LINK':
                return outlineInputProps
        }

    }

    const renderInput = () => {
        switch (props.myType) {
            case 'EDITABLE':
                return (
                    <InputGroup size="md">
                        <Input isDisabled={!editValue} {...chooseType()} {...rest} {...formReg} />
                        <InputRightAddon children={<Text fontSize={'sm'}
                                                         onClick={() => setEditValue(!editValue)}>{editValue ? t('common:BUTTONS:APPLY') : t('common:BUTTONS:EDIT')}</Text>}/>
                    </InputGroup>
                )
            case 'ARROW_LINK':
                const {onClick, ...subRest} = rest
                return (

                    <InputGroup size="md" onClick={onClick}>
                        <Input isDisabled={true} textColor={'#00000060'}
                               _disabled={{textColor: '#00000060'}} {...chooseType()} {...subRest} {...formReg}/>
                        <InputRightAddon children={<IoChevronForward/>}/>
                    </InputGroup>
                )
            case 'CURRENCY':
                if (currencyParams) {
                    return (
                        <Controller
                            control={currencyParams.control}
                            name={currencyParams.name}
                            render={({field}) => (
                                <NumberFormat
                                    ref={field.ref} value={field.value == null ? 0: field.value}
                                    onValueChange={(values, sourceInfo) => {
                                        const {formattedValue, value,floatValue} = values;
                                        console.log('NumberFormat', values);
                                        field.onChange(floatValue);
                                    }}
                                    onBlur={field.onBlur} name={field.name}
                                    isNumericString={true}
                                    thousandsGroupStyle={'thousand'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    customInput={Input}
                                    suffix={'€'}/>
                            )}
                        />
                    )
                }
                //default
                return (
                    <Input {...chooseType()} {...rest} {...formReg}/>
                )

            default :
                return (
                    <Input {...chooseType()} {...rest} {...formReg}/>
                )
        }
    }
    return (
        <Box>
            {renderInput()}
        </Box>
    )
};

export default MyInput;
