import React from 'react';
import {Center, Text, VStack} from '@chakra-ui/react';
import MyButton from 'shared/components/default/myButton/myButton.component';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as NotFound} from 'assets/general/not_found.svg';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
            <Center h={'100vh'} overflowY={'hidden'}>
                <VStack>
                    <NotFound width={'300px'} height={'300px'}/>
                    <Text color={'titleColor'} fontSize={'large'}>{t('common:ERRORS:404:DESCRIPTION')}</Text>
                    <MyButton myType={'OUTLINE'} color={'titleColor'} fontWeight={'bold'} onClick={()=>navigate(-1)} >{t('common:BUTTONS:BACK_TO_PREVIOUS_PAGE')} </MyButton>
                </VStack>
            </Center>
    )
};

export default NotFoundPage
