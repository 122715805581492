import React, {useEffect, useState} from 'react';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {
    Box,
    Button,
    Checkbox,
    Divider, Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack, IconButton, Link, Spacer,
    Stack,
    Text,
    VStack
} from '@chakra-ui/react';
import {
    outlineButtonProps,
    primaryButtonProps,
    primaryShortButtonProps
} from 'shared/components/default/myButton/myButton.types';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {useTranslation} from 'react-i18next';
import MyInput from 'shared/components/default/myInput/myInput.component';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm, useFormState} from 'react-hook-form';
import 'antd/dist/antd.css';
import {Proprietario} from 'models/proprietario.model';
import {HomeAction} from 'store/home/home.action';
import {useNavigate, useParams} from 'react-router-dom';
import {uiManagerActions} from 'store/uiManager/uiManager.action';
import {MyDatePicker} from 'shared/components/default/myDatePicker/myDatePicker.component';
import {EmailAdderComponent} from 'shared/components/custom/emailAdder/emailAdder.component';
import {myCardStyle, stretchCardContainerStyle} from 'style/styleConstants';
import {FillHeightBox} from 'shared/components/default/fillHeightBox/fillHeightBox.component';
import {MyDateTimePicker} from "../../../shared/components/default/myDateTimePicker/MyDateTimePicker";
import {Accesso} from "../../../store/gestioneAccessi/types";
import {AccessoFormInitialFrom, AccessoValidationSchema} from "./validation/accessi.validation";
import {
    MyPaginatedColumn,
    MyPaginatedTable
} from "../../../shared/components/custom/paginatedTable/myPaginatedTable.component";
import {GestioneAccessiTableAction} from "../../../store/home/paginatedActions/gestioneAccessiTable.action";
import {OrderingFilters, PaginatedTableFilters} from "../../../store/home/type";
import {PrenotazioneTableModel} from "../../../models/prenotazione.model";
import {RoutesPaths} from "../../../navigation/root.routes";
import {HomePaths} from "../../../navigation/home/home.routes";
import {DynamicCheckIconComponent} from "../../../shared/components/custom/dynamicCheckIcon/dynamicCheckIcon.component";
import {AppartamentiTableAction} from "../../../store/home/paginatedActions/appartamentiTable.action";
import {StrutturaTableModel} from "../../../models/struttura/struttura.model";
import {IoCopy} from "react-icons/io5";

export const AccessoCreationEditPage = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const {t} = useTranslation()
    const {isError, strutturePaginated} = useAppSelector(state => state.homeReducer)
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false)
    const [struttureSelectedIds, setStruttureSelectedIds] = useState<number[]>([])
    //FORM
    const [formDefaultValues, setFormDefaultValues] = useState<Accesso>(AccessoFormInitialFrom);
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setFocus,
        watch,
        formState: {errors, isDirty, isSubmitting}
    } = useForm<any>({
        resolver: yupResolver(AccessoValidationSchema),
        defaultValues: formDefaultValues,
    });
    const codice = watch('codice');

    const handleStrutturaCheckbox = (id: number) => {
        return (event: any) => {
            if (event.target.checked) {
                setStruttureSelectedIds(prevState => [...prevState, id]);
                console.log('struttureSelectedIds', struttureSelectedIds)
            } else {
                setStruttureSelectedIds(prevState => prevState.filter((item) => {
                   return item != id
                }));
                console.log('struttureSelectedIds', struttureSelectedIds)
            }
        }
    }

    const tableColumns: MyPaginatedColumn[] = [
        {
            columnName: '',
            objectKey: 'id',
            customHeaderProps: {w: '28px'},
            customObject: (field: any) => (
                <Stack h={10} align={'start'} justify={'center'}>
                    <Checkbox ref={field.ref} onChange={handleStrutturaCheckbox(field)} onBlur={field.onBlur}
                              name={field.name} isChecked={
                        struttureSelectedIds.some((item) => item == field)
                    }>
                    </Checkbox>
                </Stack>
            )
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:NOME_STRUTTURA'),
            objectKey: 'appartamento',
            filteringKey: 'nomeStruttura',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:STRUTTURE:NOME_INTERNO'),
            objectKey: 'nomeInterno',
            filteringKey: 'nomeInterno',
            isFilterable: true,
        },
    ]
    const columnEntityMapping = (params: PaginatedTableFilters) => {
        switch (params.filteringColumn) {
            case 'appartamento':
                return {
                    nomeStruttura: params.searchText,
                };
            case 'nomeInterno':
                return {
                    nomeInterno: params.searchText,
                };
            default:
                return {};
        }
    }

    const fetchData = async (pageIndex: number, pageSize: number, entity?: any, sortDirection?: string, sortField?: string) => {
        console.log('fetchData called ', pageIndex, pageSize, entity)
        setLoading(true)
        await dispatch(HomeAction.fetchStruttureAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection && sortDirection != '' ? sortDirection : 'DESC',
            sortField: sortField && sortField != '' ? sortField : 'disabled',
        }))
        setLoading(false)

    }


    useEffect(() => {
        setValue('struttureIds', struttureSelectedIds);
    }, [struttureSelectedIds, setValue]);

    useEffect(() => {
        if (params.id) {
            setIsPageLoading(true)
            setIsEdit(true)
            dispatch(HomeAction.fetchAccessoAction(params.id)).then((response) => {
                if (response.payload) {
                    let accesso = response.payload as Accesso;
                    setStruttureSelectedIds([...accesso.struttureIds]);
                    setFormDefaultValues(response.payload as Accesso)
                    reset(response.payload)
                }
            }).finally(() => {
                setIsPageLoading(false)
            })
        }
    }, [])

    const creationAction = (accesso: Accesso) => {
        accesso.struttureIds = struttureSelectedIds;
        dispatch(HomeAction.createAccessoAction(accesso)).then((response) => {
                setIsLoading(false);
                if (response.payload) {
                    dispatch(uiManagerActions.showToast({
                        title: 'Success',
                        description: 'Creazione  avvenuta con successo',
                        status: 'success',
                    }))
                    navigate(`${RoutesPaths.HOME.toString()}/${HomePaths.GESTIONE_ACCESSI.toString()}`);
                }
            }
        )

    }

    const editAction = (accesso: Accesso) => {
        if (params.id) {
            const id: string = params.id

            dispatch(HomeAction.editAccesso({
                id: id,
                accesso: accesso
            })).then((response) => {
                setIsLoading(false);
                if (response.payload) {
                    dispatch(uiManagerActions.showToast({
                        title: 'Success',
                        description: 'Modifica  avvenuta con successo',
                        status: 'success',
                    }))
                    navigate(-1);
                }
            })
        } else {
            dispatch(uiManagerActions.showToast({
                title: 'Errore',
                description: 'Non è stato possibile recuperare l\'id dell\'accesso da modificare',
                status: 'error',
            }))
        }
    }

    const formSubmit = (data: Accesso) => {
        setIsLoading(true);
        if (isEdit) {
            return editAction(data)

        }
        return creationAction(data)

    }
    const handleGenerateCode = () => {
        const code = Math.floor(100000 + Math.random() * 900000).toString()
        setValue('codice', code)
    }

    const getTitle = () => {
        if (params.id) {
            return 'Modifica accesso'
        } else {
            return 'Creazione accesso'
        }
    }

    return (
        <SimplePage title={getTitle()} hasBack={true} isLoading={isPageLoading} isError={isError}>
            <Box w={'full'} h={'full'} p={6} backgroundColor={'background'}>
                <form className={'formStyle'} onSubmit={handleSubmit(formSubmit)}>
                    <Text fontWeight={'semibold'} fontSize={'lg'} color={'titleColor'}>{'Dati'}</Text>
                    <HStack {...stretchCardContainerStyle} my={2}>
                        <VStack {...myCardStyle}>
                            <FormControl isInvalid={!!errors.codice}>
                                <FormLabel color={'titleColor'}>Codice*</FormLabel>
                                <HStack w={'full'} h={'full'} align={'start'}>
                                    <MyInput formReg={register('codice')} myType={'OUTLINE'}
                                             placeholder={'Codice'}/>
                                    <FormErrorMessage>{errors.codice?.message}</FormErrorMessage>

                                    <Button {...outlineButtonProps} w={'container.xs'}
                                            onClick={handleGenerateCode}>Genera codice
                                    </Button>
                                </HStack>
                            </FormControl>
                            <FormControl isInvalid={!!errors.fineValidita}>
                                <FormLabel color={'titleColor'}>Data di fine validità*</FormLabel>
                                <MyDateTimePicker
                                    control={control}
                                    name="fineValidita"
                                    placeholder={'Data e orario di fine validità'}
                                    allowClear={false}
                                />
                                <FormErrorMessage>{errors.fineValidita?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.descrizione}>
                                <FormLabel color={'titleColor'}>Descrizione</FormLabel>
                                <MyInput formReg={register('descrizione')} myType={'OUTLINE'}
                                         placeholder={'Descrizione'}/>
                                <FormErrorMessage>{errors.descrizione?.message}</FormErrorMessage>
                            </FormControl>
                            <Text fontWeight={'semibold'}  color={'titleColor'}>
                                Link di accesso
                            </Text>

                                <HStack spacing='24px'>
                                <Text > https://lion.home-hotel.it/gestioneStrutture/{codice}  </Text>
                                <Spacer/>
                                <IconButton
                                    aria-label="Copy link"
                                    icon={<IoCopy/>}
                                    size="sm"
                                    onClick={() => navigator.clipboard.writeText("https://lion.home-hotel.it/gestioneStrutture/" + codice)}
                                />
                            </HStack>
                        </VStack>
                        <VStack {...myCardStyle}>
                            <FormControl isInvalid={!!errors.struttureIds}>
                                <FormErrorMessage>{errors.struttureIds?.message}</FormErrorMessage>

                                <MyPaginatedTable
                                    pages={strutturePaginated?.dataTable}
                                    fetchData={fetchData}
                                    totalPages={strutturePaginated?.totalPages}
                                    columns={tableColumns}
                                    loading={loading}
                                    pageIndex={strutturePaginated.pageIndex}
                                    pageSize={strutturePaginated.pageSize}
                                    setPageIndex={(pageIndex: number) => dispatch(AppartamentiTableAction.setPageIndexAction(pageIndex))}
                                    setPageSize={(pageSize: number) => dispatch(AppartamentiTableAction.setPageSizeAction(pageSize))}
                                    setOrderingFilters={(value: OrderingFilters) => dispatch(AppartamentiTableAction.setOrderingFiltersAction(value))}
                                    orderingFilters={{
                                        sortField: strutturePaginated.sortField,
                                        sortDirection: strutturePaginated.sortDirection
                                    }}
                                    onRowClick={(data: StrutturaTableModel) => navigate(`${data.id}`)}
                                    columnEntityMapping={columnEntityMapping}
                                    header={{
                                        placeholder: t('homePage:TEXTS:TYPE_SOMETHING'),
                                        removeDatePiker: true,
                                        filters: strutturePaginated.filters,
                                        setFilters: (filters: PaginatedTableFilters) => dispatch(AppartamentiTableAction.setFiltersAction(filters)),
                                    }}
                                />
                            </FormControl>

                        </VStack>
                    </HStack>
                    <Divider/>
                    <Text fontWeight={'semibold'} mt={5} fontSize={'lg'}
                          color={'titleColor'}>{isEdit ? 'Concludi la modifica' : 'Concludi la creazione'}</Text>
                    <Divider/>
                    <Box w={'full'} display={'flex'} mt={5} justifyContent={'center'} alignContent={'center'}>
                        <Button {...primaryButtonProps} isLoading={isLoading || isSubmitting} isDisabled={isError}
                                type={'submit'}
                                maxW={'70%'}>{isEdit ? 'Modifica accesso' : 'Crea accesso'}</Button>
                    </Box>
                </form>
            </Box>
        </SimplePage>
    );
};

export default AccessoCreationEditPage;
