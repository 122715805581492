import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'store/store.config';
import {SimplePage} from 'shared/components/default/simplePage/simplePage.component';
import {MyPaginatedColumn, MyPaginatedTable} from 'shared/components/custom/paginatedTable/myPaginatedTable.component';
import {useNavigate} from 'react-router-dom';
import {HomeAction} from 'store/home/home.action';
import {PrenotazioneTableModel} from 'models/prenotazione.model';
import {Proprietario} from 'models/proprietario.model';
import {ProprietariTableAction} from 'store/home/paginatedActions/proprietariTable.action';
import {Button} from '@chakra-ui/react';
import {primaryShortButtonProps} from 'shared/components/default/myButton/myButton.types';
import {HomePaths} from 'navigation/home/home.routes';
import {RoutesPaths} from 'navigation/root.routes';
import {OrderingFilters, PaginatedTableFilters} from 'store/home/type';

export const ProprietariPage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {proprietariPaginated} = useAppSelector(state => state.homeReducer)
    const [loading, setLoading] = useState(false)

    const tableColumns:MyPaginatedColumn[] = [
        {
            columnName: t('homePage:TABLES:PROPRIETARI:NOME_PROPRIETARIO'),
            objectKey: 'nome',
            filteringKey: 'nome',
            isFilterable: true,
        },
        {
            columnName: t('homePage:TABLES:PROPRIETARI:EMAIL'),
            objectKey: 'email',
            filteringKey: 'email',
            isFilterable: true,
        },
        {
            columnName: 'Telefono',
            objectKey: 'telefono',
            filteringKey: 'telefono',
            isFilterable: true,
        },
      ]

    const columnEntityMapping = (params:PaginatedTableFilters):Partial<Proprietario> => {
        switch (params.filteringColumn) {
            case 'nome':
                return {
                    nome: params.searchText,
                    cognome: params.searchText,
                    societa: params.searchText,
                };
            case 'email':
                return {
                    email: params.searchText,
                };
            case 'telefono':
                return {
                    telefono: params.searchText,
                };
            default:
                return {};
        }
    }

    const fetchData = async (pageIndex:number,pageSize:number,entity?:any,sortDirection?:string,sortField?:string) => {
        setLoading(true)
        await dispatch(HomeAction.fetchProprietariAction({
            entity,
            page: pageIndex,
            size: pageSize,
            sortDirection: sortDirection,
            sortField: sortField
        }))
        setLoading(false)
    }

    return (
        <SimplePage title={t('homePage:TITLES:PROPRIETARI')}>

            <MyPaginatedTable
                pages={proprietariPaginated?.dataTable}
                fetchData={fetchData}
                totalPages={proprietariPaginated?.totalPages}
                columns={tableColumns}
                loading={loading}
                pageIndex={proprietariPaginated.pageIndex}
                pageSize={proprietariPaginated.pageSize}
                setPageIndex={(pageIndex: number) => dispatch(ProprietariTableAction.setPageIndexAction(pageIndex))}
                setPageSize={(pageSize: number) => dispatch(ProprietariTableAction.setPageSizeAction(pageSize))}
                setOrderingFilters={(value: OrderingFilters) => dispatch(ProprietariTableAction.setOrderingFiltersAction(value))}
                orderingFilters={{sortField:proprietariPaginated.sortField,sortDirection:proprietariPaginated.sortDirection}}
                onRowClick={(data:PrenotazioneTableModel)=> navigate(`${data.id}`)}
                columnEntityMapping={columnEntityMapping}
                header={{
                    placeholder: t('homePage:TEXTS:TYPE_SOMETHING'),
                    removeDatePiker: true,
                    filters:proprietariPaginated.filters,
                    setFilters: (filters: PaginatedTableFilters) => dispatch(ProprietariTableAction.setFiltersAction(filters)),
                    children: (
                        <Button {...primaryShortButtonProps} onClick={()=>navigate(`${RoutesPaths.HOME.toString()}/${HomePaths.PROPRIETARI_CREATION.toString()}`)} >Aggiungi Proprietario</Button>
                    )
                }}
            />
        </SimplePage>

    );
};
