import {createReducer} from '@reduxjs/toolkit';
import {GestioneStruttureState} from './types';
import {GestioneStruttureActions} from 'store/gestioneStrutture/gestioneStrutture.action';

const initialState: GestioneStruttureState = {
    isError: false,
    isLoading: false,
    gestioneStrutture: []
};

export const gestioneStruttureReducer = createReducer(initialState, (builder) => {
    builder.addCase(GestioneStruttureActions.fetchStruttureMaintenanceAction.pending, (state, action) => {
        return {
            ...state,
            isLoading: true,
            isError: false,
        }
    });

    builder.addCase(GestioneStruttureActions.fetchStruttureMaintenanceAction.rejected, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isError: true,
        }
    });

    builder.addCase(GestioneStruttureActions.fetchStruttureMaintenanceAction.fulfilled, (state, action) => {
        const gestioneStrutture = action.payload;
        return {
            ...state,
            isLoading: false,
            isError: false,
            gestioneStrutture
        }
    });
    builder.addDefaultCase((state, action) => {
        return state;
    });
});
