import React, {Suspense} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import {AppRoutes} from 'navigation/root.routes';
import {i18Mfe} from 'i18n/i18n.config';
import {I18nextProvider} from 'react-i18next';
import {ToastProvider} from 'providers/toast.provider';
import {Center, Spinner} from '@chakra-ui/react';
import {HomeRoutes} from 'navigation/home/home.routes';
import {HomeProvider} from 'providers/home.provider';

function App() {

    return (
        <Suspense fallback={
            <Center h={'100vh'} w={'full'}>
                <Spinner/>
            </Center>
        }>
            <I18nextProvider i18n={i18Mfe}>
                <ToastProvider>
                    <HomeProvider>
                        <Routes>
                            <Route {...AppRoutes.INDEX}/>
                            <Route {...AppRoutes.TEST}/>
                            <Route {...AppRoutes.LOGIN}/>
                            <Route {...AppRoutes.LOGIN_MAINTENANCE}/>
                            <Route {...AppRoutes.GESTIONE_STRUTTURE}/>

                            <Route {...AppRoutes.RECUPERA_PASSWORD}/>
                            <Route {...AppRoutes.RECUPERA_PASSWORD_ID}/>
                            <Route {...AppRoutes.NUOVO_UTENTE}/>
                            <Route {...AppRoutes.HOME} >
                                <Route {...HomeRoutes.INDEX}/>

                                <Route {...HomeRoutes.PRENOTAZIONI}/>
                                <Route {...HomeRoutes.PRENOTAZIONI_DETAIL}/>

                                <Route {...HomeRoutes.APPARTAMENTI}/>
                                <Route {...HomeRoutes.APPARTAMENTI_DETAIL}/>
                                <Route {...HomeRoutes.APPARTAMENTI_EDIT}/>
                                <Route {...HomeRoutes.APPARTAMENTI_CREATION}/>

                                <Route {...HomeRoutes.PROPRIETARI}/>
                                <Route {...HomeRoutes.PROPRIETARI_DETAIL}/>
                                <Route {...HomeRoutes.PROPRIETARI_EDIT}/>
                                <Route {...HomeRoutes.PROPRIETARI_CREATION}/>

                                <Route {...HomeRoutes.GESTIONE_ACCESSI}/>
                                <Route {...HomeRoutes.UTENTI}/>
                                <Route {...HomeRoutes.UTENTI_CREATION}/>
                                <Route {...HomeRoutes.GESTIONE_ACCESSI_CREATION}/>
                                <Route {...HomeRoutes.GESTIONE_ACCESSI_EDIT}/>
                                <Route {...HomeRoutes.MANUTENZIONE}/>
                                <Route {...HomeRoutes.MANUTENZIONE_EDIT}/>
                                <Route {...HomeRoutes.GESTIONE_MANUTENZIONI}/>
                                <Route {...HomeRoutes.NOT_FOUND}/>
                            </Route>
                            <Route {...AppRoutes.NOT_FOUND}/>
                        </Routes>
                    </HomeProvider>
                </ToastProvider>
            </I18nextProvider>
        </Suspense>

    );
}

export default App;
